import React, { useEffect, useRef } from 'react';
import replace from 'lodash/replace';
import isEqual from 'lodash/isEqual';
import { useTranslation } from 'react-i18next';
import keys from 'lodash/keys';

import { validate } from '@common/helpers/bettingSlipHelper/bettingSlipCalculationModel';
import {
  STATE_OPENED,
  STATE_QUEUED,
  STATE_REJECTED,
  STATE_ACCEPTED,
} from '@common/providers/bettingslip/state';
import deleteTimeout from '@common/helpers/timeoutHelper';
import { hasSelections } from '@common/helpers/bettingSlipHelper/bettingSlipModel';
import { IBettingslipErrorTags, TTimeout } from '@common/interfaces';
import {
  changeBettingSlipState,
  removeDisabledSelections,
  setBettingSlipError,
  useBettingSlip,
} from '@common/providers/bettingslip/useBettingSlip';
import { getBettingSlipBalance } from '@common/helpers/paymentsHelper/walletsHelper';
import { IBSWalletType } from '@common/interfaces/bettingslip/IBettingslip';
import { handleBSErrorAndOddsUpdate } from '@common/providers/bettingslip/helper';

import { INotificationStyle } from '@ui/components/genericNotification/GenericNotification.types';
import { Icon } from '@ui/components/icon';
import { GenericNotification } from '@ui/components/genericNotification';

import * as S from './Error.styled';

const ErrorUnwrapped: React.FC = () => {
  const bettingslip = useBettingSlip();
  const timeoutRef = useRef<TTimeout>(null);
  const {
    error,
    state,
    selections,
    betPackerSelections,
    banks,
    type,
    size,
    totalStake,
    user,
    walletType,
  } = bettingslip;
  const { t } = useTranslation();
  const isQueued = state === STATE_QUEUED;
  const isAccepted = state === STATE_ACCEPTED;
  const isSuspended =
    error?.data?.tag === IBettingslipErrorTags.SELECTIONS_SUSPENDED;

  const isNotEnoughBonusMoney =
    getBettingSlipBalance() < bettingslip.totalStake;

  useEffect(() => {
    const errorBettingslip = validate(bettingslip, true);
    if (errorBettingslip) {
      handleBSErrorAndOddsUpdate(errorBettingslip);
    }
    return () => {
      if (state === STATE_REJECTED) {
        changeBettingSlipState(STATE_OPENED);
      }
      deleteTimeout(timeoutRef.current);
      setBettingSlipError({});
    };
  }, []);

  useEffect(() => {
    if (hasSelections(selections, betPackerSelections) || banks.length) {
      const errorBettingslip = validate(bettingslip, true);
      if (
        errorBettingslip &&
        !isEqual(error, errorBettingslip) &&
        !isQueued &&
        !isAccepted
      ) {
        handleBSErrorAndOddsUpdate(errorBettingslip);
      }
    }
  }, [
    keys(selections).length,
    type,
    state,
    totalStake,
    size,
    user,
    isNotEnoughBonusMoney,
  ]);

  useEffect(() => {
    const showErrorTime = bettingslip?.error?.data?.fromServer ? 5000 : 4000;
    if (bettingslip?.error?.data || state === STATE_REJECTED) {
      clearTimeout(timeoutRef.current as number);
      timeoutRef.current = setTimeout(() => {
        changeBettingSlipState(STATE_OPENED);
        setBettingSlipError({});
      }, showErrorTime);
    }
  }, [bettingslip?.error?.data, state]);

  const renderButtonText = (): string => {
    const count = error?.data?.count;

    return replace(
      t('bettingslip.err_predLocked_button_remove'),
      '{count}',
      String(count),
    );
  };

  return (
    <>
      {(!!error?.data || !!error?.message || state === STATE_REJECTED) && (
        <GenericNotification
          styleType={
            walletType === IBSWalletType.BONUS
              ? INotificationStyle.bonusNotifierNotification
              : undefined
          }
          closeHandle={
            isSuspended && (
              <S.RemoveSuspendedButton onClick={removeDisabledSelections}>
                {renderButtonText()}
              </S.RemoveSuspendedButton>
            )
          }
        >
          <S.NotificationWrapper
            isBonusNotifier={walletType === IBSWalletType.BONUS}
          >
            <Icon width="18px" height="18px" name="info-circle" />
            <div
              data-qa="bettingslip-error-message"
              dangerouslySetInnerHTML={{
                __html: error?.message || t('bettingslip.error'),
              }}
            />
          </S.NotificationWrapper>
        </GenericNotification>
      )}
    </>
  );
};

export { ErrorUnwrapped as Component };

export default ErrorUnwrapped;
