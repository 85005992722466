import includes from 'lodash/includes';
import client from 'socketcluster-client';
import isArray from 'lodash/isArray';
import forEach from 'lodash/forEach';

import services from '@features/core/services';

import { SOCKET_CLUSTER_URL } from '@common/constants/config';
import { parseSocketUpdates } from '@common/helpers/updatesHelper';
import { ISocket } from '@common/providers/application/types';
import { enqueInGlobalQueue } from '@common/helpers/eventsHelper/globalSocketData';
import { setEventsSocketData } from '@common/providers/events/eventList/useEventsList';
import { setOpenAppVersion } from '@common/providers/application/useAppState';

let reconnectionDelay = 1000; // Initial reconnection delay
const reconnectionDelayMax = 30000; // Maximum reconnection delay
let isRepeatConnection = false;

export default (): ISocket => {
  const socket = client.connect({
    protocol: 'https',
    hostname: services.config.get(SOCKET_CLUSTER_URL),
    port: 443,
    autoReconnect: true,
    autoReconnectOptions: {
      initialDelay: reconnectionDelay,
      randomness: 0,
      multiplier: 1,
      maxDelay: reconnectionDelayMax,
    },
    secure: true,
  });

  socket.on('message', message => {
    if (includes(message, 'event')) {
      let data = JSON.parse(message).data || JSON.parse(message).event;
      if (window.socketData) {
        if (isArray(window.socketData)) {
          forEach(window.socketData, e => {
            data = [{ type: 'update', body: e }, ...data];
          });
        } else {
          data = [{ type: 'update', body: window.socketData }, ...data];
        }
        window.socketData = null;
      }
      data = parseSocketUpdates(data);
      enqueInGlobalQueue(data);
      setEventsSocketData(data);
    }
    return null;
  });

  socket.on('connect', () => {
    if (isRepeatConnection) {
      setOpenAppVersion();
    }
    isRepeatConnection = true;
    services.logger.log('open', new Date().toString());
  });

  socket.on('error', err => {
    services.logger.log('error', new Date().toString(), err);
    reconnectionDelay = Math.min(reconnectionDelay * 2, reconnectionDelayMax);
    socket.options.autoReconnectOptions.initialDelay = reconnectionDelay;
    services.logger.log(
      'reconnect error. Increasing reconnection delay to',
      String(reconnectionDelay),
    );
  });

  socket.on('disconnect', () => {
    services.logger.log('close', new Date().toString());
  });

  return socket;
};
