import styled from 'styled-components';

import { TextNormal } from '@features/core/styles/text.styled';

import { Icon } from '@ui/components/icon';

export const Item = styled.div`
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  padding: ${({ theme }) => theme.space_10} ${({ theme }) => theme.space_15};
  grid-template-columns: 25px 1fr;
`;

export const Label = styled.label`
  height: 18px;
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: auto 1fr 1fr;
  position: relative;
  align-items: center;
`;
export const LabelIcon = styled(Icon)`
  position: absolute;
  top: 3.5px;
  left: 3px;
  opacity: 0;
`;
export const LabelText = styled(TextNormal)`
  color: ${props => props.theme.textMain};
  justify-self: end;
`;
export const LabelTextBold = styled(TextNormal)`
  color: ${props => props.theme.textMain};
  margin-right: auto;
  font-weight: ${({ theme }) => theme.fontBold};
`;
export const LabelBanksCount = styled(TextNormal)`
  color: ${props => props.theme.textMain};
  margin-right: ${({ theme }) => theme.space_5};
  font-weight: ${({ theme }) => theme.fontBold};
`;
