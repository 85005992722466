import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import {
  PageName,
  StaticSlugPageName,
} from '@features/core/routing/linkAliases';
import { MenuTab } from '@features/app/layout/menuTab';
import { LugasLayer } from '@features/user/components/lugasLayer';
import { LugasBlockLayer } from '@features/user/components/lugasBlockLayer';
import { LugasCooldown } from '@features/user/components/lugasCooldown';
import { LugasInactive } from '@features/user/components/lugasInactive';
import { AcceptTerms } from '@features/user/components/acceptTerms';
import { RealityCheck } from '@features/user/components/realityCheck';
import { AccountSummary } from '@features/user/components/summaryLayers';
import { IWalletView, WalletSwitcher } from '@features/wallets/walletSwitcher';
import ISidebar from '@features/app/layout/sidebar/Sidebar.types';
import { WalletConfirm } from '@features/user/components/walletConfirm';
import { DepositLimit } from '@features/user/components/depositLimit';
import { BlockAccountSubmitLayer } from '@features/user/components/blockAccountSubmitLayer';
import LimitLayer from '@features/user/components/limitLayer/LimitLayer';
import DynamicNoteLayer from '@features/user/components/dynamicNoteLayer/DynamicNoteLayer';
import PushNotificationLayer from '@features/user/components/pushNotificationLayer/PushNotificationLayer';
import AcceptCookieLayer from '@features/app/layout/acceptCookieLayer/AcceptCookieLayer';
import OddsComparisonLayer from '@features/oddsComparison/components/oddsComparisonLayer/OddsComparisonLayer';
import AutomaticDepositConfirmLayer from '@features/user/components/automaticDepositConfirmLayer/AutomaticDepositConfirmLayer';
import LimitLoaderLayer from '@features/user/components/limitLayer/LimitLoaderLayer';
import services from '@features/core/services';
import CancelBonus from '@features/user/components/cancelBonus/CancelBonus';
import GCMSLayer from '@features/staticPage/components/gcmsLayer/GCMSLayer';

import {
  WALLET_SWITCHER,
  SUMMARY_LAYER,
  TERMS_LAYER,
  REALITY_LAYER,
  LUGAS_LAYER,
  LUGAS_BLOCK_LAYER,
  LUGAS_COOLDOWN,
  WALLET_REQUEST,
  DEPOSIT_LIMIT_LAYER,
  BLOCK_ACCOUNT_SUBMIT_LAYER,
  LUGAS_INACTIVE,
  LIMIT_LAYER_INCREASE,
  LIMIT_LAYER_VERIFICATION,
  DYNAMIC_NOTE_LAYER,
  PUSH_NOTIFICATION_LAYER,
  ACCEPT_COOKIE_LAYER,
  ODDS_COMPARISON_LAYER,
  AUTOMATIC_DEPOSIT_CONFIRM_LAYER,
  LIMIT_LAYER_CREDIT_CHECK,
  LIMIT_LAYER_CREDIT_CHECK_REJECT,
  LIMIT_LOADER_LAYER,
  CANCEL_BONUS,
  GCMS_FORM_SUBMISSION,
  GCMS_FORM_ERROR,
  LIMIT_LAYER_CREDIT_SAVED_LIMITS,
} from '@common/constants/dialogs';
import { getOpenDialog } from '@common/helpers/appState/dialogs';
import {
  closeSidebars,
  toggleContentScroll,
  useAppState,
} from '@common/providers/application/useAppState';
import { isNativeApp } from '@common/helpers/links';
import { isDesktopView } from '@common/helpers/deviceUtil';
import { useWalletsState } from '@common/providers/payments/wallets/useWallets';
import {
  isMatchWebRoutes,
  isMatchWebStaticRoutes,
} from '@common/providers/router/helper';
import { useIsLoggedIn } from '@common/helpers/userHelper/loggedInState';

import * as S from './Sidebar.styled';

const getLayer = (key: string | undefined): ISidebar => {
  switch (key) {
    case LUGAS_LAYER: {
      return LugasLayer;
    }
    case LUGAS_BLOCK_LAYER: {
      return LugasBlockLayer;
    }
    case LUGAS_COOLDOWN: {
      return LugasCooldown;
    }
    case LUGAS_INACTIVE: {
      return LugasInactive;
    }
    case TERMS_LAYER: {
      return AcceptTerms;
    }
    case WALLET_REQUEST: {
      return WalletConfirm;
    }
    case REALITY_LAYER: {
      return RealityCheck;
    }
    case SUMMARY_LAYER: {
      return AccountSummary;
    }
    case LIMIT_LAYER_INCREASE:
    case LIMIT_LAYER_VERIFICATION:
    case LIMIT_LAYER_CREDIT_SAVED_LIMITS:
    case LIMIT_LAYER_CREDIT_CHECK_REJECT:
    case LIMIT_LAYER_CREDIT_CHECK: {
      return LimitLayer;
    }
    case LIMIT_LOADER_LAYER: {
      return LimitLoaderLayer;
    }
    case WALLET_SWITCHER: {
      return WalletSwitcher;
    }
    case ACCEPT_COOKIE_LAYER: {
      return AcceptCookieLayer;
    }
    case DEPOSIT_LIMIT_LAYER: {
      return DepositLimit;
    }
    case BLOCK_ACCOUNT_SUBMIT_LAYER: {
      return BlockAccountSubmitLayer;
    }
    case DYNAMIC_NOTE_LAYER: {
      return DynamicNoteLayer;
    }
    case PUSH_NOTIFICATION_LAYER: {
      return PushNotificationLayer;
    }
    case AUTOMATIC_DEPOSIT_CONFIRM_LAYER: {
      return AutomaticDepositConfirmLayer;
    }
    case ODDS_COMPARISON_LAYER: {
      return OddsComparisonLayer;
    }
    case CANCEL_BONUS: {
      return CancelBonus;
    }
    case GCMS_FORM_SUBMISSION:
    case GCMS_FORM_ERROR: {
      GCMSLayer.meta.props = { type: '', title: '', content: '' };
      return GCMSLayer;
    }
    default:
      return MenuTab;
  }
};

const Sidebar: React.FC = () => {
  const { domainLang } = services;
  const currentKey = getOpenDialog();
  const CurrentLayer = getLayer(currentKey);
  const isSummary = currentKey === SUMMARY_LAYER;
  const isBlockAccount = currentKey === BLOCK_ACCOUNT_SUBMIT_LAYER;
  const isTerms = currentKey === TERMS_LAYER;
  const isWalletConfirm = currentKey === WALLET_REQUEST;
  const isReality = currentKey === REALITY_LAYER;
  const isLugas = currentKey === LUGAS_LAYER;
  const isLugasBlock =
    currentKey === LUGAS_BLOCK_LAYER || currentKey === LUGAS_COOLDOWN;
  const isLugasInactive = currentKey === LUGAS_INACTIVE;
  const { pathname, search } = useLocation();
  const nativeApp = isNativeApp(search);
  const isTermPage = isMatchWebStaticRoutes(
    pathname,
    domainLang,
    StaticSlugPageName.TERMS,
  );
  const isVerificationPage = isMatchWebRoutes(PageName.USER_VERIFICATION);
  const disableTermPopup = isTerms && (isTermPage || isVerificationPage);

  const isDesktop = isDesktopView();
  const view = useWalletsState(state => state.view);
  const isWalletSwitcher = currentKey === WALLET_SWITCHER;
  const isSwitcherWithDarkening =
    (isDesktop && view === IWalletView.POPUP_VIEW) || !isDesktop;
  const hasTopAppBanner = useAppState(state => state.hasTopAppBanner);
  const isShowFooter = useAppState(state => state.showFooter);
  const isLoggedIn = useIsLoggedIn();
  const isWalletSwitcherSmallView =
    isWalletSwitcher && view === IWalletView.SMALL_VIEW;
  const walletSwitcherTopDistance =
    !hasTopAppBanner || isDesktop || view === IWalletView.POPUP_VIEW ? 0 : 60;
  const {
    side,
    onClose,
    onOpen,
    preventDarkening,
    fullScreen,
  } = CurrentLayer.meta;

  useEffect(() => {
    if (!disableTermPopup) {
      toggleContentScroll('disable');
    }
    if (onOpen) {
      onOpen();
    }
    return () => {
      toggleContentScroll('enable');
      if (onClose) {
        onClose();
      }
    };
  }, []);

  if (
    (isSummary ||
      isTerms ||
      isReality ||
      isLugas ||
      isLugasBlock ||
      isLugasInactive ||
      isWalletConfirm ||
      isBlockAccount) &&
    !isLoggedIn
  ) {
    closeSidebars();
  }
  if (disableTermPopup) {
    return null;
  }
  if (isWalletSwitcherSmallView && isDesktop) {
    return <CurrentLayer />;
  }

  return (
    <S.Root
      side={side}
      fullHeight={nativeApp}
      preventDarkening={
        isWalletSwitcher ? !isSwitcherWithDarkening : !!preventDarkening
      }
      isShowFooter={isShowFooter}
      fullScreen={!!fullScreen}
      data-qa="sidebar"
      applicationAdvBannerHeight={walletSwitcherTopDistance}
    >
      <CurrentLayer />
    </S.Root>
  );
};

export default Sidebar;
