import React from 'react';
import { useTranslation } from 'react-i18next';
import map from 'lodash/map';
import { useTheme } from 'styled-components';

import { SingleIcon } from '@ui/components/icon';

import * as S from './Description.styled';

const Description: React.FC<{
  isOnline: boolean;
  isAccountBlockType?: boolean;
}> = ({ isOnline, isAccountBlockType }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const allowIcon = 'allow-filled';
  const denyIcon = 'switcher-cross';

  const items = [
    {
      icon: allowIcon,
      text: t('wallet_switcher.cashout'),
      fill: [theme.statusSuccess, theme.textMain],
    },
    {
      icon: isOnline ? allowIcon : denyIcon,
      text: t(
        `${
          isAccountBlockType
            ? 'wallet_switcher.betting_online'
            : 'wallet_switcher.betting'
        }`,
      ),
      fill: isOnline ? [theme.statusSuccess, theme.textMain] : theme.textMain,
    },
    {
      icon: isOnline ? allowIcon : denyIcon,
      text: t(
        isAccountBlockType
          ? 'wallet_switcher.deposit_withdraw_online'
          : 'wallet_switcher.deposit_withdraw',
      ),
      fill: isOnline ? [theme.statusSuccess, theme.textMain] : theme.textMain,
    },
  ];

  const getIconSize = (isDenyIcon): string => {
    if (isAccountBlockType && isDenyIcon) {
      return '9px';
    }
    if (!isAccountBlockType && isDenyIcon) {
      return '7px';
    }
    if (isAccountBlockType) {
      return '20px';
    }
    return '16px';
  };

  return (
    <S.Root isAccountBlockType={!!isAccountBlockType}>
      {map(items, (item, index) => {
        const isDenyIcon = item.icon === denyIcon;
        return (
          <S.Item key={index}>
            <S.IconBackground
              isDenyIcon={isDenyIcon}
              isAccountBlockType={!!isAccountBlockType}
            >
              <SingleIcon
                name={item.icon}
                width={getIconSize(isDenyIcon)}
                height={getIconSize(isDenyIcon)}
                fill={item.fill}
              />
            </S.IconBackground>

            <S.Text isAccountBlockType={!!isAccountBlockType}>
              {item.text}
            </S.Text>
          </S.Item>
        );
      })}
    </S.Root>
  );
};

Description.defaultProps = {
  isAccountBlockType: false,
};

export default Description;
