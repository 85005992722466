import React from 'react';
import { useTheme } from 'styled-components';

import { NotStartedTimer } from '@features/events/components/scoreboards/timers';
import BetPackerIcon from '@features/detail/components/betPacker/BetPackerIcon';
import { IBetPackerIconSize } from '@features/detail/components/betPacker/BetPacker.types';

import { goBack } from '@common/providers/router/helper';
import {
  getAwayLabel,
  getHomeLabel,
} from '@common/helpers/eventsHelper/eventLabelHelper';
import { openEventDetail } from '@common/helpers/eventsHelper/eventRouteHelper';
import {
  STATE_FROZEN,
  STATE_QUEUED,
} from '@common/providers/bettingslip/state';
import { useBettingSlip } from '@common/providers/bettingslip/useBettingSlip';

import { Icon } from '@ui/components/icon';

import IBetPackerTotalViewWrapperProps from './BetPackerTotalViewWrapper.types';
import * as S from './BetPackerTotalViewWrapper.styled';

const BetPackerTotalViewWrapper: React.FC<IBetPackerTotalViewWrapperProps> = ({
  children,
  id,
  betPackerOdds,
  onClear,
  event,
}) => {
  const state = useBettingSlip(s => s.state);
  const { iconFillLight } = useTheme();
  const handleSelectingEvent = (): void => {
    if (state === STATE_FROZEN || state === STATE_QUEUED) {
      return;
    }
    openEventDetail({ eventId: event.id });
  };

  const handleClearBets = (): void => {
    onClear();
    goBack();
  };

  return (
    <S.BetPackerTotalViewWrapper>
      <S.EventWrapper onClick={(): void => handleSelectingEvent()}>
        <S.EventLabel>
          <S.Label>{getHomeLabel(event?.label || '')}</S.Label>
          <S.Label>{getAwayLabel(event?.label || '')}</S.Label>
        </S.EventLabel>
        <S.Scoreboard>
          <NotStartedTimer event={event} shortTimer />
        </S.Scoreboard>
      </S.EventWrapper>
      <S.MainWrapper key={id}>
        <S.ChildWrapper>{children}</S.ChildWrapper>
        <S.BetPackerIconWrapper>
          <BetPackerIcon size={IBetPackerIconSize.normal} />
          <S.Odds>{betPackerOdds}</S.Odds>
          <S.IconWrapper onClick={handleClearBets}>
            <Icon
              name="delete-icon"
              width="16"
              height="16"
              fill={iconFillLight}
            />
          </S.IconWrapper>
        </S.BetPackerIconWrapper>
      </S.MainWrapper>
    </S.BetPackerTotalViewWrapper>
  );
};

export default BetPackerTotalViewWrapper;
