import styled from 'styled-components';

const ButtonDisableStyles = `
&:disabled + label > div, + div > span {
   opacity: 0.6;
   cursor: auto;
   }
`;

export const getBorderColor = (
  theme,
  isDarkMode,
  isChecked,
  isBlueTheme = false,
) => {
  if (isBlueTheme) {
    return isChecked
      ? theme.inputSuccessBorderColor
      : theme.buttonMainTextColor;
  }

  if (isChecked) {
    return isDarkMode ? theme.inputSuccessBorderColor : theme.accent;
  }

  return isDarkMode ? theme.inputDefaultText : theme.buttonMainTextColor;
};

export const RadioField = styled.input<{
  disabled: boolean;
  isDarkMode: boolean;
}>`
  display: none;

  &:checked + label > div {
    border-color: ${({ theme, isDarkMode }): string =>
      getBorderColor(theme, isDarkMode, true)};

    &::after {
      content: '';
      position: absolute;
      top: calc(50% - 6px);
      left: calc(50% - 6px);
      border-radius: 50%;
      width: 12px;
      height: 12px;
      background-color: ${({ theme, isDarkMode }): string =>
        getBorderColor(theme, isDarkMode, true)};
      transition: color 0.2s;
    }
  }

  ${({ disabled }) => disabled && ButtonDisableStyles}
`;

export const RadioLabel = styled.div<{ isError: boolean; isDarkMode: boolean }>`
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  min-width: ${({ theme }) => theme.fontH3};
  border: 1px solid
    ${({ theme, isError, isDarkMode }): string =>
      isError ? theme.statusError : getBorderColor(theme, isDarkMode, false)};
  border-radius: 50%;
  margin-right: ${({ theme }) => theme.space_10};
`;

export const Wrapper = styled.label<{
  isBottomMargin: boolean;
  isDarkMode: boolean;
}>`
  display: flex;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  ${({ isBottomMargin }) => isBottomMargin && 'margin-bottom: 20px;'}

  #radio-title {
    color: ${({ theme, isDarkMode }): string =>
      isDarkMode ? theme.inputDefaultText : theme.textMain};
  }

  & :first-child {
    align-self: flex-start;
  }
`;
