import React, { useEffect, useState, useMemo, useCallback } from 'react';
import includes from 'lodash/includes';

import { StakeInfo } from '@features/bettingslip/components/stakeInfo';
import { VirtualContainer } from '@features/bettingslip/components/virtualContainer';
import { Systems } from '@features/bettingslip/components/systems';
import { Tabs } from '@features/bettingslip/components/tabs';
import KeyboardTarget from '@features/bettingslip/keyboardEnum';

import { isDesktopView, roundDown } from '@common/helpers/deviceUtil';
import { getFormattedAmount } from '@common/helpers/numeralHelper';
import {
  STATE_FROZEN,
  STATE_QUEUED,
} from '@common/providers/bettingslip/state';
import {
  setStake,
  toggleBSWalletType,
  useBettingSlip,
} from '@common/providers/bettingslip/useBettingSlip';
import {
  hasBonusWallets,
  getActiveWalletBalance,
  getBettingSlipBalance,
  hasBettingSlipWalletSwitcher,
  getDefaultBettingslipWallet,
  getAllowedExistingWallets,
} from '@common/helpers/paymentsHelper/walletsHelper';
import { IUserWalletType } from '@common/interfaces/user/IUser';
import usePrevious from '@common/hooks/usePrevious';
import { useUserState } from '@common/providers/user/useUserState';
import { useWalletsState } from '@common/providers/payments/wallets/useWallets';

import { VirtualKeyboard } from '@ui/components/virtual';

import { BettingSlipWalletSwitcher } from '../bettingSlipWalletSwitcher';

import Virtual from './StakeForm.styled';

const MAX_BET_VALUE = 15000;
const isDesktop = isDesktopView();

const StakeForm: React.FC = () => {
  const totalAmount = useBettingSlip(s => s.totalStake);
  const stake = useBettingSlip(s => s.stake);
  const walletType = useBettingSlip(s => s.walletType);
  const legsCount = useBettingSlip(s => s.legsCount);
  const banksLength = useBettingSlip(s => s.banks.length);
  const totalStake = useBettingSlip(s => s.totalStake);
  const state = useBettingSlip(s => s.state);
  const username = useUserState(s => s.data?.username);
  const wallets = useWalletsState(s => s.data);
  const [keyboardTarget, onSetKeyboardTarget] = useState<KeyboardTarget>(
    KeyboardTarget.MULTIPLE,
  );

  const prevSingleStake = usePrevious(getFormattedAmount(stake));
  const bonusMoney = getActiveWalletBalance(IUserWalletType.bonus);
  const hasBonusSection = hasBonusWallets() && bonusMoney;
  const currentWalletBalance = getBettingSlipBalance();

  const [currentInput, setCurrentInput] = useState(
    String(getFormattedAmount(totalAmount)),
  );
  const [shouldReplace, setShouldReplace] = useState(false);

  const setDefaultWalletType = (): void => {
    if (!includes(getAllowedExistingWallets(), walletType)) {
      toggleBSWalletType(getDefaultBettingslipWallet());
    }
  };

  useEffect(() => {
    setDefaultWalletType();
  }, [username, wallets]);

  useEffect(() => {
    setDefaultWalletType();
  }, []);

  useEffect(() => {
    setShouldReplace(true);
  }, [banksLength, legsCount, walletType]);

  const onSetStake = useCallback(
    (value: string, node: KeyboardTarget): void => {
      const currentValue = value;
      setShouldReplace(false);

      if (prevSingleStake !== currentValue) {
        setStake({
          amount: currentValue,
          isTotal: node === KeyboardTarget.MULTIPLE,
        });
      }

      setCurrentInput(currentValue);
    },
    [prevSingleStake],
  );

  useEffect(() => {
    if (hasBonusSection && currentWalletBalance < totalStake) {
      const value = getFormattedAmount(currentWalletBalance);
      setStake({
        amount: value,
        isTotal: true,
      });
      setCurrentInput(value);
    }
  }, [hasBonusSection, walletType]);

  const maxValue = useMemo(
    (): number =>
      keyboardTarget === KeyboardTarget.SINGLE
        ? roundDown(MAX_BET_VALUE / (legsCount || 1))
        : MAX_BET_VALUE,
    [legsCount, keyboardTarget],
  );

  const handleKeyboardInput = (value: string, target: KeyboardTarget): void => {
    if (state === STATE_FROZEN || state === STATE_QUEUED) {
      return;
    }
    onSetStake(value, target);
  };

  return (
    <>
      <Tabs />
      <Systems />
      {hasBettingSlipWalletSwitcher() && <BettingSlipWalletSwitcher />}
      <div>
        <Virtual>
          <VirtualContainer
            keyboardTarget={keyboardTarget}
            onSetKeyboardTarget={onSetKeyboardTarget}
            currentValue={currentInput}
            onBlur={onSetStake}
            setShouldReplace={setShouldReplace}
          />
          {!isDesktop && (
            <VirtualKeyboard
              value={currentInput}
              maxValue={maxValue}
              shouldReplace={shouldReplace}
              onChange={(value): void => {
                handleKeyboardInput(value, keyboardTarget);
              }}
            />
          )}
          <StakeInfo />
        </Virtual>
      </div>
    </>
  );
};

export default StakeForm;
