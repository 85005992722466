import React, { useState } from 'react';
import map from 'lodash/map';
import find from 'lodash/find';
import reject from 'lodash/reject';

import ISwitcherProps, {
  ISwitcherItem,
} from '@features/wallets/switcher/Switcher.types';

import { IBSWalletType } from '@common/interfaces/bettingslip/IBettingslip';

import { Icon } from '@ui/components/icon';

import * as S from './Select.styled';

const Select: React.FC<ISwitcherProps> = ({
  items,
  activeItem,
  onSelectItem,
}) => {
  const [opened, setOpened] = useState<boolean>(false);
  const activeListItem = find(items, ['id', activeItem]);
  const renderItem = (item: ISwitcherItem, key): React.ReactElement => (
    <S.Item
      className="item"
      onClick={(): void => {
        onSelectItem(
          (item as unknown) as {
            affiliateID: string;
            walletType: IBSWalletType;
          },
        );
        setOpened(false);
      }}
      key={key}
    >
      <S.ItemContentWrap>
        {item.icon && (
          <S.ItemIconWrap>
            <S.ItemIcon
              className="icon"
              name={item.icon.name}
              width={item.icon.width}
              height={item.icon.height}
            />
          </S.ItemIconWrap>
        )}

        <S.ItemTitle>{item.title}</S.ItemTitle>
      </S.ItemContentWrap>

      {item.description && (
        <S.ItemDescription>{item.description}</S.ItemDescription>
      )}
    </S.Item>
  );

  return (
    <S.Root isOpen={opened}>
      <S.ActiveItem
        isOpen={opened}
        onClick={(): void => setOpened(() => !opened)}
      >
        {activeListItem ? renderItem(activeListItem, -1) : null}

        <S.ListToggle isOpen={opened}>
          <Icon name="chevron-large-down" width="15px" />
        </S.ListToggle>
      </S.ActiveItem>

      {opened && (
        <S.List>
          {map(reject(items, ['id', activeItem]), (item, index) =>
            renderItem(item, index),
          )}
        </S.List>
      )}
    </S.Root>
  );
};

export default Select;
