import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import includes from 'lodash/includes';

import { ITransactionIconProps } from '@features/transactions/components/transactionItem/transactionIcon/TransactionIconsWrapper.types';
import {
  IReturnIconProps,
  SuccessType,
} from '@features/transactions/components/Transaction.types';
import { colorSchema } from '@features/core/theming/contexTheme';

import { IPaymentOperation } from '@common/interfaces';

import * as S from './TransactionIconsWrapper.styled';

const TransactionIconsWrapper: React.FC<ITransactionIconProps> = props => {
  const { t } = useTranslation();
  const { transaction, isAccountSummary } = props;
  const isAdministrativeIcon =
    transaction.description === 'Pariplay Casino  (Pariplay Casino)' ||
    transaction.description === 'Auszahlung - Banküberweisung' ||
    transaction.description === 'Withdrawal - Bank transfer' ||
    includes(transaction.description, 'Administrative');
  const isProfit = parseFloat(transaction.amount) > 0;
  const getIconDescriptionByType = (type: string): string =>
    transaction?.description?.match(new RegExp(type, 'g'))
      ? transaction?.description
      : '';

  const getTransactionProfitType = (): SuccessType => {
    if (isProfit) {
      return 'win';
    }
    return 'loss';
  };
  const transactionProfitType = getTransactionProfitType();

  const getTransactionIconProps = (): IReturnIconProps => {
    const { textMain, accent } = colorSchema;
    const baseIconProps = {
      width: '16px',
      height: '16px',
      fill: 'currentColor',
    };

    switch (transaction.description) {
      case 'Cashout':
      case t('cashout.pending_cashout'):
        return {
          ...baseIconProps,
          name: 'euro-arrow-circle',
        };
      case getIconDescriptionByType('Einzahlung'):
      case getIconDescriptionByType('Deposit'):
      case getIconDescriptionByType('Bank'):
      case getIconDescriptionByType('Auszahlung'):
      case getIconDescriptionByType('Withdrawal'):
      case 'Echtgeld-Gutschrift':
      case 'Real money credit':
        return {
          ...baseIconProps,
          name: `transfer-${
            isProfit ? IPaymentOperation.DEPOSIT : IPaymentOperation.WITHDRAWAL
          }`,
        };
      case getIconDescriptionByType('Bonus'):
        return {
          ...baseIconProps,
          width: '15px',
          height: '20px',
          name: 'gift',
        };
      case 'Pariplay Casino  (Pariplay Casino)':
      case 'Auszahlung - Banküberweisung':
      case 'Withdrawal - Bank transfer':
        return { ...baseIconProps, name: 'down-arrow' };
      case 'Administrative':
      case 'Administrative booking - ':
      case 'Administrative Buchung - ':
        if (!isProfit) {
          return {
            ...baseIconProps,
            name: 'down-arrow',
            fill: textMain,
            height: '18px',
          };
        }
        return {
          ...baseIconProps,
          name: 'up-arrow',
          fill: accent,
          height: '18px',
        };
      case 'Wette':
      case 'Bet':
      case 'Gewinn':
        return {
          ...baseIconProps,
          width: '15px',
          name: 'ticket',
        };
      default:
        return { ...baseIconProps, name: '' };
    }
  };

  const iconProps = useMemo(() => getTransactionIconProps(), [
    transaction.description,
    transactionProfitType,
  ]);

  const getColor = (): string => {
    const { textMain, accent, inputDefaultText } = colorSchema;
    if (isAccountSummary) {
      return inputDefaultText;
    }

    return isProfit ? accent : textMain;
  };
  const isTicketIcon = iconProps.name === 'ticket';
  return (
    <S.IconsWrapper>
      {isAdministrativeIcon && (
        <S.EuroIcon iconColor={getColor()}>&euro;</S.EuroIcon>
      )}
      <S.TransactionIcon
        iconColor={getColor()}
        isTicketIcon={isTicketIcon}
        {...iconProps}
      />
    </S.IconsWrapper>
  );
};

export default TransactionIconsWrapper;
