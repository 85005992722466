import find from 'lodash/find';

import services from '@features/core/services';

import { DEPOSIT_LIMIT_LAYER } from '@common/constants/dialogs';
import { DEPOSIT_LIMIT_LAYER_COOKIE } from '@common/constants/cookie';
import fetchLimit from '@common/api/limits/fetchLimit';
import { MethodType } from '@common/api/apiHandler';
import fetchPocketTags from '@common/api/account/fetchPocketTags';
import { openSidebar } from '@common/providers/application/useAppState';
import { hasDepositLayer } from '@common/helpers/userHelper/hasLugasRestrictions';
import { createExpiresStamp } from '@common/helpers/deviceUtil';
import { ILimit } from '@common/interfaces';

export const authLimits = async (user, paymentAccount): Promise<void> => {
  const depositLayer = await fetchPocketTags({
    method: MethodType.Get,
    params: {
      tag: { key: 'usr.frontend.shoponline_depositlimit_layer_seen' },
    },
  });

  const limits = await fetchLimit({
    token: paymentAccount.account.token,
    accountId: paymentAccount.account.id,
    limitType: 'sys.deposit.self_limit',
  });

  const monthlyLimit = (find(limits, [
    'data.attribute',
    'sys.deposit.self_limit.monthly',
  ]) as unknown) as ILimit;

  if (
    depositLayer.tag &&
    !depositLayer.tag.value &&
    !parseFloat(monthlyLimit?.data.value as string)
  ) {
    openSidebar({ type: DEPOSIT_LIMIT_LAYER });
  }

  if (hasDepositLayer(user, depositLayer, monthlyLimit)) {
    services.cookie.set(
      DEPOSIT_LIMIT_LAYER_COOKIE,
      String(depositLayer?.tag?.value),
      {
        expires: createExpiresStamp(60 * 60 * 24 * 365),
      },
    );
  }
};

export default authLimits;
