import React, { useRef, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import keys from 'lodash/keys';
import includes from 'lodash/includes';
import { animated as a } from '@react-spring/web';
import { useTheme } from 'styled-components';

import { PageName } from '@features/core/routing/linkAliases';
import services from '@features/core/services';
import BetDetailContent from '@features/bets/components/betDetail/BetDetailsContent';
import { useBetDetailSocket } from '@features/bets/hooks/useBetsSocket';

import { USER_ID } from '@common/constants/cookie';
import { isMatchWebRoutes, setLocation } from '@common/providers/router/helper';
import { isDesktopView } from '@common/helpers/deviceUtil';
import {
  ANIMATED_BET_DETAIL,
  useAnimatedX,
} from '@common/helpers/appLayoutHelper';
import {
  clearBetDetail,
  setSelectedBetId,
  useBets,
} from '@common/providers/bets/useBets';
import { getBetDetails } from '@common/providers/bets/helper';
import { useCashoutState } from '@common/providers/cashout/useCashout';
import { STATE_ACCEPTED } from '@common/providers/cashout/state';
import useOnClickOutside from '@common/hooks/useOnClickOutside';

import { DualRingLoader } from '@ui/components/loaders';
import { INotificationIcon } from '@ui/components/genericNotification/GenericNotification.types';
import { GenericNotification } from '@ui/components/genericNotification';

import { IBetDetail } from './BetDetail.types';
import * as S from './BetDetail.styled';

const BetDetail: React.FC<IBetDetail> = ({ id }) => {
  const theme: { main: string } = useTheme();
  const { id: betQueryId }: { id: string } = useParams();
  const { t } = useTranslation();
  const bet = useBets(s => s.bet_detail);
  const betId = id || bet.id || betQueryId;
  const error = useBets(s => s.betDetailError);
  const loading = useBets(s => s.detailLoading);
  const requestedCashoutState = useCashoutState(s => s.requested[betId])?.state;
  const isScannerResultPage = includes(window.location.href || '', 'scanbet');
  const isDetailPage = isMatchWebRoutes(PageName.USER_BET_ITEM);
  const shouldRedirectToDesk = isDesktopView() && isDetailPage;
  const barcode = isScannerResultPage ? betQueryId : undefined;
  const userId = services.cookie.get(USER_ID);
  const shouldRefreshByState =
    isScannerResultPage && requestedCashoutState === STATE_ACCEPTED;
  const holderRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    return (): void => {
      clearBetDetail();
    };
  }, []);

  useBetDetailSocket({
    betId: bet.id,
    barcode,
    isScannerResultPage,
    userId,
    betUserId: bet.user_id,
  });

  useEffect(() => {
    if (!isScannerResultPage || !bet.id || shouldRefreshByState) {
      getBetDetails({ bet_id: betId, barcode });
    }
  }, [betId, shouldRefreshByState]);

  useEffect(() => {
    if (shouldRedirectToDesk && betId) {
      setLocation(PageName.USER_BETS);
      setSelectedBetId(betId);
    }
  }, [shouldRedirectToDesk, betId]);

  useOnClickOutside(holderRef, event => {
    const betHolder = document.getElementById('scrollableDiv');
    if (!betHolder?.contains(event.target)) {
      setSelectedBetId('');
    }
  });

  return (
    <a.div ref={holderRef} style={{ ...useAnimatedX(ANIMATED_BET_DETAIL) }}>
      <S.Container
        className="betdetail"
        id={`betdetail-${betId}`}
        isTransparentDetail={shouldRedirectToDesk}
      >
        <div id="bet_detail">
          {(loading || shouldRedirectToDesk) && (
            <DualRingLoader backgroundColor={theme.main} />
          )}
          {keys(bet).length !== 0 && !loading && !shouldRedirectToDesk && (
            <BetDetailContent bet={bet} betId={betId} barcode={barcode} />
          )}

          {!keys(bet).length && !loading && error && (
            <GenericNotification iconType={INotificationIcon.error}>
              {t('bets.error_bet_detail')}
            </GenericNotification>
          )}
        </div>
      </S.Container>
    </a.div>
  );
};

export default BetDetail;
