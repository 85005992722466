import styled from 'styled-components';

export const NotificationWrapper = styled.div<{ isBonusNotifier?: boolean }>`
  display: grid;
  grid-template-columns: auto 1fr;
  font-size: ${props => props.theme.fontMD};
  align-items: center;
  color: ${props => props.theme.main};
  padding-right: ${({ theme }) => theme.space_10};

  & svg {
    margin: 0 ${({ theme }) => theme.space_10} 0 0;
  }

  & > div {
    ${({ isBonusNotifier }) => isBonusNotifier && 'padding: 2px 0'};
  }
`;

export const RemoveSuspendedButton = styled.button`
  padding: ${({ theme }) => theme.space_10} ${({ theme }) => theme.space_20};
  width: 100%;
  box-sizing: border-box;
  min-width: 225px;
  border-radius: 3px;
  color: ${props => props.theme.textMain};
  background-color: ${props => props.theme.tertiaryBackground};
  border: ${props => props.theme.border};
  font-weight: ${({ theme }) => theme.fontBold};
  text-align: center;
  text-transform: uppercase;
  margin-top: ${({ theme }) => theme.space_5};
`;
