import React, { useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import omitBy from 'lodash/omitBy';

import { PageName } from '@features/core/routing/linkAliases';

import {
  getSportName,
  getCategoryById,
  getPath,
  getIdsPathToCategory,
} from '@common/helpers/categories/categoriesModel';
import { IEvent } from '@common/interfaces';
import { setLocation } from '@common/providers/router/helper';
import { getDateLabel } from '@common/helpers/eventsHelper/scoreboards/timerModel';
import { getLabel } from '@common/helpers/eventsHelper/eventLabelHelper';
import { openEventDetail } from '@common/helpers/eventsHelper/eventRouteHelper';
import { useLiveCategories } from '@common/providers/events/liveCategories/useLiveCategories';
import {
  resetSearchResult,
  useSearchState,
} from '@common/providers/search/useSearch';
import { isQuotenboost } from '@common/helpers/eventsHelper/eventStatusHelper';

import { Icon } from '@ui/components/icon';

import { ISearchResults } from './SearchResults.types';
import * as S from './SearchResults.styled';

const SearchResults: React.FC<ISearchResults> = ({
  searchResultRef,
  value,
  isHeader,
  onClose,
}) => {
  const { t } = useTranslation();
  const categoriesLoading = useSearchState(s => s.categoriesLoading);
  const eventsLoading = useSearchState(s => s.eventsLoading);
  const categories = useSearchState(s => s.categories);
  const searchStatus = useSearchState(s => s.searchStatus);
  const eventCategories = useSearchState(s => s.eventCategories);
  const events = omitBy(
    useSearchState(s => s.events),
    event => isQuotenboost(getLabel(event)),
  );

  const categoriesTree = useLiveCategories(s => s.sportCategoriesTree.data);

  const goToEvent = (eventCategoryId, eventId): void => {
    const topCategoryId = eventCategories[eventCategoryId]?.top_category_id;
    openEventDetail({
      eventId,
      categoryId: eventCategoryId,
      topCategoryId,
    });
  };

  const goToCategory = (catId): void => {
    const topCategoryId = getIdsPathToCategory(categoriesTree, catId)[0];
    const search = `?categories=${catId}`;

    setLocation(
      PageName.EVENTS_CATEGORY,
      {
        categoryId: topCategoryId,
        filter: 'all',
      },
      { search },
    );
  };

  const getEventSubTitle = useCallback(
    (event: IEvent) => {
      const { category_id } = event;
      const eventDate = getDateLabel(event.expires_ts);
      const sportName = getCategoryById(categoriesTree, category_id)?.label;

      return `${eventDate} / ${sportName}`;
    },
    [categoriesTree],
  );

  const getCategorySubTitle = useCallback(
    (categoryId: string) => {
      const categoryById = getCategoryById(categoriesTree, categoryId);
      return getPath(categoryById);
    },
    [categoriesTree],
  );

  const getIconName = useCallback(
    categoryId => {
      const topCategory = eventCategories[categoryId].top_category_id;
      return getSportName(topCategory);
    },
    [eventCategories],
  );

  useEffect(() => {
    return () => resetSearchResult();
  }, []);

  const renderSearchResults = (): React.ReactNode => {
    const loading = eventsLoading || categoriesLoading;
    if (!loading && isEmpty(events) && isEmpty(categories) && searchStatus) {
      return (
        <S.SearchResultsList>
          <S.NotFound>
            {`${t('home_page.search.not_found_for')} ${value}`}
          </S.NotFound>
        </S.SearchResultsList>
      );
    }

    if (isEmpty(events) && isEmpty(categories)) {
      return null;
    }

    return (
      <S.SearchResultsList>
        {!isEmpty(events) && (
          <>
            <S.ResultTypeTitle>
              {t('home_page.search.events')}
            </S.ResultTypeTitle>
            {map(events, (event, id) => (
              <S.SearchResultsListItem
                key={id}
                onClick={(): void => {
                  goToEvent(event.category_id, id);
                  onClose();
                }}
              >
                <S.Icon>
                  <Icon
                    name={getIconName(event.category_id)}
                    width="18px"
                    height="18px"
                  />
                </S.Icon>

                <S.Details>
                  <S.Title>{getLabel(event)}</S.Title>
                  <S.Subtitle>{getEventSubTitle(event)}</S.Subtitle>
                </S.Details>
              </S.SearchResultsListItem>
            ))}
          </>
        )}
        {!isEmpty(categories) && (
          <>
            <S.Divider />
            <S.ResultTypeTitle>{t('home_page.categories')}</S.ResultTypeTitle>
            {map(categories, category => (
              <S.SearchResultsListItem
                key={category.id}
                onClick={(): void => {
                  goToCategory(category.id);
                  onClose();
                }}
              >
                <S.Icon>
                  <Icon
                    name={getSportName(category.top_category_id)}
                    width="18px"
                    height="18px"
                  />
                </S.Icon>

                <S.Details>
                  <S.Title>{category.label}</S.Title>
                  <S.Subtitle>
                    {getCategorySubTitle(category.top_category_id)}
                  </S.Subtitle>
                </S.Details>
              </S.SearchResultsListItem>
            ))}
          </>
        )}
      </S.SearchResultsList>
    );
  };

  const searchResults = renderSearchResults();

  return (
    <S.SearchContainer
      ref={searchResultRef}
      isHeader={isHeader}
      hasResults={!!searchResults}
    >
      {searchResults}
    </S.SearchContainer>
  );
};

export default SearchResults;
