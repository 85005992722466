import map from 'lodash/map';
import flatMap from 'lodash/flatMap';
import compact from 'lodash/compact';
import uniq from 'lodash/uniq';
import sortBy from 'lodash/sortBy';
import trim from 'lodash/trim';
import replace from 'lodash/replace';
import split from 'lodash/split';
import includes from 'lodash/includes';
import some from 'lodash/some';
import parseISO from 'date-fns/parseISO';
import isNaN from 'lodash/isNaN';

import i18next from '@features/core/translation';

import {
  ICashoutRequest,
  ICashoutSerialize,
  IQueudCashout,
  IBetSelection,
  IBetDetail,
  IEvent,
  IQueudCashoutSerialize,
} from '@common/interfaces';
import { isLiveCurrent } from '@common/helpers/eventsHelper/eventStatusHelper';
import numeral from '@common/helpers/numeralHelper';
import { formatBalance } from '@common/helpers/paymentsHelper/walletsHelper';

export const cashoutDefaultButtonValue = '--';

/**
 * sortByTime, sort selections by time
 *
 * @param {IBetSelection[]} selections
 * @returns {IBetSelection[]} sorted
 */
export const sortByTime = (selections: IBetSelection[]): IBetSelection[] => {
  return sortBy(selections, selection => {
    return selection.event_date_safe_server
      ? parseISO(selection.event_date_safe_server)
      : selection;
  });
};

/**
 * getEventsFromCashouts
 * collect event ids for cashout
 *
 * @param {IBetDetail[]} result
 * @returns {string[]} events
 */
export const getEventsFromCashouts = (result: IBetDetail[]): string[] => {
  return uniq(
    compact(
      map(
        flatMap(map(result, 'selections')),
        el => el?.event?.id || el?.event_id || null,
      ),
    ),
  );
};

/**
 * isLiveOrder
 *
 * @param {Record<string, IEvent>} events
 * @param {IBetDetail} bet
 * @returns {boolean} liveOrder
 */
export const isLiveOrder = (
  events: Record<string, IEvent>,
  bet: IBetDetail,
): boolean => {
  return some(
    events,
    event =>
      isLiveCurrent(event) && includes(getEventsFromCashouts([bet]), event.id),
  );
};

/**
 * splitLabel
 * splits selection label
 *
 * @param {string} label
 * @returns {string[]} labels
 */
export const splitLabel = (label: string): string[] => {
  // eslint-disable-next-line sonarjs/no-dead-store
  let teams = ['', ''];
  if (includes(label, ' - ')) {
    teams = split(label, /\s-\s/);
  } else {
    teams = [label, ''];
  }
  return teams;
};

/**
 * serialize
 * prepare cashout for submission
 *
 * @param {ICashoutSerialize} cashout
 * @returns {Record<keyof ICashoutRequest, string>} serialized
 */
export const serialize = (
  cashout: ICashoutSerialize,
): Record<keyof ICashoutRequest, string> => {
  return {
    amount: cashout.amount.toFixed(2),
    offer: cashout.offer.toFixed(2),
    bet_id: cashout.bet_id,
    lang: cashout.lang,
    offer_token: cashout.offer_token || '',
    barcode: cashout?.barcode || '',
  };
};

/**
 * serializeQueued
 * prepare cashout queued for submission
 *
 * @param {IQueudCashout} payload
 * @returns {IQueudCashout} serializedQueued
 */
export const serializeQueued = (
  payload: IQueudCashoutSerialize,
): IQueudCashoutSerialize => {
  return {
    cashout_id: payload.cashout_id,
    bet_id: payload.bet_id,
    lang: payload.lang,
    queued: true,
    offer_token: payload.offer_token,
    barcode: payload.barcode,
  };
};

/**
 * sortedBetsSelector
 *
 * @param {IBetDetail[]} openBets
 * @param {Record<string, IEvent>} events
 * @param {Array<string>} favorites
 * @returns {IBetDetail[]} sorted
 */
export const sortedBetsSelector = (
  openBets: IBetDetail[],
  events: Record<string, IEvent>,
  favorites: Array<string>,
): IBetDetail[] => {
  return sortBy(openBets, bet => {
    const favoriteOrder = includes(favorites, bet.id)
      ? 1 + favorites.indexOf(bet.id)
      : 0;
    const liveOrder = isLiveOrder(events, bet) ? 2 : 1;
    return -parseFloat(
      `${favoriteOrder}${liveOrder}${bet.created_safe_server}`,
    );
  });
};

/**
 * formatTypeString
 *
 * @param {string} type
 * @returns {string} cashoutType
 */
export const formatTypeString = (type: string): string => {
  let modified: string = type;
  modified = replace(modified, /[0-9,]|\//g, '');
  return trim(modified);
};

/**
 * formatCashoutOffer
 *
 * @param {string} cashout_offer
 * @param {boolean} cashoutDisabled
 * @param {boolean} withConfirm
 * @returns {string} formatted
 */
export const formatCashoutOffer = (
  cashout_offer,
  cashoutDisabled,
  withConfirm = false,
): string => {
  if (cashout_offer === cashoutDefaultButtonValue) {
    return '';
  }
  if (cashoutDisabled) {
    return i18next.t('cashout.cashout_unavailable');
  }
  if (
    cashout_offer !== cashoutDefaultButtonValue &&
    !cashoutDisabled &&
    withConfirm
  ) {
    return `${formatBalance(cashout_offer)} ${i18next.t('cashout.confirm')}`;
  }
  return formatBalance(cashout_offer);
};

export const getNewRisk = (
  partValue: string,
  cashoutOffer: number,
  remainingRisk: string,
): number => {
  const parsedPartValue = numeral(partValue).value() || 0;
  const newRemainingRisk =
    +remainingRisk - (parsedPartValue / cashoutOffer) * +remainingRisk;

  return newRemainingRisk > 0 ? newRemainingRisk : 0;
};

export const formatCashoutButtonValue = (value: string): string =>
  !isNaN(+value)
    ? numeral(+value).format('0.00')
    : value || cashoutDefaultButtonValue;

export const getNewStake = (
  partValue: string,
  cashoutOffer: number,
  total_stake: string,
): number => {
  const parsedPartValue = numeral(partValue).value() || 0;
  const newTotalStake =
    +total_stake - (parsedPartValue / cashoutOffer) * +total_stake;

  return newTotalStake > 0 ? newTotalStake : 0;
};
