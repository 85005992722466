import hasLugasRestriction from '@common/helpers/userHelper/hasLugasRestrictions';
import { MethodType } from '@common/api/apiHandler';
import fetchPocketTags from '@common/api/account/fetchPocketTags';
import { updateUserData } from '@common/providers/user/useUserState';

export const authSelfLock = async (): Promise<void> => {
  if (hasLugasRestriction()) {
    const selfLocking = await fetchPocketTags({
      method: MethodType.Get,
      params: {
        tag: { key: 'sys.self_exclusion' },
      },
    });
    updateUserData({
      selfExclusionTag: selfLocking?.tag?.value || '',
    });
  }
};

export default authSelfLock;
