import assign from 'lodash/assign';
import reduce from 'lodash/reduce';
import toPairs from 'lodash/toPairs';
import toUpper from 'lodash/toUpper';
import toLower from 'lodash/toLower';
import repeat from 'lodash/repeat';
import split from 'lodash/split';
import compact from 'lodash/compact';
import head from 'lodash/head';
import includes from 'lodash/includes';
import map from 'lodash/map';
import join from 'lodash/join';
import { detect } from 'detect-browser';
import padStart from 'lodash/padStart';
import trimStart from 'lodash/trimStart';
import isNaN from 'lodash/isNaN';
import startsWith from 'lodash/startsWith';

import services from '@features/core/services';

import { CommonEvents } from '@packages/events/appEvents';

import { ALLOWED_LANGUAGES, DEFAULT_LANGUAGE } from '@common/constants/config';
import {
  ICategory,
  IEvent,
  IFixDecimalsOptions,
  IMarket,
  IPagination,
  IPrediction,
} from '@common/interfaces';
import numeral from '@common/helpers/numeralHelper';
import { isWebView } from '@common/helpers/links';

export const IS_MOBILE_VIEW = 'is_mobile_view';
export const isLScreenWidth =
  window.innerWidth >= 1280 && window.innerWidth < 1366;
export const isXLScreenWidth =
  window.innerWidth >= 1366 && window.innerWidth < 1600;
export const isLsXLScreenWidth =
  window.innerWidth >= 1280 && window.innerWidth < 1600;
export const isXXLScreenWidth =
  window.innerWidth >= 1600 && window.innerWidth < 1920;

/**
 * roundDown
 * round numbers for betting slip calculations
 *
 * @param {number} number
 * @param {number} decimals
 * @returns {number} rounded
 */
export const roundDown = (number: number, decimals = 2): number => {
  // move decimal point $decimal places to the right
  let conversion = number * 10 ** decimals;
  // strip decimal part
  conversion = Math.floor(conversion);
  // put decimal point again and return it
  return conversion / 10 ** decimals;
};

/**
 * fixDecimals
 * DE: 1.234.567,89
 * EN: 1,234,567.89
 * fix decimals for betting slip
 *
 * @param {number | string} num
 * @param { IFixDecimalsOptions } options
 * @returns {string} fixed
 */
export const fixDecimals = (
  num?: number | string,
  options?: IFixDecimalsOptions,
): string => {
  const initialNumber = String(num) || '0.00';

  const repeatNumber = options?.precision === 0 ? 0 : options?.precision || 2;

  return numeral(parseFloat(initialNumber)).format(
    `0,0.${reduce(
      repeat('0', repeatNumber),
      (acc, e, index) => {
        if (options?.optional && index + 1 >= options?.optional) {
          // eslint-disable-next-line
          return `${acc}[${e}]`;
        }
        // eslint-disable-next-line
        return `${acc}${e}`;
      },
      '',
    )}`,
  );
};

const browser = detect();

/**
 * getDevice
 * returns device string
 *
 * @returns {string} device
 */
export const getDevice = (): string => {
  if (browser?.os === 'iOS') {
    return 'ios';
  }
  if (browser?.os === 'Android OS') {
    return 'android';
  }
  return 'browser';
};
export const isIOS = (): boolean => browser?.os === 'iOS';

export const isOldIOS = (): boolean => {
  if (!isIOS()) {
    return false;
  }
  return parseInt(browser?.version || '', 10) < 15;
};
export const isAndroid = (): boolean => browser?.os === 'Android OS';

export enum Browser {
  Chrome = 'chrome',
  Firefox = 'firefox',
  InternetExplorer = 'ie',
  Edge = 'edge',
  Safari = 'safari',
  IOSSafari = 'ios-safari',
  Unknown = '',
}

export const getBrowser = (): Browser => {
  switch (browser?.name || '') {
    case 'chrome':
    case 'crios':
    case 'ios-webview':
    case 'chromium-webview':
      return Browser.Chrome;
    case 'firefox':
      return Browser.Firefox;
    case 'ie':
      return Browser.InternetExplorer;
    case 'edge':
      return Browser.Edge;
    case 'safari':
      return Browser.Safari;
    case 'ios':
      return Browser.IOSSafari;
    default:
      return Browser.Unknown;
  }
};

/**
 *  extendObjectWithID
 *  adds id to object
 *
 * @param {string} key
 * @param {ICategory | IMarket | IPrediction | IEvent} value
 * @returns {ICategory | IMarket | IPrediction | IEvent} withId
 */
export const extendObjectWithID = (
  key: string,
  value: ICategory | IMarket | IPrediction | IEvent,
): ICategory | IMarket | IPrediction | IEvent => {
  return assign(value, { id: key });
};

/**
 * extendCollectionsWithIDs
 * extends collections with ids
 * example {1: {0: 1}} => { 1: { id: 1, 0:1} }
 *
 * @param {Record<string, ICategory | IMarket | IPrediction | IEvent>} item
 * @returns {Record<string, ICategory | IMarket | IPrediction | IEvent>} extended
 */
export const extendCollectionsWithIDs = (
  item: Record<string, ICategory | IMarket | IPrediction | IEvent>,
): Record<string, ICategory | IMarket | IPrediction | IEvent> => {
  return reduce(
    toPairs(item),
    (sum, value) =>
      assign(sum, {
        [value[0]]: extendObjectWithID(value[0], value[1]),
      }),
    {} as Record<string, ICategory | IMarket | IPrediction | IEvent>,
  );
};

/**
 * calculatePagination
 * calculate pagination for event list
 *
 * @param {IPagination} p
 * @returns {{prevOffset: number, nextOffset: *, currentPage: number, totalPages: number, hasPrevPage: boolean,
 *   hasNextPage: boolean}} pagination
 */
export const calculatePagination = (
  p: IPagination,
): {
  prevOffset: number;
  nextOffset: number;
  currentPage: number;
  totalPages: number;
  hasPrevPage: boolean;
  hasNextPage: boolean;
} => {
  const prevOffset = p.offset - p.rows > 0 ? p.offset - p.rows : 0;
  const nextOffset =
    p.offset + p.rows < p.registers ? p.offset + p.rows : p.offset;
  const currentPage = (p.offset + p.rows) / p.rows;
  const totalPages = Math.ceil(p.registers / p.rows);
  return {
    prevOffset,
    nextOffset,
    currentPage,
    totalPages,
    hasPrevPage: currentPage > 1,
    hasNextPage: currentPage < totalPages,
  };
};

export const getTestList = (): string | null => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get('testlist');
};

/**
 * capitalize
 * simple capitalize func
 *
 * @param {string} string
 * @returns {string} capitalized
 */
export const capitalize = (string: string): string => {
  return toUpper(string.charAt(0)) + string.slice(1);
};

/**
 * parseCoddedString
 * parse codded strings from back-end, used for content on home page, static pages, etc
 *
 * @param {string} string
 * @returns {string} parsed
 */
export const parseCoddedString = (string = ''): string => {
  try {
    return decodeURIComponent(escape(atob(string || '')));
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e);
  }
  return '';
};

/**
 * createExpiresStamp
 * create a tamp set to be passed in cookie modules as a time stamp
 *
 * @param {number} seconds
 * @returns {Date} date
 */
export const createExpiresStamp = (seconds: number): Date => {
  const now = new Date();
  let time = now.getTime();
  time += seconds * 1000;
  now.setTime(time);
  return now;
};

export const TWENTY_HOURS_IN_SEK = 20 * 60 * 60;
export const ONE_WEEK_IN_SEK = 7 * 24 * 60 * 60;

/**
 * isDateValid
 *
 * @param {string} year
 * @param {string} month
 * @param {string} day
 * @returns {boolean} isDateValid
 */
export const isDateValid = (
  year: string,
  month: string,
  day: string,
): boolean => {
  const year_n = Number(year);
  const month_n = Number(month) - 1;
  const day_n = Number(day);

  const date = new Date();
  date.setFullYear(year_n, month_n, day_n);

  return (
    date.getFullYear() === year_n &&
    date.getMonth() === month_n &&
    date.getDate() === day_n
  );
};

/**
 * isDesktopView
 * Detect mobile device by checking User-Agent header
 *
 * @returns {boolean} isDesktopView
 */
export const isDesktopView = (): boolean => {
  try {
    const urlParams: URLSearchParams = new URLSearchParams(
      window.location.search,
    );
    if (
      urlParams.get('cy') === 'true' ||
      includes(navigator.userAgent, 'Cypress') ||
      // eslint-disable-next-line no-underscore-dangle
      window.__STORYBOOK_CLIENT_API__
    ) {
      return (
        urlParams.get(IS_MOBILE_VIEW) === 'false' || window.innerWidth >= 1280
      );
    }
    return (
      urlParams.get(IS_MOBILE_VIEW) === 'false' || window.screen.width >= 1280
    );
    // eslint-disable-next-line sonarjs/no-ignored-exceptions
  } catch (error) {
    return window.screen.width >= 1280;
  }
};

/**
 * get application language from URL
 *
 * @returns {void}
 */
export const getLangFromUrl = (): string => {
  const urlParts: string[] = split(window.location.pathname, '/');
  const lang =
    head(compact(urlParts)) ||
    (services.config.get(DEFAULT_LANGUAGE) as string);
  if (includes(services.config.get(ALLOWED_LANGUAGES) as Array<string>, lang)) {
    return lang;
  }
  return services.config.get(DEFAULT_LANGUAGE) as string;
};

/**
 * isInStandaloneMode
 *
 * @returns {boolean} isInStandaloneMode
 */
export const isInStandaloneMode = (): boolean =>
  window.matchMedia('(display-mode: standalone)').matches ||
  includes(document.referrer, 'android-app://');

/**
 * copyToClipBoard
 *
 * @param {string} text
 * @returns {Promise<void>} copyToClipBoard
 */
export const copyToClipBoard = async (text: string): Promise<void> => {
  if (!isWebView(window.location.search)) {
    await window.navigator.clipboard.writeText(text);
  } else {
    services.events.emitEvent(CommonEvents.NATIVE_COPY_TO_CLIPBOARD, text);
  }
};

/**
 * isTabletView
 *
 * @returns {boolean} isTabletView
 */
export const isTabletView = (): boolean => {
  const userAgent = toLower(navigator.userAgent);
  return (
    (window.innerWidth >= 768 && window.innerWidth < 1280) ||
    /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(
      userAgent,
    )
  );
};

export const hash = async (inputString: string): Promise<string> => {
  try {
    const utf8 = new TextEncoder().encode(inputString);
    const hashBuffer = await crypto.subtle.digest('SHA-256', utf8);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    return join(
      map(hashArray, bytes => padStart(bytes.toString(16), 2, '0')),
      '',
    );
    // eslint-disable-next-line sonarjs/no-ignored-exceptions
  } catch (e) {
    return '';
  }
};

const hexToComponent = (hexValue: string, startIndex: number): number => {
  const hexComponent = hexValue.slice(startIndex, startIndex + 2);
  const value = parseInt(hexComponent, 16);
  return isNaN(value) ? 0 : value;
};

const isValidHex = (hexValue: string): boolean => {
  const hexRegex = /^([0-9A-Fa-f]{3}|[0-9A-Fa-f]{6}|[0-9A-Fa-f]{4}|[0-9A-Fa-f]{8})$/;
  return hexRegex.test(hexValue);
};

const expandShorthandHex = (hex: string): string => {
  if (hex.length < 6) {
    return map(hex, c => c + c).join('');
  }
  return hex;
};

export const hexToRgba = (hex: string, opacity?: number): string => {
  const hexValue = startsWith(hex, '#') ? trimStart(hex, '#') : hex;
  const defaultRgb = 'rgb(0 0 0)';

  const expandedHex = expandShorthandHex(hexValue);

  if (!isValidHex(expandedHex)) {
    return defaultRgb;
  }

  const r = hexToComponent(expandedHex, 0);
  const g = hexToComponent(expandedHex, 2);
  const b = hexToComponent(expandedHex, 4);
  let a = 1;

  if (expandedHex.length === 8) {
    a = hexToComponent(expandedHex, 6) / 255;
  }

  if (opacity !== undefined) {
    a = opacity;
  }

  const alpha = a < 1 ? ` / ${Math.round(a * 100)}%` : '';
  return `rgb(${r} ${g} ${b}${alpha})`;
};
