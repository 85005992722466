import React, { ReactElement } from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';

interface ConditionalRouteRouteProps extends RouteProps {
  component: React.ComponentType<object>;
  condition: boolean;
  redirectPath: string;
}

const ConditionalRoute: React.FC<ConditionalRouteRouteProps> = ({
  component: Component,
  condition,
  redirectPath,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props): ReactElement => {
      return condition ? (
        <Component {...props} />
      ) : (
        <Redirect to={redirectPath} />
      );
    }}
  />
);

export default ConditionalRoute;
