import styled from 'styled-components';

export const Root = styled.div`
  background-color: ${({ theme }) => theme.bodyBackground};
`;

export const TimeSwitcher = styled.button<{ timeFilter: boolean }>`
  display: grid;
  grid-auto-flow: row;
  justify-items: center;
  position: relative;
  align-items: center;
  align-self: stretch;
  justify-content: center;
  min-width: 47px;
  text-align: center;
  padding: ${({ theme }) => theme.space_15} 0 ${({ theme }) => theme.space_20};
  color: ${({ theme, timeFilter }) =>
    timeFilter ? theme.textActive : theme.accent};
  background: ${({ theme, timeFilter }) =>
    timeFilter ? theme.accent : theme.bodyBackground};
  transition: 0.3s;

  span {
    display: inline-block;
    margin-top: ${({ theme }) => theme.space_10};
  }

  svg.mi-chevron-down,
  svg.mi-chevron-up {
    position: absolute;
    bottom: ${({ theme }) => theme.space_5};
  }

  &::before {
    display: block;
    content: '';
    position: absolute;
    right: 0;
    top: ${({ theme }) => theme.space_10};
    bottom: ${({ theme }) => theme.space_10};
    height: calc(100% - 20px);
    width: ${({ theme }) => theme.space_1};
    background: ${({ theme }) => theme.textMain};
  }
`;
