import React from 'react';
import reduce from 'lodash/reduce';
import { useTranslation } from 'react-i18next';

import { TransactionItem } from '@features/transactions/components/transactionItem';

import { isDesktopView } from '@common/helpers/deviceUtil';
import getGroupedTransactions from '@common/helpers/transactions/getGroupedTransactions';
import { useTransactions } from '@common/providers/transactions/useTransactions';

import { ITransactionSortedList } from '../Transaction.types';
import * as S from '../Transaction.styled';

export const TransactionSortedList: React.FC<ITransactionSortedList> = ({
  isAccountSummary,
}) => {
  const { t } = useTranslation();
  const transactionsData = useTransactions(s => s.data);
  const transactionListItems = getGroupedTransactions(transactionsData, t);

  const renderItemCustom = (item): JSX.Element | null => {
    switch (item.type) {
      case 'group_title':
        return (
          <S.PendingTransactionTitle
            key={item.key}
            isPendingTitle={item.key === '000'}
            isAccountSummary={!!isAccountSummary}
          >
            {item.flatListItem}
          </S.PendingTransactionTitle>
        );
      case 'date':
        return (
          <S.SortedList key={item.key}>
            <S.SortedListTitle
              isLight={!isDesktopView()}
              isAccountSummary={isAccountSummary}
              text={item.flatListItem}
            />
          </S.SortedList>
        );
      case 'transaction':
        return (
          <TransactionItem
            key={item.flatListItem.transaction_id}
            transaction={item.flatListItem}
            isAccountSummary={isAccountSummary}
            isEven={item.key % 2 === 0}
          />
        );
      default:
        return null;
    }
  };

  return (
    <S.SortedList>
      {reduce(
        transactionListItems,
        (acc, el) => {
          const renderedEl = renderItemCustom(el);
          return renderedEl ? acc.concat(renderedEl) : acc;
        },
        [] as JSX.Element[],
      )}
    </S.SortedList>
  );
};

export { TransactionSortedList as Component };

export default TransactionSortedList;
