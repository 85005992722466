import { IEvent, ICategory, EventListTypes } from '@common/interfaces';
import { useEventsListState } from '@common/providers/events/eventList/useEventsList';

const useEventsModel = (
  id: string,
  listType: keyof typeof EventListTypes,
): {
  event: IEvent;
  category: ICategory;
} => {
  const categories = useEventsListState(
    s => s[listType]?.data?.categories || {},
  );
  const event = useEventsListState(s => s[listType]?.data?.events[id] || {});
  const category = (event && categories[event.category_id]) || {};

  return {
    event,
    category,
  };
};

export default useEventsModel;
