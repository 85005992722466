import styled from 'styled-components';

import { TextNormal } from '@features/core/styles/text.styled';

export const BetPackerWrapper = styled.div`
  display: grid;
  grid-template-columns: 40px 1fr 80px;
  justify-content: center;
  align-content: center;
  width: 100%;
  height: 45px;
  padding: ${({ theme }) => theme.space_10};
  color: ${({ theme }) => theme.textMain};
  background-color: ${({ theme }) => theme.bodyBackground};
`;

export const BetPackerIconWrapper = styled(TextNormal)<{ size: string }>`
  display: grid;
  justify-content: center;
  align-content: center;
  width: ${({ size }) => (size === 'normal' ? '30' : '25')}px;
  height: ${({ size }) => (size === 'normal' ? '30' : '25')}px;
  font-size: ${({ size, theme }) =>
    size === 'normal' ? theme.fontMD : theme.fontSM};
  font-weight: ${({ theme }) => theme.fontBold};
  border-radius: 50%;
  background-color: ${({ theme }) => theme.primaryAccentBackground};
`;

export const BetPackerLabel = styled.div`
  display: grid;
  grid-auto-rows: 1fr 1fr;
`;

export const BetPackerTitle = styled.p`
  margin: 0;
`;

export const BetPackerExplanation = styled.p`
  margin: 0;
  width: 75px;
  cursor: pointer;
  text-decoration: underline;
  color: ${({ theme }) => theme.accent};
`;

export const BetPackerToggleButton = styled.label`
  position: relative;
  display: inline-block;
  width: 60px;
  height: 28px;

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  span {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: ${({ theme }) => theme.tertiaryBackground};
    transition: 0.3s;
    border-radius: 30px;
  }

  span::before {
    position: absolute;
    content: '';
    height: 23px;
    width: 23px;
    left: 3px;
    bottom: 3px;
    background-color: ${({ theme }) => theme.primaryAccentBackground};
    border-radius: 50%;
    transition: 0.3s;
  }

  input:checked + span {
    background-color: ${({ theme }) => theme.accent};
  }

  input:checked + span::before {
    background-color: ${({ theme }) => theme.tertiaryBackground};
    transform: translateX(30px);
  }
`;

export const BetPackerPopupWrapper = styled.div`
  box-sizing: border-box;
  position: absolute;
  margin-top: ${({ theme }) => theme.space_40};
  right: ${({ theme }) => theme.space_20};
  left: ${({ theme }) => theme.space_20};
  display: grid;
  grid-auto-rows: 20px 1fr 1fr;
  width: 345px;
  height: 330px;
  padding: ${({ theme }) => theme.space_15};
  background-color: ${({ theme }) => theme.textMain};
  color: ${({ theme }) => theme.elementNuanceColor};
  z-index: 6;
`;

export const ExplanationText = styled.p`
  width: 300px;
  height: 80px;
  margin: 0 0 ${({ theme }) => theme.space_20};
`;

export const ExampleTableWrapper = styled.div`
  box-sizing: border-box;
  display: grid;
  grid-template-columns: auto auto;
  width: 100%;
  height: 140px;
  padding: ${({ theme }) => theme.space_10};
  background-color: ${({ theme }) => theme.tertiaryBackground};
  color: ${({ theme }) => theme.textMain};
  justify-content: space-between;
  border-left: ${({ theme }) => theme.borderSecondary};
`;

export const ExampleTitle = styled.p`
  display: grid;
  grid-auto-rows: 50px 25px 25px 25px;
  margin: 0;
`;

export const ExampleRow = styled.div``;

export const ExampleValue = styled.p`
  display: grid;
  grid-auto-rows: 15px 15px 115px;
  text-align: center;
  margin: 0;
`;

export const BetPackerExampleResult = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  justify-items: center;
  grid-gap: ${({ theme }) => theme.space_10};

  & span {
    color: ${({ theme }) => theme.accent};
  }
`;

export const BackgroundDarkening = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100vh;
  width: 100%;
  background-color: ${({ theme }) => theme.textTertiary};
  opacity: 0.5;
  z-index: 5;
`;

export const CloseIcon = styled.div`
  display: grid;
  justify-content: end;

  & svg {
    cursor: pointer;
  }
`;

export const PopupArrowUp = styled.div`
  position: absolute;
  left: ${({ theme }) => theme.space_50};
  bottom: 97%;
  width: 14px;
  height: 14px;
  transform: rotate(45deg);
  background-color: ${({ theme }) => theme.textMain};
`;

export const BetpackerRow = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;

  & span {
    padding: ${({ theme }) => theme.space_10} 0;
  }
`;

export const MarketLabel = styled.span<{ hasIndent: boolean }>`
  font-size: ${props => props.theme.fontMD};
  color: ${({ theme }) => theme.textMain};
  padding-bottom: ${({ theme }) => theme.space_5};
`;

export const SelectionLabel = styled.span`
  font-size: ${props => props.theme.fontMD};
  font-weight: ${({ theme }) => theme.fontBold};
  color: ${({ theme }) => theme.textMain};
  margin-right: ${({ theme }) => theme.space_5};
  margin-left: ${({ theme }) => theme.space_5};
`;
