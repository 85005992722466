import styled from 'styled-components';

export const EventWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: ${({ theme }) => theme.space_10} ${({ theme }) => theme.space_20}
    ${({ theme }) => theme.space_10} ${({ theme }) => theme.space_10};
`;

export const MainWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex: 1;
  padding: 0;
  color: ${({ theme }) => theme.textTertiary};
  margin: ${({ theme }) => theme.space_5} ${({ theme }) => theme.space_10}
    ${({ theme }) => theme.space_15} ${({ theme }) => theme.space_10};
  border-radius: 5px;
`;

export const ChildWrapper = styled.div`
  flex: 1;
`;

export const BetPackerIconWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 25px;
  align-items: center;
  color: ${({ theme }) => theme.textMain};
`;

export const Odds = styled.span`
  font-weight: ${({ theme }) => theme.fontBold};
  margin: 0 ${({ theme }) => theme.space_5};
  font-size: ${props => props.theme.fontMD};
  color: ${({ theme }) => theme.accent};
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: ${({ theme }) => theme.space_10};
`;

export const EventLabel = styled.div`
  display: grid;
  grid-auto-rows: auto auto;
  font-weight: ${({ theme }) => theme.fontBold};
`;
export const Label = styled.span`
  color: ${({ theme }) => theme.textMain};
`;

export const Scoreboard = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const BetPackerTotalViewWrapper = styled.div`
  background: ${props => props.theme.main};
`;
