import replace from 'lodash/replace';
import sortBy from 'lodash/sortBy';
import split from 'lodash/split';
import filter from 'lodash/filter';

import services from '@features/core/services';
import i18next from '@features/core/translation';

import {
  IEvent,
  IMarket,
  IMarketType,
  MarketType,
  IPrediction,
  IPredictionType,
  PredictionType,
  IBetSelection,
} from '@common/interfaces';
import {
  getType as getMarketType,
  getSpecialValue,
} from '@common/helpers/markets/marketModel';
import { IData, ISelection } from '@common/interfaces/prediction/IPrediction';
import { useEventsListState } from '@common/providers/events/eventList/useEventsList';
import { IBetslipEventData } from '@common/interfaces/events/IEventData';

const hasSortedPredictions = {
  [MarketType.CORRECT_SCORE]: MarketType.CORRECT_SCORE,
  [MarketType.CORRECT_3SET_SCORE]: MarketType.CORRECT_3SET_SCORE,
  [MarketType.CORRECT_5SET_SCORE]: MarketType.CORRECT_5SET_SCORE,
  [MarketType.CORRECT_7SET_SCORE]: MarketType.CORRECT_5SET_SCORE,
  [MarketType.TOTAL_GOALS_AGGREGATED]: MarketType.TOTAL_GOALS_AGGREGATED,
  [MarketType.HALFTIME_FULLTIME]: MarketType.HALFTIME_FULLTIME,
  [MarketType.HIGHEST_SCORING_HALF]: MarketType.HIGHEST_SCORING_HALF,
  [MarketType['3WAY_OVER_UNDER']]: MarketType['3WAY_OVER_UNDER'],
  [MarketType.WINNER]: MarketType.WINNER,
  [MarketType.WINNER_CHAMPIONSHIP]: MarketType.WINNER_CHAMPIONSHIP,
  [MarketType.WINNER_EVENT]: MarketType.WINNER_EVENT,
  [MarketType.TOP_2]: MarketType.TOP_2,
  [MarketType.TOP3]: MarketType.TOP3,
  [MarketType.TOP_3]: MarketType.TOP_3,
  [MarketType.TOP4]: MarketType.TOP4,
  [MarketType.TOP_4]: MarketType.TOP_4,
  [MarketType.GOALS_AWAY_TEAM_LIVE]: MarketType.GOALS_AWAY_TEAM_LIVE,
  [MarketType.GOALS_HOME_TEAM_LIVE]: MarketType.GOALS_HOME_TEAM_LIVE,
  [MarketType.GOALS_AWAY_TEAM]: MarketType.GOALS_AWAY_TEAM,
  [MarketType.GOALS_HOME_TEAM]: MarketType.GOALS_HOME_TEAM,
  [MarketType.EXACT_NUMBER_OF_GOALS]: MarketType.EXACT_NUMBER_OF_GOALS,
  [MarketType.EXACT_NUMBER_OF_GOALS_HT]: MarketType.EXACT_NUMBER_OF_GOALS_HT,
  [MarketType.CORRECT_SCORE_SETS]: MarketType.CORRECT_SCORE_SETS,
  [MarketType.CORRECT_SCORE_LEGS_SET]: MarketType.CORRECT_SCORE_LEGS_SET,
  [MarketType.GOALSCORER_HOME_TEAM]: MarketType.GOALSCORER_HOME_TEAM,
  [MarketType.GOALSCORER_AWAY_TEAM]: MarketType.GOALSCORER_AWAY_TEAM,
  [MarketType.GOALSCORER]: MarketType.GOALSCORER,
};

const predictionsShortedByLabel = {
  [MarketType.GOALS_AWAY_TEAM]: MarketType.GOALS_AWAY_TEAM,
  [MarketType.GOALS_HOME_TEAM]: MarketType.GOALS_HOME_TEAM,
  [MarketType.GOALS_AWAY_TEAM_LIVE]: MarketType.GOALS_AWAY_TEAM_LIVE,
  [MarketType.GOALS_HOME_TEAM_LIVE]: MarketType.GOALS_HOME_TEAM_LIVE,
  [MarketType.EXACT_NUMBER_OF_GOALS]: MarketType.EXACT_NUMBER_OF_GOALS,
  [MarketType.EXACT_NUMBER_OF_GOALS_HT]: MarketType.EXACT_NUMBER_OF_GOALS_HT,
  [MarketType.TOTAL_GOALS_AGGREGATED]: MarketType.TOTAL_GOALS_AGGREGATED,
  [MarketType.GOALSCORER_HOME_TEAM]: MarketType.GOALSCORER_HOME_TEAM,
  [MarketType.GOALSCORER_AWAY_TEAM]: MarketType.GOALSCORER_AWAY_TEAM,
};

const predictionsShortedByOdds = {
  [MarketType.WINNER]: MarketType.WINNER,
  [MarketType.WINNER_CHAMPIONSHIP]: MarketType.WINNER_CHAMPIONSHIP,
  [MarketType.WINNER_EVENT]: MarketType.WINNER_EVENT,
  [MarketType.TOP_2]: MarketType.TOP_2,
  [MarketType.TOP3]: MarketType.TOP3,
  [MarketType.TOP_3]: MarketType.TOP_3,
  [MarketType.TOP4]: MarketType.TOP4,
  [MarketType.TOP_4]: MarketType.TOP_4,
  [MarketType.GOALSCORER]: MarketType.GOALSCORER,
};

export const normalizeLabel = (label: string | undefined): string => {
  return label
    ? replace(
        replace(label, '(1)', `(${i18next.t('helpers.home_title')})`),
        '(2)',
        `(${i18next.t('helpers.away_title')})`,
      )
    : '';
};

/**
 * getShortLabel
 *
 * @param {IPrediction} prediction
 * @param {string} marketType
 * @returns {string} shortLabel
 */
export const getShortLabel = (
  prediction: IPrediction,
  marketType?: IMarketType,
): string => {
  const { type, label_short, label } = prediction;
  if (label_short === '1X') {
    return '1/X';
  }
  if (label_short === '12') {
    return '1/2';
  }
  if (label_short === 'X2') {
    return 'X/2';
  }
  if (services.domainLang === 'de') {
    if (label_short === '1H') {
      return '1';
    }
    if (label_short === 'Equal') {
      return 'X';
    }
    if (label_short === 'Keiner') {
      return 'X';
    }
    if (label_short === 'keiner') {
      return 'X';
    }
    if (label_short === 'None') {
      return 'X';
    }
    if (label_short === 'Keine Ecke') {
      return 'X';
    }
    if (label_short === '2H') {
      return '2';
    }
    if (label_short === 'Ja') {
      return 'J';
    }
    if (label_short === 'Nein') {
      return 'N';
    }
    if (!label_short) {
      return 'X';
    }
  } else {
    if (label_short === 'Yes') {
      return 'Y';
    }
    if (label_short === 'No') {
      return 'N';
    }
    if (label_short === 'None') {
      return 'X';
    }
    if (label_short === 'Equal') {
      return 'X';
    }
    if (label_short === 'keiner') {
      return 'X';
    }
    if (label_short === 'Keiner') {
      return 'X';
    }
    if (!label_short) {
      return 'X';
    }
  }

  if (marketType === MarketType.WINNING_METHOD) {
    return label;
  }

  if (type === MarketType.WINNER || type === MarketType.GOALSCORER) {
    return normalizeLabel(label_short);
  }
  return label_short;
};

/**
 * getOdds
 *
 * @param {IPrediction} prediction
 * @returns {number} odds
 */
export const getOdds = (prediction: IPrediction): number => {
  return parseFloat(prediction?.odds);
};

/**
 * getFormattedOdds
 *
 * @param {IPrediction} prediction
 * @returns {string} formattedOdds
 */
export const getFormattedOdds = (prediction: IPrediction): string => {
  if (parseFloat(prediction.odds) >= 100) {
    return parseFloat(prediction.odds).toFixed(0);
  }
  if (parseFloat(prediction.odds) >= 10) {
    return parseFloat(prediction.odds).toFixed(1);
  }
  return parseFloat(prediction.odds).toFixed(2);
};

/**
 * hasValidOdds
 *
 * @param {IPrediction} prediction
 * @returns {boolean} hasValidOdds
 */
export const hasValidOdds = (prediction: IPrediction): boolean => {
  return getOdds(prediction) > 1;
};

/**
 * getType
 *
 * @param {IPrediction} prediction
 * @returns {string} type
 */
export const getType = (prediction: IPrediction): IPredictionType =>
  prediction.type || prediction.predictionType || '';

/**
 * isEnabled
 *
 * @param {IPrediction} prediction
 * @returns {boolean} isEnabled
 */
export const isEnabled = (prediction: IPrediction): boolean => {
  return hasValidOdds(prediction);
};

/**
 *  @function
 *  Returns the result range of the prediction
 *  The X axis represents home+visitor (total) and the Y axis represents home-visitor (difference)
 *  It can be a range object (with the X0, X1, Y0 and Y1 properties) or an array of range objects
 *   If it returns an array, the total range is the UNION of all the range objects
 * @param {ISelection} selection
 * @returns {Record<string, number | string> | null} range
 */

export const getRange = (
  selection: ISelection,
):
  | Record<string, string | number>
  | Record<string, string | number>[]
  | null => {
  const { markets, predictions } = useEventsListState.getState().betslip.data;
  const prediction = predictions[selection.id];
  const market = markets[selection.marketId];

  if (!prediction || !market) {
    return null;
  }

  // Infinit.
  const INF = 1000000;
  const marketType = getMarketType(market);
  const specialValue = getSpecialValue(market);
  const predictionType = getType(prediction);
  let offset_x: number;

  if (marketType === MarketType['3WAY']) {
    if (predictionType === PredictionType.HOME) {
      return {
        X0: 1,
        X1: INF,
        Y0: 1,
        Y1: INF,
      };
    }
    if (predictionType === PredictionType.DRAW) {
      return {
        X0: 0,
        X1: INF,
        Y0: 0,
        Y1: 0,
      };
    }
    if (predictionType === PredictionType.VISITOR) {
      return {
        X0: 1,
        X1: INF,
        Y0: -INF,
        Y1: -1,
      };
    }
    return null;
  }
  if (marketType === MarketType['2WAY']) {
    if (predictionType === PredictionType.HOME) {
      return {
        X0: 1,
        X1: INF,
        Y0: 1,
        Y1: INF,
      };
    }
    if (predictionType === PredictionType.VISITOR) {
      return {
        X0: 1,
        X1: INF,
        Y0: -INF,
        Y1: -1,
      };
    }
    return null;
  }
  if (marketType === MarketType.HANDICAP) {
    const HCValues = split(specialValue, ':');
    const HCP = (parseInt(HCValues[0], 10) - parseInt(HCValues[1], 10)) * -1;
    if (predictionType === PredictionType.HOME) {
      return {
        X0: 0,
        X1: INF,
        Y0: HCP + 1,
        Y1: INF,
      };
    }
    if (predictionType === PredictionType.DRAW) {
      return {
        X0: 0,
        X1: INF,
        Y0: HCP,
        Y1: HCP,
      };
    }
    if (predictionType === PredictionType.VISITOR) {
      return {
        X0: 0,
        X1: INF,
        Y0: -INF,
        Y1: HCP - 1,
      };
    }
    return null;
  }
  if (marketType === MarketType.OVER_UNDER) {
    const offset = parseInt(specialValue, 10);
    if (predictionType === PredictionType.UNDER) {
      return {
        X0: 0,
        X1: offset,
        Y0: -offset,
        Y1: offset,
      };
    }
    if (predictionType === PredictionType.OVER) {
      return {
        X0: offset + 1,
        X1: INF,
        Y0: -INF,
        Y1: INF,
      };
    }
    return null;
  }
  if (marketType === MarketType.REST_OF_MATCH) {
    const ROMValues = split(specialValue, ':');
    const offset_y = parseInt(ROMValues[0], 10) - parseInt(ROMValues[1], 10);
    offset_x = parseInt(ROMValues[0], 10) + parseInt(ROMValues[1], 10);
    if (predictionType === PredictionType.HOME) {
      return {
        X0: offset_x + 1,
        X1: INF,
        Y0: offset_y + 1,
        Y1: INF,
      };
    }
    if (predictionType === PredictionType.DRAW) {
      return {
        X0: offset_x,
        X1: INF,
        Y0: offset_y,
        Y1: offset_y,
      };
    }
    if (predictionType === PredictionType.VISITOR) {
      return {
        X0: offset_x + 1,
        X1: INF,
        Y0: -INF,
        Y1: offset_y - 1,
      };
    }
    return null;
  }
  if (marketType === MarketType.XTH_GOAL) {
    offset_x = parseInt(market?.special_value as string, 10);
    if (predictionType === PredictionType.HOME) {
      return {
        X0: offset_x + 1,
        X1: INF,
        Y0: -INF,
        Y1: INF,
      };
    }
    if (predictionType === PredictionType.DRAW) {
      return {
        X0: offset_x,
        X1: offset_x,
        Y0: -INF,
        Y1: INF,
      };
    }
    if (predictionType === PredictionType.VISITOR) {
      return {
        X0: offset_x + 1,
        X1: INF,
        Y0: -INF,
        Y1: INF,
      };
    }
    return null;
  }
  if (marketType === MarketType.DOUBLE_CHANCE) {
    switch (predictionType) {
      case PredictionType.HOME_OR_DRAW:
        return {
          X0: 0,
          X1: INF,
          Y0: 0,
          Y1: INF,
        };
      case PredictionType.HOME_OR_VISITOR:
        return [
          {
            X0: 1,
            X1: INF,
            Y0: 1,
            Y1: INF,
          },
          {
            X0: 1,
            X1: INF,
            Y0: -INF,
            Y1: -1,
          },
        ];
      case PredictionType.DRAW_OR_VISITOR:
        return {
          X0: 0,
          X1: INF,
          Y0: -INF,
          Y1: 0,
        };
      default:
        return null;
    }
  } else {
    return {
      X0: -INF,
      X1: INF,
      Y0: -INF,
      Y1: INF,
    };
  }
};

/**
 * getOddsLine
 *
 * @param {IEvent} prediction
 * @returns {number} oddsLIne
 */
export const getOddsLine = (prediction: IEvent): number => {
  const oddsLine = parseFloat(String(prediction?.odds_line));
  return Number.isNaN(oddsLine) ? 0 : oddsLine;
};

/**
 * sortPredictions
 *
 * @param {string} type
 * @param {IPrediction} prediction
 * @returns {number} sortingOrder
 */
export const sortPredictions = (
  type: IMarketType,
  prediction: IPrediction,
): number => {
  if (prediction.type === PredictionType.SCORE) {
    const goals = split(prediction.label || '', ':');
    if (goals[0] > goals[1]) {
      return parseFloat((parseFloat(goals[1]) + 1) * 10 + goals[0]);
    }
    return parseFloat((parseFloat(goals[0]) + 1) * 10 + goals[1]);
  }
  if (type === MarketType['3WAY_OVER_UNDER']) {
    return ['1/+', 'X/+', '2/+', '1/-', 'X/-', '2/-'].indexOf(
      getShortLabel(prediction),
    );
  }
  if (type === MarketType.HALFTIME_FULLTIME) {
    return [
      '1/1',
      'X/1',
      '2/1',
      '1/X',
      'X/X',
      '2/X',
      '1/2',
      'X/2',
      '2/2',
    ].indexOf(getShortLabel(prediction));
  }
  if (predictionsShortedByOdds[type]) {
    return parseFloat(prediction.odds);
  }
  if (predictionsShortedByLabel[type] && prediction.label) {
    return parseFloat(prediction.label);
  }
  return -1;
};

/**
 * sortPredictionByType
 *
 * @param {IPrediction[]} predictions
 * @param {string} type
 * @returns {IPrediction[]} sortedPerdictions
 */
export const sortPredictionByType = (
  predictions: IPrediction[],
  type: IMarketType,
): IPrediction[] => {
  if (hasSortedPredictions[type]) {
    return sortBy(predictions, e => sortPredictions(type, e));
  }
  return predictions;
};

/**
 * getHomePrediction
 * returns home prediction
 *
 * @param {IMarket} market
 * @param {IPrediction[]} predictions
 * @returns {IPrediction | null} prediction
 */
export const getHomePrediction = (
  market: IMarket,
  predictions: IPrediction[],
): IPrediction | null => {
  if (market?.predictions?.length && predictions?.length) {
    const result = filter(predictions, ['id', market.predictions[0]]);
    return result ? result[0] : null;
  }
  return null;
};

/**
 * getDrawPrediction
 * returns draw prediction
 *
 * @param {IMarket} market
 * @param {IPrediction[]} predictions
 * @returns {IPrediction | null} prediction
 */
export const getDrawPrediction = (
  market: IMarket,
  predictions: IPrediction[],
): IPrediction | null => {
  if (market?.predictions?.length && predictions?.length) {
    const result = filter(predictions, ['id', market.predictions[2]]);
    return result ? result[0] : null;
  }
  return null;
};

/**
 * getAwayPrediction
 * returns away prediction
 *
 * @param {IMarket} market
 * @param {IPrediction[]} predictions
 * @returns {IPrediction | null} prediction
 */
export const getAwayPrediction = (
  market: IMarket,
  predictions: IPrediction[],
): IPrediction | null => {
  if (market?.predictions?.length && predictions?.length) {
    const result = filter(predictions, ['id', market.predictions[1]]);
    return result ? result[0] : null;
  }
  return null;
};

/**
 * Creates structured betslip event data from the provided data object.
 *
 * This function takes an object containing event, category, market, and prediction data,
 * and returns a structured object suitable for use in the betslip.
 *
 * @param {IData} data - The data object containing event, category, market, and prediction.
 * @returns {IBetslipEventData} - The structured betslip event data.
 */
export const createStructuredData = (data: IData): IBetslipEventData => {
  return {
    markets: {
      [data.market.id]: data.market,
    },
    events: {
      [data.event.id]: data.event,
    },
    categories: {
      [data.category.id]: data.category,
    },
    predictions: {
      [data.prediction.id]: {
        ...data.prediction,
        marketId: data.market.id,
        eventId: data.event.id,
        categoryId: data.category.id,
      },
    },
  };
};

/**
 * Extracts identifiers from the provided data object to create a new selection.
 *
 * This function takes an object containing event, category, market, and prediction data,
 * and returns an object containing the identifiers needed for a new selection.
 *
 * @param {IData} data - The data object containing event, category, market, and prediction.
 * @returns {ISelection} - The new selection containing identifiers.
 */
export const extractIdentifiers = (data: IData): ISelection => {
  return {
    id: data.prediction.id,
    marketId: data.market.id,
    eventId: data.event.id,
    categoryId: data.category.id,
  };
};

/**
 * Creates a selection object from an item containing IDs.
 *
 * @param {IBetSelection} item - The bet selection item containing IDs.
 * @returns {ISelection} - The new selection containing identifiers.
 */
export const createSelectionFromIds = (item: IBetSelection): ISelection => {
  return {
    id: item.prediction_id,
    eventId: item.event_id,
    marketId: item.market_id,
    categoryId: '', // If categoryId is not available, set it as empty string or handle accordingly
  };
};
