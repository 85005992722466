import services from '@features/core/services';
import { PageName } from '@features/core/routing';

import { UserEvents } from '@packages/events/appEvents';

import {
  HAS_CHECK_MISS_USER_DATA,
  HAS_PUSH_NOTIFICATION,
  HAS_YEARLY_DATA_CHECK,
} from '@common/constants/config';
import fetchPaymentAccount from '@common/api/payments/fetchPaymentAccount';
import { setNotifications } from '@common/helpers/appState/notifications';
import { setDialogs } from '@common/helpers/appState/dialogs';
import { authUserSuccess } from '@common/providers/user/useUserState';
import { IUser } from '@common/interfaces/user/IUser';
import { setPaymentAccountSuccess } from '@common/providers/payments/usePayments';
import { getMessageCount } from '@common/providers/inbox/helper';
import {
  getPushNotificationStatus,
  getPushSubscriptions,
} from '@common/providers/pushNotifications/helper';
import { setLocation } from '@common/providers/router/helper';
import {
  hasFrozenAcccount,
  isYearlyFrozen,
} from '@common/helpers/paymentsHelper/paymentModel';
import { mustUpdateAccountData } from '@common/helpers/userHelper/userHelper';
import { authWallets } from '@common/providers/user/actions/authWallets';
import { authLugasTimer } from '@common/providers/user/actions/authLugasTimer';
import { authSelfLock } from '@common/providers/user/actions/authSelfLock';
import { authLimits } from '@common/providers/user/actions/authLimits';
import { isLoggedIn } from '@common/helpers/userHelper/loggedInState';
import mustCreateOnlineAccount from '@common/helpers/userHelper/mustCreateOnlineAccount';
import { ACTIVATE_ONLINE_ACCOUNT_REMOVE } from '@common/constants/cookie';

const checkRedirects = (user: IUser): void => {
  if (
    services.config.get(HAS_YEARLY_DATA_CHECK) &&
    user.personal_information_status === 'show_personal_information'
  ) {
    setLocation(PageName.YEARLY_DATA_CHECK);
  } else if (
    services.config.get(HAS_CHECK_MISS_USER_DATA) &&
    mustUpdateAccountData(user)
  ) {
    setLocation(PageName.UPDATE_ONLINE_ACCOUNT);
  } else if (hasFrozenAcccount(user)) {
    setLocation(
      isYearlyFrozen(user)
        ? PageName.USER_VERIFICATION_YEARLY
        : PageName.USER_VERIFICATION,
    );
  } else if (
    mustCreateOnlineAccount(user) &&
    !services.cookie.get(ACTIVATE_ONLINE_ACCOUNT_REMOVE)
  ) {
    setLocation(PageName.ACTIVATE_ONLINE_ACCOUNT);
  }
};

export const authOnlineUser = async (user: IUser): Promise<void> => {
  if (isLoggedIn()) {
    const paymentAccount = await fetchPaymentAccount();
    const wallets = await authWallets(user);
    setPaymentAccountSuccess(paymentAccount.account);

    getMessageCount();
    authUserSuccess(user);

    await authLimits(user, paymentAccount);
    await authLugasTimer();
    await authSelfLock();

    setNotifications(user);
    setDialogs({
      user,
      wallets,
    });
    checkRedirects(user);

    services.events.emitEvent(UserEvents.AUTH, user);
  }
  if (services.config.get(HAS_PUSH_NOTIFICATION)) {
    await getPushNotificationStatus({ username: user.username });

    await getPushSubscriptions({ username: user.username });
  }
};

export default authOnlineUser;
