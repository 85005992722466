import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { TextNormal } from '@features/core/styles/text.styled';

import { GLOBAL_DEPOSIT_LIMIT_INCREASED } from '@common/constants/notifications';
import { ButtonType } from '@common/interfaces';
import { isDesktopView } from '@common/helpers/deviceUtil';
import { isAccountPages } from '@common/helpers/links';
import { removeNotification } from '@common/providers/application/useAppState';

import { StyledButton } from '@ui/components/buttons';
import { Icon } from '@ui/components/icon';

import * as S from '../TopNotification.styled';

const isDesktop = isDesktopView();

const GlobalDepositLimitIncreased: React.FC = (): React.ReactElement => {
  const { t } = useTranslation();
  const isMyAccount = isAccountPages();
  const hasRightCol = isMyAccount && isDesktop;

  // todo temporary, mock data! This should be changed after implementing logic for showing this notification
  const depositValue = 2000;

  return (
    <S.NotificationWrapper hasRightCol={hasRightCol} id="verification">
      <S.NotificationHeader>
        <Icon width="20" height="20" name="info-circle" />
        <S.HighlightBoldTitle className="title">
          {t('user.notifications.global_deposit_limit_increased.title')}
        </S.HighlightBoldTitle>
      </S.NotificationHeader>
      <S.GridWrapper>
        <TextNormal>
          <Trans
            i18nKey="user.notifications.global_deposit_limit_increased.message"
            values={{
              amount: depositValue,
            }}
          >
            <strong />
          </Trans>
        </TextNormal>
        <StyledButton
          onClick={(): void => {
            removeNotification(GLOBAL_DEPOSIT_LIMIT_INCREASED);
          }}
          buttonType={ButtonType.Secondary}
          label="user.notifications.global_deposit_limit_increased.btn_label"
        />
      </S.GridWrapper>
    </S.NotificationWrapper>
  );
};

export default GlobalDepositLimitIncreased;
