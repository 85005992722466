import styled from 'styled-components';

import { isDesktopView } from '@common/helpers/deviceUtil';
import { isLoggedIn } from '@common/helpers/userHelper/loggedInState';

export const getHeaderHeight = (): number => {
  if (isDesktopView()) {
    return 55;
  }

  return isLoggedIn() ? 40 : 60;
};

export const Root = styled.div`
  height: ${(): number => getHeaderHeight()}px;
  background-color: ${(props): string =>
    props.theme.highlightsHeaderBackground};
  display: grid;
  align-content: center;
  grid-auto-flow: column;
  justify-content: space-between;
  max-width: 1920px;
  margin: 0 auto;
`;

export const LeftSection = styled.div`
  display: grid;
  align-items: center;
  justify-content: start;
  padding-left: ${({ theme }) => theme.space_5};
`;

export const CenterSection = styled.div`
  display: grid;
  align-items: center;
  justify-content: center;
`;

export const getSectionExtraStyles = (loggedIn: boolean): string => {
  if (loggedIn) {
    return `
      display: grid;
      align-items: center;
      justify-content: end;
    `;
  }

  return `
    top: 0;
    right: 0;
  `;
};

export const RightSection = styled.div<{ loggedIn: boolean }>`
  min-width: 65px;
  padding-right: ${({ theme, loggedIn }): string =>
    theme.isDesktop && loggedIn ? theme.space_20 : '0'};
  ${({ loggedIn }): string => getSectionExtraStyles(loggedIn)};
`;
