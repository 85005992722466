import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import KeyboardTarget from '@features/bettingslip/keyboardEnum';

import {
  STATE_FROZEN,
  STATE_QUEUED,
  STATE_OPENED,
} from '@common/providers/bettingslip/state';
import numeral, {
  FIXED_TWO_DECIMAL_FORMAT,
  FIXED_TWO_OPT_3RD_FORMAT,
  getFormattedAmount,
} from '@common/helpers/numeralHelper';
import { useBettingSlip } from '@common/providers/bettingslip/useBettingSlip';

import { VirtualInput } from '@ui/components/virtual';

import * as S from './VirtualContainer.styled';
import { VirtualContainerProps } from './VirtualContainer.types';

const VirtualContainer: React.FC<VirtualContainerProps> = props => {
  const {
    keyboardTarget,
    onSetKeyboardTarget,
    currentValue,
    onBlur,
    setShouldReplace,
  } = props;

  const state = useBettingSlip(s => s.state);
  const stake = useBettingSlip(s => s.stake);
  const walletType = useBettingSlip(s => s.walletType);
  const legsCount = useBettingSlip(s => s.legsCount);
  const type = useBettingSlip(s => s.type);
  const totalStake = useBettingSlip(s => s.totalStake);
  const { t } = useTranslation();

  useEffect(() => {
    onBlur(getFormattedAmount(totalStake), KeyboardTarget.MULTIPLE);
    onSetKeyboardTarget(KeyboardTarget.MULTIPLE);
  }, [legsCount, type, walletType]);

  return (
    <S.Container>
      <S.VirtualInputContainer isOneInput={legsCount <= 1}>
        {legsCount > 1 && (
          <>
            <S.Separator>
              <S.Header>
                <S.HeaderLabel>&nbsp;</S.HeaderLabel>
              </S.Header>
              <S.SeparatorLabel>{legsCount}x</S.SeparatorLabel>
            </S.Separator>
            <S.VirtualInputItem>
              <S.Header>
                <S.HeaderLabel data-qa="stake-per-bet-title">
                  {t('bettingslip.stakePerBet')}
                </S.HeaderLabel>
              </S.Header>
              <VirtualInput
                currentValue={currentValue}
                value={numeral(stake).format(FIXED_TWO_OPT_3RD_FORMAT)}
                numFormat={FIXED_TWO_OPT_3RD_FORMAT}
                onFocus={(e): void => {
                  onBlur(e, KeyboardTarget.SINGLE);
                  onSetKeyboardTarget(KeyboardTarget.SINGLE);
                  setShouldReplace(true);
                }}
                onBlur={(value): void => onBlur(value, KeyboardTarget.SINGLE)}
                active={keyboardTarget === KeyboardTarget.SINGLE}
                disabled={state === STATE_FROZEN || state === STATE_QUEUED}
                name={KeyboardTarget.SINGLE}
              />
            </S.VirtualInputItem>
            <S.Separator>
              <S.Header>
                <S.HeaderLabel>&nbsp;</S.HeaderLabel>
              </S.Header>
              <S.SeparatorLabel>=</S.SeparatorLabel>
            </S.Separator>
          </>
        )}
        <S.VirtualInputItem>
          <S.Header>
            <S.HeaderLabel data-qa="total-stake-title">
              {t('bettingslip.totalStake')}
            </S.HeaderLabel>
          </S.Header>
          <VirtualInput
            currentValue={currentValue}
            value={getFormattedAmount(totalStake)}
            numFormat={FIXED_TWO_DECIMAL_FORMAT}
            focused={state === STATE_OPENED}
            onFocus={(e): void => {
              onBlur(e, KeyboardTarget.MULTIPLE);
              onSetKeyboardTarget(KeyboardTarget.MULTIPLE);
              setShouldReplace(true);
            }}
            onBlur={(value): void => onBlur(value, KeyboardTarget.MULTIPLE)}
            active={keyboardTarget === KeyboardTarget.MULTIPLE}
            disabled={state === STATE_FROZEN || state === STATE_QUEUED}
            name={KeyboardTarget.MULTIPLE}
          />
        </S.VirtualInputItem>
      </S.VirtualInputContainer>
    </S.Container>
  );
};

export default VirtualContainer;
