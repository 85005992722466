import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  BallPossession,
  FactoryCards,
  FactoryScoreboards,
  ScoreboardTypes,
  FactoryTimers,
} from '@features/events/components/scoreboards';
import { SuspendReason } from '@features/events/components/susependReason';

import {
  getHomeLabel,
  getAwayLabel,
} from '@common/helpers/eventsHelper/eventLabelHelper';
import {
  STATE_FROZEN,
  STATE_QUEUED,
} from '@common/providers/bettingslip/state';
import { getTopID } from '@common/helpers/categories/categoriesModel';
import { isDesktopView } from '@common/helpers/deviceUtil';
import { EventListTypes, IBettingslipType } from '@common/interfaces';
import { isLiveCurrent } from '@common/helpers/eventsHelper/eventStatusHelper';
import { openEventDetail } from '@common/helpers/eventsHelper/eventRouteHelper';
import {
  addBankEvent,
  removeBankEvent,
  useBettingSlip,
} from '@common/providers/bettingslip/useBettingSlip';
import isEventSuspended from '@common/helpers/eventsHelper/isEventSuspended';
import { useEventsListState } from '@common/providers/events/eventList/useEventsList';

import * as S from './EventHeader.styled';
import { IEventHeader } from './EventHeader.types';

const isDesktop = isDesktopView();

const EventHeader: React.FC<IEventHeader> = props => {
  const { children, eventId, categoryId, type, activeBank } = props;
  const state = useBettingSlip(s => s.state);
  const { t } = useTranslation();
  const isDisabled = state === STATE_FROZEN || state === STATE_QUEUED;
  const event = useEventsListState(s => s.betslip.data.events[eventId]);
  const category = useEventsListState(
    s => s.betslip.data.categories?.[categoryId],
  );
  const isSuspended = isEventSuspended(event);

  if (!event || !category) {
    return null;
  }

  const toggleBank = (): void => {
    if (activeBank) {
      removeBankEvent(parseFloat(event.id));
    } else {
      addBankEvent(parseFloat(event.id));
    }
  };

  const handleSelectingEvent = (e): void => {
    e.preventDefault();
    if (isDesktop) {
      openEventDetail({
        eventId: event.id,
        categoryId: event.category_id,
        topCategoryId: getTopID(category),
      });
    } else {
      openEventDetail({
        eventId: event.id,
      });
    }
  };

  const renderButtonBank = (): React.ReactElement | boolean => {
    return (
      type === IBettingslipType.system && (
        <S.BankButtonContainer>
          <S.BankButton
            aria-label={t('bettingslip.bank')}
            isActiveBank={activeBank}
            onClick={toggleBank}
          >
            <S.BankButtonLabel>B</S.BankButtonLabel>
          </S.BankButton>
        </S.BankButtonContainer>
      )
    );
  };

  return (
    <S.Event
      data-event-id={event.id}
      data-category-id={event.category_id}
      data-top-category-id={getTopID(category)}
      data-live-status={isLiveCurrent(event) ? 'live' : 'prematch'}
    >
      <S.EventLabel isSuspended={isSuspended}>
        <S.Team data-qa="event-header-teams">
          {renderButtonBank()}
          <S.Labels data-qa="event-label">
            <S.StyledCustomLink
              onClick={(e): void => handleSelectingEvent(e)}
              disabled={isDisabled}
            >
              {category && (
                <BallPossession
                  id={event.id}
                  listType={EventListTypes.bettingslip}
                  team="home"
                  iconSize={10}
                  topCategoryId={category.top_category_id}
                />
              )}
              <FactoryCards
                event_id={event.id}
                listType={EventListTypes.bettingslip}
                isHome
              />
              <S.Label>{getHomeLabel(event.label || '')}</S.Label>
            </S.StyledCustomLink>
            <S.StyledCustomLink
              onClick={(e): void => handleSelectingEvent(e)}
              disabled={isDisabled}
            >
              {category && (
                <BallPossession
                  id={event.id}
                  listType={EventListTypes.bettingslip}
                  team="away"
                  iconSize={10}
                  topCategoryId={category.top_category_id}
                />
              )}
              <FactoryCards
                event_id={event.id}
                listType={EventListTypes.bettingslip}
                isHome={false}
              />
              <S.Label>{getAwayLabel(event.label || '')}</S.Label>
            </S.StyledCustomLink>
          </S.Labels>
        </S.Team>
        <S.StyledCustomLink
          onClick={(e): void => handleSelectingEvent(e)}
          disabled={isDisabled}
        >
          <S.Scoreboard data-qa="event-header-scoreboard">
            <FactoryTimers
              id={event.id}
              topCategoryId={category.top_category_id}
              listType={EventListTypes.bettingslip}
              shortTimer
            />
            <FactoryScoreboards
              id={event.id}
              event={event}
              topCategoryId={getTopID(category)}
              type={ScoreboardTypes.small}
              listType={EventListTypes.bettingslip}
            />
          </S.Scoreboard>
        </S.StyledCustomLink>
      </S.EventLabel>
      <S.Selections>
        <S.SuspendWrapper>
          <SuspendReason
            reasonType={EventListTypes.bettingslip}
            eventId={event.id}
          />
        </S.SuspendWrapper>

        {children}
      </S.Selections>
    </S.Event>
  );
};

export default EventHeader;
