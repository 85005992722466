/* eslint-disable import/prefer-default-export */
import CustomError from '@features/core/error/error';
import { DATE_TIME_TEMPLATE } from '@features/core/form/components/dateInput/DateTemplates';
import services from '@features/core/services';
import { PageName } from '@features/core/routing';

import {
  LIMIT_LAYER_CREDIT_CHECK,
  LIMIT_LAYER_INCREASE,
  LIMIT_LAYER_VERIFICATION,
  LIMIT_LOADER_LAYER,
} from '@common/constants/dialogs';
import {
  ICustomError,
  IFetchSetLimitResponse,
  IUser,
} from '@common/interfaces';
import fetchPocketTags from '@common/api/account/fetchPocketTags';
import { MethodType } from '@common/api/apiHandler';
import formatDate from '@common/helpers/timeHelper/localizedFormat';
import {
  closeSidebar,
  openSidebar,
} from '@common/providers/application/useAppState';
import { needVerification } from '@common/helpers/paymentsHelper/paymentModel';
import { useUserState } from '@common/providers/user/useUserState';
import { SHOULD_SET_LIMITS } from '@common/constants/cookie';
import { setLocation } from '@common/providers/router/helper';

export const setGlobalLimit = async (
  amount,
  prevValue,
  limitType,
  isSchufaAccept,
): Promise<(IFetchSetLimitResponse & ICustomError) | null> => {
  const response = await fetchPocketTags({
    method: MethodType.Get,
    params: { tag: { key: 'sys.income.status' } },
  });
  const wasLastSchufaCheckSuccessful =
    response?.tag?.status === 'granted' && response?.tag?.value === 'verified';

  if (
    amount &&
    +amount > 1000 &&
    !isSchufaAccept &&
    !wasLastSchufaCheckSuccessful &&
    !(response instanceof CustomError && response.code !== '404')
  ) {
    const user = useUserState.getState().data;
    const isNotVerified = needVerification(user as IUser);

    if (isNotVerified) {
      openSidebar({ type: LIMIT_LAYER_VERIFICATION });
    } else {
      openSidebar({
        type: LIMIT_LAYER_CREDIT_CHECK,
        data: { value: amount, prevValue, limitType },
      });
      return null;
    }
  }

  const data = await fetchPocketTags({
    method: MethodType.Post,
    params: {
      tag: { key: 'usr.deposit.global_limit.monthly', value: amount },
    },
  });

  const value = String(
    data?.tag?.status === 'rejected' ? prevValue : data?.tag?.value,
  );

  const activation = formatDate(
    data?.tag?.activation || '',
    DATE_TIME_TEMPLATE,
  );

  if (data?.tag?.change === 'increment' && !isSchufaAccept) {
    openSidebar({ type: LIMIT_LAYER_INCREASE, data: { value, activation } });
  }

  if (isSchufaAccept) {
    closeSidebar(LIMIT_LOADER_LAYER);
    if (data?.tag?.status !== 'rejected') {
      setLocation(PageName.SET_LIMITS);
      services.cookie.set(SHOULD_SET_LIMITS, SHOULD_SET_LIMITS);
    }
  }

  return {
    data: {
      data: {
        attribute: data?.tag?.key || 'usr.deposit.global_limit.monthly',
        value,
        status: data?.tag?.status || '',
        modified: data?.tag?.created || '',
        activation,
      },
    },
  } as IFetchSetLimitResponse & ICustomError;
};
