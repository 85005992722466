import styled from 'styled-components';

const SystemsContainer = styled.div<{
  systems: number;
  isSystemTab: boolean;
}>`
  background: ${(props): string => props.theme.main};

  @media screen and (max-height: 800px) {
    ${({ systems, theme, isSystemTab }): string =>
      systems > 5 && theme.isDesktop && isSystemTab
        ? 'overflow: auto; max-height: 200px;'
        : ''}
  }
`;

export default SystemsContainer;
