import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { can } from '@common/helpers/bettingSlipHelper/bettingSlipModel';
import { IBettingslipType } from '@common/interfaces';
import {
  STATE_FROZEN,
  STATE_QUEUED,
} from '@common/providers/bettingslip/state';
import {
  setBettingSlipType,
  useBettingSlip,
} from '@common/providers/bettingslip/useBettingSlip';

import * as S from './Tabs.styled';

const Tabs: React.FC = (): React.ReactElement => {
  const state = useBettingSlip(s => s.state);
  const bettingslip = useBettingSlip();

  const { t } = useTranslation();

  const handleSelect = (type: IBettingslipType): void => {
    if (state !== STATE_FROZEN) {
      setBettingSlipType(type);
    }
  };

  const canBet = useCallback(
    (type): boolean => {
      return (
        !can(bettingslip, type) ||
        state === STATE_FROZEN ||
        state === STATE_QUEUED
      );
    },
    [state, bettingslip.selections],
  );

  const isActive = (type: IBettingslipType): boolean => {
    return bettingslip.type === type;
  };

  return (
    <S.Root>
      <S.TabsContainer>
        <S.Tab
          onClick={(): void => handleSelect(IBettingslipType.single)}
          isDisabled={canBet(IBettingslipType.single)}
          data-qa="bettingslip-tab-single"
          className={isActive(IBettingslipType.single) ? 'active' : ''}
        >
          <S.Label>{t('bettingslip.singles')}</S.Label>
        </S.Tab>
        <S.Tab
          onClick={(): void => handleSelect(IBettingslipType.combi)}
          isDisabled={canBet(IBettingslipType.combi)}
          data-qa="bettingslip-tab-combi"
          className={isActive(IBettingslipType.combi) ? 'active' : ''}
        >
          <S.Label>{t('bettingslip.kombi')}</S.Label>
        </S.Tab>
        <S.Tab
          onClick={(): void => handleSelect(IBettingslipType.system)}
          isDisabled={canBet('combi')}
          data-qa="bettingslip-tab-system"
          className={isActive(IBettingslipType.system) ? 'active' : ''}
        >
          <S.Label>{t('bettingslip.system')}</S.Label>
        </S.Tab>
      </S.TabsContainer>
    </S.Root>
  );
};

export default Tabs;
