import React from 'react';
import includes from 'lodash/includes';
import { useTranslation } from 'react-i18next';

import { CheckboxInput } from '@features/core/form/components/checkboxInput';

import {
  STATE_FROZEN,
  STATE_OPENED,
  STATE_QUEUED,
} from '@common/providers/bettingslip/state';
import {
  changeBettingSlipState,
  setBettingSlipError,
  setSize,
  useBettingSlip,
} from '@common/providers/bettingslip/useBettingSlip';
import { IBettingslipStates } from '@common/interfaces';

import * as S from './System.styled';
import { SystemProps } from './System.types';

const System: React.FC<SystemProps> = (props): React.ReactElement => {
  const {
    state,
    totalSize,
    value,
    banksCount,
    size,
    legsCount,
    total,
    selected,
  } = props;
  const id = `system-size-${size}`;
  const { t } = useTranslation();
  const error = useBettingSlip(s => s.error);

  const toggleSystem = (): void | boolean => {
    if (error?.data) {
      setBettingSlipError({});
      changeBettingSlipState(STATE_OPENED);
    }
    if (state === STATE_FROZEN || state === STATE_QUEUED) {
      return false;
    }
    if (includes(totalSize, value)) {
      totalSize.splice(totalSize.indexOf(value), 1);
      return setSize(totalSize);
    }
    totalSize.push(value);
    return setSize(totalSize);
  };

  const generateContent = (): React.ReactElement => {
    const text = `${t('bettingslip.system')} ${size}/${total}`;
    return (
      <>
        <S.LabelTextBold>{text}</S.LabelTextBold>
        <S.LabelText>
          {legsCount}{' '}
          {legsCount > 1 ? t('bettingslip.bets') : t('bettingslip.bet')}
        </S.LabelText>
      </>
    );
  };

  return (
    <S.Item>
      <CheckboxInput
        disabled={state !== IBettingslipStates.OPENED}
        onChange={toggleSystem}
        checked={selected}
        value={value}
        data-qa={`system-checkbox-${value}`}
        isBlueTheme
      />
      <S.Label htmlFor={id} onClick={toggleSystem}>
        <S.LabelIcon
          fill="currentColor"
          name="check"
          width="12px"
          height="10px"
        />
        <S.LabelBanksCount>
          {banksCount ? `${banksCount}B +` : null}
        </S.LabelBanksCount>
        {generateContent()}
      </S.Label>
    </S.Item>
  );
};

export default System;
