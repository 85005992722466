import { useState, useEffect } from 'react';

import { PageName } from '@features/core/routing';

import { isShouldChangePassword } from '@common/helpers/userHelper/userHelper';
import { useUserState } from '@common/providers/user/useUserState';
import { useRouterState } from '@common/providers/router/useRouterState';
import { setLocation } from '@common/providers/router/helper';
import isMainUserId from '@common/helpers/userHelper/isMainUserId';

export const useChangePassword = (
  withRedirect?: boolean,
): { shouldChangePassword: boolean; isMainUser: boolean } => {
  const user = useUserState(state => state.data);

  const [shouldChangePassword, setShouldChangePassword] = useState(false);
  const [isMainUser, setIsMainUser] = useState(false);
  const currentRoute = useRouterState(s => s.currentWebRoute?.route);

  useEffect(() => {
    const setShouldChangePasswordState = async (): Promise<void> => {
      const valueChangePassword = isShouldChangePassword(user);
      const valueMainUser = isMainUserId();
      setShouldChangePassword(valueChangePassword);
      setIsMainUser(valueMainUser);
    };
    if (user?.username) {
      setShouldChangePasswordState();
    } else {
      setShouldChangePassword(false);
    }
  }, [user?.username]);

  useEffect(() => {
    if (
      withRedirect &&
      !isMainUser &&
      currentRoute === PageName.USER_CHANGE_PASSWORD
    ) {
      setLocation(PageName.HOME);
    }
  }, [user?.username, isMainUser]);

  useEffect(() => {
    if (
      withRedirect &&
      currentRoute !== PageName.USER_CHANGE_PASSWORD &&
      shouldChangePassword
    ) {
      setLocation(PageName.USER_CHANGE_PASSWORD);
    }
  }, [shouldChangePassword, currentRoute]);

  return { shouldChangePassword, isMainUser };
};

export default useChangePassword;
