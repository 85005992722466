import React, { useMemo } from 'react';

import { colorSchema } from '@features/core/theming/contexTheme';

import {
  isCategoryFavorite,
  isEventFavorite,
} from '@common/helpers/favoritesHelper';
import { checkChildrenOfParent } from '@common/helpers/categories/categoriesModel';
import { useLiveCategories } from '@common/providers/events/liveCategories/useLiveCategories';
import {
  toggleFavorites,
  useFavoritesState,
} from '@common/providers/favorites/useFavoritesState';

import { Icon } from '@ui/components/icon';

import * as S from './FavoritesButton.styled';
import FavoritesButtonType, { IFavoritesButton } from './FavoritesButton.types';

const FavoritesButton = ({
  eventId,
  categoryId,
  parentId,
  favoritesType,
  disabledHandler = false,
  setIsActionModalOpen,
}: IFavoritesButton): JSX.Element => {
  const favoritesEvents = useFavoritesState(s => s.favoritesEvents);
  const favoritesCategories = useFavoritesState(s => s.favoritesCategories);
  const isOnLivePg = favoritesType === FavoritesButtonType.live;
  const isFavoriteEvent = isEventFavorite(eventId, favoritesEvents);
  const isFavoriteCategory = isCategoryFavorite(
    categoryId,
    favoritesCategories,
  );
  const isParentFavorite = isCategoryFavorite(parentId, favoritesCategories);
  const catChildrenExist = useMemo(() => {
    const idParentMap = useLiveCategories.getState().flatSportCatTree;
    return checkChildrenOfParent(categoryId, favoritesCategories, idParentMap);
  }, [categoryId, favoritesCategories]);
  const isFavorite = isFavoriteEvent || isFavoriteCategory;
  /* eslint-disable no-nested-ternary,sonarjs/no-nested-conditional */
  const iconName = catChildrenExist.length
    ? 'star-outlined'
    : isFavorite
    ? 'star-full'
    : 'star-outlined';
  /* eslint-enable no-nested-ternary,sonarjs/no-nested-conditional */
  const favoriteFill = isFavorite ? colorSchema.accent : colorSchema.textMain;
  const iconFill = catChildrenExist.length ? colorSchema.accent : favoriteFill;

  // if isFavorite so the action is delete
  const actionCondition = isFavorite ? isFavoriteEvent : isOnLivePg;
  const isDisabled = (isFavoriteCategory && isOnLivePg) || isParentFavorite;

  const handleFavorites = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    id: string,
  ): void => {
    e.stopPropagation();
    toggleFavorites({
      id,
      type: actionCondition ? 'event' : 'category',
    });
    if (setIsActionModalOpen) {
      setIsActionModalOpen(false);
    }
  };
  return (
    <S.FavoritesButton
      favoritesType={favoritesType}
      onClick={(e): void => {
        if (!disabledHandler && !isDisabled) {
          const id = actionCondition ? eventId : categoryId;
          handleFavorites(e, id as string);
        } else if (isDisabled) {
          e.stopPropagation();
        }
      }}
    >
      <Icon name={iconName} fill={iconFill} width="18" height="18" />
    </S.FavoritesButton>
  );
};

export default FavoritesButton;
