import React from 'react';
import { useTranslation } from 'react-i18next';

import { PageName } from '@features/core/routing/linkAliases';
import services from '@features/core/services';

import { ButtonType } from '@common/interfaces';
import { setLocation } from '@common/providers/router/helper';
import { useIsLoggedIn } from '@common/helpers/userHelper/loggedInState';

import { StyledButton } from '@ui/components/buttons';

import * as S from './RegistrationMessage.styled';

const RegistrationMessage: React.FC = () => {
  const { t } = useTranslation();
  const isDesktop = services.isDesktopView;
  const isLoggedIn = useIsLoggedIn();

  if (isLoggedIn) {
    return null;
  }
  return (
    <S.Root>
      <S.RegisterContainer>
        <S.RegisterText>
          {isDesktop ? (
            <div>
              <S.BoldText>{t('home_page.registration.title1')}</S.BoldText>
              {t('home_page.registration.title2')}
            </div>
          ) : (
            <>
              {t('home_page.registration.title1_mobile')}
              <br />
              {t('home_page.registration.title2_mobile')}
            </>
          )}
        </S.RegisterText>
        <StyledButton
          onClick={(): void => setLocation(PageName.REGISTER)}
          dataQa="home-btn-registration"
          buttonType={isDesktop ? ButtonType.LightGhost : ButtonType.Primary}
          label="home_page.registration.button"
        />
      </S.RegisterContainer>
    </S.Root>
  );
};

export default RegistrationMessage;
