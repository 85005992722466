import styled, { DefaultTheme } from 'styled-components';

import { hexToRgba } from '@common/helpers/deviceUtil';

export const LiveChatContent = styled.div`
  display: grid;
  align-items: center;
  cursor: pointer;
`;

export const Block = styled.div`
  cursor: pointer;

  .live-chat-content {
    color: ${({ theme }) => theme.highlightWrapperBackground};
    padding: ${({ theme }) => theme.space_10};

    & svg {
      width: 32px;
      height: 32px;
      position: relative;
    }
  }

  &#live-chat-button-menu {
    width: 100%;
    margin: 0;
    padding: ${({ theme }) => theme.space_10};
  }
`;

const getLiveChatFixedView = (theme: DefaultTheme) => `
  position: fixed;
  z-index: 100;
  left: ${theme.space_50};
  bottom: ${theme.space_20};
  background-color: ${theme.highlightsHeaderBackground};
  height: 60px;
  width: 60px;
  border-radius: 50%;
  display: grid;
  align-items: center;
  justify-content: center;
  box-shadow: 0 5px 10px ${hexToRgba(theme.textTertiary, 0.75)};

  & > button {
    margin: 0;
  }
`;

export const LiveChatButtonWrapper = styled.div<{ isFixedView?: boolean }>`
  ${({ theme, isFixedView }) => isFixedView && getLiveChatFixedView(theme)}
`;
