import { UserLoadingState } from '@common/providers/user/actions/actionTypes';
import { useUserState } from '@common/providers/user/useUserState';
/**
 * isLoggedIn
 * checks if user is registered in shop;
 *
 * @returns {boolean} isLoggedIn
 */
export const isLoggedIn = (): boolean => {
  return useUserState.getState().loadingState > UserLoadingState.VERIFYING;
};

export const isFullyLoggedIn = (): boolean => {
  return useUserState.getState().loadingState === UserLoadingState.LOGGED_IN;
};

export const isLoginProccessing = (
  loadingState?: UserLoadingState,
): boolean => {
  const loadingSt = loadingState || useUserState.getState().loadingState;

  return (
    loadingSt !== UserLoadingState.LOGGED_OUT &&
    loadingSt !== UserLoadingState.LOGGED_IN
  );
};

export const useIsLoggedIn = (): boolean => {
  return useUserState(state => state.loadingState > UserLoadingState.VERIFYING);
};
