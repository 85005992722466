import React, { useEffect, useRef } from 'react';
import map from 'lodash/map';
import { useTheme } from 'styled-components';

import { isDesktopView } from '@common/helpers/deviceUtil';
import { elementScrollIntoView } from '@common/helpers/scrollHelper';
import { TTimeout } from '@common/interfaces';
import deleteTimeout from '@common/helpers/timeoutHelper';

import Icon from '@ui/components/icon/Icon';

import { IListType, ISwitcherItem, ISwitcherProps } from './Switcher.types';
import * as S from './Switcher.styled';

const Switcher: React.FC<ISwitcherProps> = ({
  items,
  activeItem,
  onSelectItem,
}) => {
  const isDesktop = isDesktopView();
  const { iconFillDark } = useTheme();
  const timeoutScrollRef = useRef<TTimeout>(null);
  const refActiveItem = useRef<HTMLDivElement>(null);

  const getListType = (): IListType => {
    switch (items.length) {
      case 1:
        return 'single';
      case 2:
        return 'dual';
      default:
        return isDesktop ? 'dual' : 'multiple';
    }
  };

  const isSingleItem = getListType() === 'single';
  const isActive = (item: ISwitcherItem): boolean =>
    item.affiliateID === activeItem && !isSingleItem;

  const renderIcon = (item): React.ReactElement => (
    <S.ItemIconWrap>
      <S.ItemIcon
        className="icon"
        name={item.icon.name}
        width={item.icon.width}
        height={item.icon.height}
      />
    </S.ItemIconWrap>
  );

  useEffect(() => {
    const currentActiveItem = refActiveItem?.current;

    if (currentActiveItem && getListType() === 'multiple') {
      timeoutScrollRef.current = setTimeout(
        elementScrollIntoView,
        400,
        currentActiveItem,
        {
          behavior: 'smooth',
          block: 'nearest',
          inline: 'nearest',
        },
      );
    }
  }, [activeItem]);

  useEffect(() => {
    return () => {
      deleteTimeout(timeoutScrollRef.current);
    };
  }, []);

  return (
    <S.List listType={getListType()}>
      {map(items, (item, index) => (
        <S.Item
          ref={isActive(item) ? refActiveItem : null}
          className="item"
          isActive={isActive(item)}
          onClick={(): void => onSelectItem(item)}
          key={index}
        >
          {item.icon && !isSingleItem && renderIcon(item)}

          <S.ItemContentWrap>
            <S.ItemContentTop className="content-top">
              {item.icon && isSingleItem && renderIcon(item)}

              <S.ItemTitle className="title">{item.title}</S.ItemTitle>
            </S.ItemContentTop>

            {item.description && (
              <S.ItemDescription className="description">
                {item.description}
              </S.ItemDescription>
            )}
          </S.ItemContentWrap>

          {isActive(item) && (
            <S.CheckCircle>
              <Icon name="check" width="10" height="10" fill={iconFillDark} />
            </S.CheckCircle>
          )}
        </S.Item>
      ))}
    </S.List>
  );
};

export default Switcher;
