import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import map from 'lodash/map';
import filter from 'lodash/filter';

import { CollapsedCategoriesList } from '@features/categories/collapsedCategoriesList';
import { NoFavoriteView } from '@features/categories/ui/noFavoriteView';
import services from '@features/core/services';
import { CollapsibleContainer } from '@features/categories/categoryCollapsibleContainer';
import CategoriesListTitle from '@features/categories/categoriesList/CategoriesListTitle';

import useNotification from '@common/hooks/useNotification';
import { HAS_CATEGORIES_FAVORITES } from '@common/constants/config';
import { useFavoritesState } from '@common/providers/favorites/useFavoritesState';
import { getCheckedCategories } from '@common/helpers/categories/categories';
import { ILiveSportCategoriesTreeData } from '@common/interfaces';
import {
  extendRequestCategories,
  filterCategoriesTree,
} from '@common/helpers/categories/categoriesModel';
import { isDesktopView } from '@common/helpers/deviceUtil';
import { isLongTerm as isLongTermCategory } from '@common/helpers/eventsHelper/eventStatusHelper';
import { getEventRoute } from '@common/helpers/eventsHelper/eventRouteHelper';
import checkItem from '@common/helpers/eventsHelper/eventCheckHelper';
import {
  getFavoritesData,
  isCategoryFavorite,
} from '@common/helpers/favoritesHelper';
import { scrollTopWindow } from '@common/helpers/scrollHelper';
import { useLiveCategories } from '@common/providers/events/liveCategories/useLiveCategories';
import { getCategoriesBySport } from '@common/helpers/categories/categoriesListHelper';

import * as S from './CategoriesList.styled';
import ICategoriesListProps from './CategoriesList.types';

const isDesktop = isDesktopView();

const CategoriesList: React.FC<ICategoriesListProps> = props => {
  const { isHighlights, isLongTerm, isFavorite, hasFavoritesInfoView } = props;
  const { t } = useTranslation();
  const favoritesCategories = useFavoritesState(s => s.favoritesCategories);
  const selectedSportCategory = useLiveCategories(
    s => s.selectedSportCategory[0],
  );
  const categoryWeight = useLiveCategories(
    s => s.highlightsMeta.categoryWeight,
  );
  const timeFilter = useLiveCategories(s => s.sportCategoriesTree.filter);
  const sportCategoriesTree = useLiveCategories(
    s => s.sportCategoriesTree.data,
  );

  // functionality for filtering categories
  const extendedCategories: string[] = extendRequestCategories([
    selectedSportCategory,
  ]);

  const highlightCategories: ILiveSportCategoriesTreeData[] = filterCategoriesTree(
    isDesktop
      ? sportCategoriesTree
      : filter(sportCategoriesTree, ['id', selectedSportCategory]),
    categoryWeight.sports,
  );

  const highlightLongTermCategories: ILiveSportCategoriesTreeData[] = filterCategoriesTree(
    isDesktop
      ? sportCategoriesTree
      : filter(sportCategoriesTree, ['id', selectedSportCategory]),
    categoryWeight.longterm,
  );

  const allCategories: ILiveSportCategoriesTreeData[] = getCategoriesBySport(
    extendedCategories,
    sportCategoriesTree,
    isDesktop,
    isLongTerm,
  );

  const regularCategories: ILiveSportCategoriesTreeData[] = filter(
    allCategories,
    item => !isLongTermCategory(item),
  );

  const longTermCategories: ILiveSportCategoriesTreeData[] = filter(
    allCategories,
    item => isLongTermCategory(item),
  );

  const getCategoriesToRender = (): ILiveSportCategoriesTreeData[] => {
    if (isHighlights && isLongTerm) {
      return highlightLongTermCategories;
    }
    if (isHighlights) {
      return highlightCategories;
    }
    if (isLongTerm) {
      return longTermCategories;
    }
    return regularCategories;
  };
  // functionality for handlers (check & select categories on desktop)
  const checkedCategories: string[] = getCheckedCategories();

  const selectItem = getEventRoute(timeFilter, sportCategoriesTree);

  const currentCategories = getCategoriesToRender();

  const filteredFavoriteCategories = useMemo(() => {
    return getFavoritesData(sportCategoriesTree, favoritesCategories);
  }, [
    favoritesCategories,
    regularCategories,
    highlightCategories,
    sportCategoriesTree,
  ]);

  const hasFavorite =
    services.config.get(HAS_CATEGORIES_FAVORITES) &&
    !!filteredFavoriteCategories?.length &&
    isFavorite;

  const renderCategories = (category: string): JSX.Element[] => {
    const filteredCategories = filter(currentCategories, item => {
      return !isCategoryFavorite(item.id, favoritesCategories);
    });

    const mergedCategories = {
      highlighted: filteredCategories,
      all: currentCategories,
      favorite: filteredFavoriteCategories,
    };

    return map(mergedCategories[category], item => {
      const handleClick = (clickedItem): void => {
        checkItem(
          clickedItem,
          checkedCategories,
          timeFilter,
          sportCategoriesTree,
        );
      };
      return (
        <S.Item
          key={item.id}
          isFavorite={
            !!favoritesCategories[item.id] &&
            !!(hasFavorite || hasFavoritesInfoView)
          }
        >
          <CollapsedCategoriesList
            item={item}
            filterData={timeFilter}
            onClick={handleClick}
            onSelect={selectItem}
            isHighlight={isHighlights || false}
          />
        </S.Item>
      );
    });
  };

  // side effects
  useEffect(() => {
    scrollTopWindow();
  }, [selectedSportCategory, timeFilter]);

  const isHighlightsEmpty = currentCategories.length === 0 && isHighlights;
  const isTopNotification = useNotification();

  return (
    <S.Root data-qa="categories-list" isTopNotification={!!isTopNotification}>
      {!isDesktop && (hasFavorite || hasFavoritesInfoView) && (
        <CategoriesListTitle
          isFavoriteSection
          isHighlights={isHighlights}
          hasFavorite={!!hasFavorite}
          isLongTerm={isLongTerm}
          currentCategories={currentCategories}
          timeFilter={timeFilter}
        />
      )}

      {(hasFavorite || hasFavoritesInfoView) &&
        (isDesktop ? (
          <CollapsibleContainer title={t('common.labels.favorites')}>
            {hasFavorite ? (
              renderCategories('favorite')
            ) : (
              <NoFavoriteView
                message={t('home_page.favorites.no_favorites_desktop_text')}
              />
            )}
          </CollapsibleContainer>
        ) : (
          <S.FavoritesMobileWrapper>
            {hasFavorite ? (
              renderCategories('favorite')
            ) : (
              <NoFavoriteView
                message={t('home_page.favorites.no_favorites_mobile_text')}
              />
            )}
          </S.FavoritesMobileWrapper>
        ))}

      {!isDesktop && !isHighlightsEmpty && (
        <CategoriesListTitle
          isFavoriteSection={false}
          isHighlights={isHighlights}
          hasFavorite={!!hasFavorite}
          isLongTerm={isLongTerm}
          currentCategories={currentCategories}
          timeFilter={timeFilter}
        />
      )}
      {isDesktop && isHighlights ? (
        <CollapsibleContainer title={t('common.labels.highlights')}>
          {renderCategories('highlighted')}
        </CollapsibleContainer>
      ) : (
        // eslint-disable-next-line sonarjs/no-nested-conditional
        renderCategories(isHighlights ? 'highlighted' : 'all')
      )}
      {currentCategories.length === 0 && !isHighlights && !isFavorite && (
        <S.EmptyList>
          <S.EmptyListTitle className="title">
            {t('categoriesPage.no_categories_title')}
          </S.EmptyListTitle>
          <S.EmptyListMessage
            message={t('categoriesPage.no_categories_message')}
          />
        </S.EmptyList>
      )}
    </S.Root>
  );
};

export default CategoriesList;
