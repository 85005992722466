import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Buttons } from '@features/bettingslip/components/buttons';
import { StakeForm } from '@features/bettingslip/components/stakeForm';
import { BettingSlipBottomNotification } from '@features/bettingslip/components/states';
import { SelfLock } from '@features/user/components/selfLock';
import services from '@features/core/services';
import ShopWalletNotification from '@features/bettingslip/components/shopWalletNotification/ShopWalletNotification';
import BetpackerTotalView from '@features/detail/components/betPacker/BetpackerTotalView';
import SelectionsList from '@features/bettingslip/components/bettingslip/SelectionsList';

import { HAS_LUGAS, HAS_OASIS } from '@common/constants/config';
import { elementScrollIntoView } from '@common/helpers/scrollHelper';
import deleteTimeout from '@common/helpers/timeoutHelper';
import { isDesktopView } from '@common/helpers/deviceUtil';
import {
  STATE_ACCEPTED,
  STATE_OPENED,
  STATE_QUEUED,
} from '@common/providers/bettingslip/state';
import { IBettingslipStates, TTimeout } from '@common/interfaces';
import { goBack } from '@common/providers/router/helper';
import { BetView } from '@common/providers/bettingslip/types';
import { hasSelections } from '@common/helpers/bettingSlipHelper/bettingSlipModel';
import { useUserState } from '@common/providers/user/useUserState';
import {
  resetBSpredictions,
  useBettingSlip,
} from '@common/providers/bettingslip/useBettingSlip';
import { isShopUser } from '@common/helpers/userHelper/userHelper';
import { useIsLoggedIn } from '@common/helpers/userHelper/loggedInState';

import { TabHeader } from '@ui/components/tabHeader';
import { Icon } from '@ui/components/icon';

import * as S from './Bettingslip.styled';

const isDesktop = isDesktopView();

const Bettingslip = (): React.ReactElement => {
  const { t } = useTranslation();
  const betPackerOdds = useBettingSlip(s => s.betPackerOdds);
  const bsMode = useBettingSlip(s => s.bsMode);
  const betPackerSelections = useBettingSlip(s => s.betPackerSelections);
  const selections = useBettingSlip(s => s.selections);
  const banks = useBettingSlip(s => s.banks);
  const bettingslipType = useBettingSlip(s => s.type);
  const bettingslipState = useBettingSlip(s => s.state);
  const user = useUserState(state => state.data);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const timeoutScrollRef = useRef<TTimeout>(null);
  const controlsContainerRef = useRef<HTMLDivElement>(null);
  const [bettingslipContainer, setBettingslipContainer] = useState(true);
  const isLoggedIn = useIsLoggedIn();
  const selectionsLoading = useBettingSlip(s => s.selectionsLoading);
  const bettingNotification = document.getElementById('betting-slip frozen');
  const [prevBettingslipState, setPrevBettingslipState] = useState<
    IBettingslipStates
  >(STATE_OPENED);
  const hasAllSelections = hasSelections(selections, betPackerSelections);
  useEffect(() => {
    if (isDesktop && bettingNotification) {
      elementScrollIntoView(bettingNotification, {
        behavior: 'smooth',
        block: 'end',
        inline: 'nearest',
      });
    }
  }, [bettingNotification, isDesktop]);

  const toggleBettingslipContainer = (): void => {
    setBettingslipContainer(prevState => !prevState);
  };

  useEffect(() => {
    if (bettingslipState === STATE_ACCEPTED) {
      if (isDesktop) {
        toggleBettingslipContainer();
        if (hasAllSelections) {
          setBettingslipContainer(true);
        }
      } else {
        goBack();
      }
    }

    if (!isDesktop && prevBettingslipState === STATE_QUEUED) {
      goBack();
    }

    setPrevBettingslipState(bettingslipState);
  }, [bettingslipState]);

  useEffect(() => {
    return () => {
      deleteTimeout(timeoutScrollRef.current);
    };
  }, []);

  useEffect(() => {
    if (!isDesktop && controlsContainerRef.current) {
      elementScrollIntoView(controlsContainerRef.current, {
        behavior: 'smooth',
        block: 'end',
      });
    }
  }, [hasAllSelections]);

  if (!hasAllSelections || selectionsLoading) {
    return <S.Container desktop={isDesktop} />;
  }

  return (
    <S.Container
      desktop={isDesktop}
      data-qa="bettingslip-wrapper"
      ref={wrapperRef}
      className={isDesktop ? '' : 'slider-bettingslip'}
    >
      <S.BettingslipContainer
        withoutSelections={false}
        loggedIn={isLoggedIn}
        innerHeight={window.innerHeight}
        desktop={isDesktop}
        isAccepted={bettingslipState === STATE_ACCEPTED}
        data-qa="bettingslip-container"
      >
        <S.SectionContainer data-qa="bettingslip-section-container">
          {!isDesktop && (
            <TabHeader
              title={t('footer.bettingSlip')}
              isBetting
              icon={<Icon name="close" width="18px" onClick={goBack} />}
              className="tab-header"
            />
          )}
          {!!isLoggedIn &&
            !isDesktop &&
            user?.countryCode === 'DE' &&
            services.config.get(HAS_OASIS) && <SelfLock />}
          {bsMode === BetView.BETPACKER ? (
            <BetpackerTotalView
              selectionGroup={betPackerSelections}
              betPackerOdds={betPackerOdds}
            />
          ) : (
            <>
              {bettingslipContainer && (
                <S.ItemsContainer>
                  <SelectionsList
                    selections={selections}
                    banks={banks}
                    bettingslipType={bettingslipType}
                  />
                  {hasAllSelections &&
                    bettingslipState !== STATE_QUEUED &&
                    bettingslipState !== STATE_ACCEPTED && (
                      <S.RemoveAllButtonContainer
                        onClick={resetBSpredictions}
                        data-qa="btn-remove-all-bets"
                      >
                        <S.RemoveAllItems>
                          {t('bettingslip.deleteAll')}
                        </S.RemoveAllItems>
                        <S.DeleteIcon
                          width="16"
                          height="16"
                          name="delete-icon"
                        />
                      </S.RemoveAllButtonContainer>
                    )}
                </S.ItemsContainer>
              )}
            </>
          )}
        </S.SectionContainer>
        <S.ControlsContainer ref={controlsContainerRef} desktop={isDesktop}>
          <form onSubmit={(e): void => e.preventDefault()}>
            <StakeForm />
            {isShopUser() && services.config.get(HAS_LUGAS) ? (
              <ShopWalletNotification />
            ) : (
              <Buttons />
            )}
          </form>
        </S.ControlsContainer>
      </S.BettingslipContainer>
      {isDesktop && <BettingSlipBottomNotification />}
    </S.Container>
  );
};

export default Bettingslip;
