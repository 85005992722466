import concat from 'lodash/concat';
import reject from 'lodash/reject';
import { create } from 'zustand';

import defaultState from '@common/providers/limits/state';
import {
  IGetLimitSuccess,
  ILimitError,
  ILimitType,
  ILimitsProviderState,
  ISetLimitSuccess,
} from '@common/providers/limits/types';
import { ICustomError, ILimitHistory } from '@common/interfaces';

export const useLimitsState = create<ILimitsProviderState>(() => defaultState);

export const setSelfExclusionLoading = (): void => {
  useLimitsState.setState(() => ({
    selfExclusionForm: {
      loading: true,
      success: false,
      error: null,
    },
  }));
};

export const setSelfExclusionSuccess = (): void => {
  useLimitsState.setState(() => ({
    selfExclusionForm: {
      loading: false,
      success: true,
      error: null,
    },
  }));
};

export const setSelfExclusionError = (error: ICustomError): void => {
  useLimitsState.setState(() => ({
    selfExclusionForm: {
      loading: false,
      success: false,
      error,
    },
  }));
};

export const resetSelfExclusion = (): void => {
  useLimitsState.setState(() => ({
    selfExclusionForm: defaultState.selfExclusionForm,
  }));
};

export const getLimitsHistory = (): void => {
  useLimitsState.setState(() => ({
    limitHistory: { loading: true, data: null, error: null },
  }));
};

export const getLimitsHistorySuccess = (data: ILimitHistory): void => {
  useLimitsState.setState(() => ({
    limitHistory: {
      loading: false,
      data,
      error: null,
    },
  }));
};

export const getLimitsHistoryError = (error: ICustomError): void => {
  useLimitsState.setState(() => ({
    limitHistory: {
      loading: false,
      data: null,
      error,
    },
  }));
};

export const resetLimits = (): void => {
  useLimitsState.setState(defaultState);
};

export const getLimitsSuccess = ({
  data,
  limitType,
}: IGetLimitSuccess): void => {
  useLimitsState.setState(state => ({
    [limitType]: {
      ...state[limitType],
      error: null,
      data,
      loaded: true,
    },
  }));
};

export const setLimit = (limitType: ILimitType): void => {
  useLimitsState.setState(state => ({
    [limitType]: {
      ...state[limitType],
      error: null,
      loaded: false,
    },
  }));
};

export const setActiveLimits = (activeLimitsField: string): void => {
  useLimitsState.setState(() => ({
    activeLimitsField,
  }));
};

export const setLimitSuccess = ({
  limitType,
  data,
}: ISetLimitSuccess): void => {
  useLimitsState.setState(state => ({
    [limitType]: {
      ...state[limitType],
      error: null,
      loaded: true,
      data: concat(
        reject(state[limitType].data, [
          'data.attribute',
          data[0]?.data?.attribute,
        ]),
        data,
      ),
    },
    activeLimitsField: '',
  }));
};

export const setLimitError = (error: ILimitError): void => {
  useLimitsState.setState(state => ({
    [error.data?.limitType as string]: {
      ...state[error.data?.limitType as string],
      error: { data: error },
      loaded: true,
    },
  }));
};

export const setAutoWithdrawLimit = (): void => {
  useLimitsState.setState(state => ({ ...state }));
};
