import map from 'lodash/map';
import assign from 'lodash/assign';
import toPairs from 'lodash/toPairs';
import reduce from 'lodash/reduce';
import keys from 'lodash/keys';
import forEach from 'lodash/forEach';
import _update from 'lodash/update';
import set from 'lodash/set';
import uniq from 'lodash/uniq';
import merge from 'lodash/merge';
import split from 'lodash/split';
import difference from 'lodash/difference';
import take from 'lodash/take';
import cloneDeep from 'lodash/cloneDeep';

import { PageName } from '@features/core/routing/linkAliases';

import {
  EventListSort,
  EventListTypes,
  EventTimeFilters,
  IEvent,
  IEventsState,
  IEventState,
  IEventUpdates,
  IMarket,
  IPrediction,
  IScore,
  ITimer,
  LiveStatus,
} from '@common/interfaces';
import {
  filterToTimestamp,
  isRunning,
} from '@common/helpers/eventsHelper/scoreboards/timerModel';
import { getOddsLine } from '@common/helpers/eventsHelper/predictionModel';
import { IEventData } from '@common/interfaces/events/IEventData';
import fetchEventsList from '@common/api/events/fetchEventsList';
import {
  removeEventFromList,
  setEventsList,
  useEventsListState,
} from '@common/providers/events/eventList/useEventsList';
import { useLiveCategories } from '@common/providers/events/liveCategories/useLiveCategories';
import { isMatchWebRoutes } from '@common/providers/router/helper';

import { parseEventList } from './eventsHelper/eventDataHelper';
import { handleUpdateReason } from './betstopReasonHelper';
import {
  extendRequestCategories,
  getTopID,
} from './categories/categoriesModel';

const EVENT_SINCE_PATH_SHORT = 'timer.runningSince';

export const getEventsForUpdates = (
  list: string,
  state?: IEventsState,
): string[] => {
  const eventsState = useEventsListState.getState();
  let events = {};
  if (list === 'home') {
    events =
      state?.combined_events.data.events ||
      eventsState.combined_events.data.events;
  } else if (list) {
    events = state?.[list].data.events || eventsState[list].data.events || {};
  }

  return reduce(
    events,
    (result, { id }) => {
      if (id) {
        result.push(id);
      }
      return result;
    },
    [],
  );
};

const syncEventList = async (): Promise<void> => {
  const eventsListState = useEventsListState.getState();
  const liveCategoriesSelected = useLiveCategories.getState()
    .selectedCategories;

  const categoryIds = liveCategoriesSelected[0]
    ? keys(eventsListState.live.data.categories)
    : liveCategoriesSelected;
  const categories = extendRequestCategories(categoryIds);
  const urlOffset = split(window.location.pathname, '/').pop() as string;
  const offset = parseInt(urlOffset, 10) ? parseInt(urlOffset, 10) : 0;
  const date_to = filterToTimestamp(EventTimeFilters.next24hrs);
  const params = {
    categories,
    listType: EventListTypes.live,
    live_status: [LiveStatus.open, LiveStatus.suspended, LiveStatus.future],
    offset,
    sort: EventListSort.liveHighlights,
    rows: 60,
    date_to,
  };

  const oldList = getEventsForUpdates(EventListTypes.live) as string[];
  const newList: IEventData = await fetchEventsList({
    ...params,
    sync: true,
  });
  const syncList = (newList?.sync as unknown) as string[];

  const shouldListReload = (): boolean => {
    return difference(take(syncList, 60), oldList).length > 0;
  };

  if (shouldListReload()) {
    const listUpdate: IEventData = await fetchEventsList({
      ...params,
      sync: false,
    });
    const parsedEventList = parseEventList(listUpdate);
    setEventsList({
      ...parsedEventList,
      listType: EventListTypes.live,
    });
  }
};

let timeout: ReturnType<typeof setTimeout> | null = null;

const addStartedEvents = (updates: IEventUpdates): void => {
  if (isMatchWebRoutes(PageName.LIVE)) {
    // eslint-disable-next-line lodash/prefer-filter
    forEach(updates, update => {
      if (update?.label === 'sEvent') {
        if (timeout) {
          clearTimeout(timeout);
        }

        timeout = setTimeout(() => {
          if (isMatchWebRoutes(PageName.LIVE)) {
            syncEventList();
          }
        }, 10000);
      }
    });
  }
};

const removeEndedEvents = (updates: IEventUpdates): void => {
  const ids = reduce(
    updates,
    (acc, el) => {
      if (el?.label === 'hEvent') {
        return acc.concat(el.data.eid);
      }
      return acc;
    },
    [] as string[],
  );
  if (ids.length) {
    const listsToDeleteFrom = [
      EventListTypes.combined_events,
      EventListTypes.live,
      // set bs event as finished ?
    ];
    const removeWithTimeout = (id: string | undefined): void => {
      if (id) {
        setTimeout(() => {
          forEach(listsToDeleteFrom, eventList =>
            removeEventFromList({ id, list: eventList }),
          );
        }, 15000);
      }
    };
    forEach(ids, id => removeWithTimeout(id));
  }
};

export const eventUpdates = (
  updates: IEventUpdates,
  state: IEventsState,
): IEventsState => {
  let parsedState = state;
  forEach(updates, update => {
    if (!update?.label) {
      return;
    }
    if (update.label === 'uOdds') {
      forEach(toPairs(parsedState), (entry: [string, IEventState]) => {
        const [eventKey, eventGroup] = entry;

        if (keys(eventGroup?.data?.events).length) {
          forEach(keys(update.data), predictionId => {
            const current =
              parsedState[eventKey]?.data?.predictions[predictionId];

            if (current) {
              const market =
                parsedState[eventKey]?.data?.markets[current.market_id] || {};

              const event =
                parsedState[eventKey]?.data?.events[market.event_id] || {};

              parsedState = _update(
                parsedState,
                `[${eventKey}].data.predictions.${predictionId}`,
                (prediction: IPrediction) => ({
                  ...prediction,
                  odds: update.data[predictionId][getOddsLine(event)],
                }),
              );
            }
          });
        }
      });
    } else if (update.label === 'sMarket') {
      forEach(toPairs(parsedState), (entry: [string, IEventState]) => {
        const [eventKey, eventGroup] = entry;
        if (
          keys(eventGroup?.data?.events).length &&
          update.data.eid &&
          parsedState[eventKey]?.data?.events[update.data.eid]
        ) {
          const updatePredictions = update.data.predictions;
          const updateMarket = {
            ...update.data,
            id: update.data.mid,
            predictions: map(updatePredictions, 'pid'),
          };
          if (parsedState[eventKey]?.data?.markets) {
            parsedState = _update(
              parsedState,
              `[${eventKey}].data.markets`,
              (markets: IMarket[]) =>
                set(markets, `[${update.data.mid}]`, updateMarket),
            );
            parsedState = _update(
              parsedState,
              `[${eventKey}].data.events.${update.data.eid}.markets`,
              markets => {
                if (markets) {
                  return uniq([...markets, update.data.mid]);
                }
                return [update.data.mid];
              },
            );
            const event = parsedState[eventKey]?.data?.events[update.data.eid];

            parsedState = _update(
              parsedState,
              `[${eventKey}].data.predictions`,
              predictions => {
                const updated = predictions || {};
                return merge(
                  { ...updated },
                  reduce(
                    updatePredictions,
                    (result, item) => ({
                      ...result,
                      [item.pid]: assign(item, {
                        id: item.pid,
                        odds: item.odds[getOddsLine(event)],
                      }),
                    }),
                    {},
                  ),
                );
              },
            );
          }
        }
      });
    } else if (update.label === 'uMarketStatus') {
      forEach(toPairs(parsedState), (entry: [string, IEventState]) => {
        const [eventKey, eventGroup] = entry;

        if (keys(eventGroup?.data?.events).length) {
          forEach(keys(update.data), marketId => {
            if (parsedState[eventKey]?.data?.markets[marketId]) {
              const clonedMarket = cloneDeep(
                parsedState[eventKey].data.markets[marketId],
              );
              clonedMarket.live_status = update.data[marketId];
              parsedState[eventKey].data.markets[marketId] = clonedMarket;
            }
          });
        }
      });
    } else if (update.label === 'uEventStatus') {
      forEach(toPairs(parsedState), (entry: [string, IEventState]) => {
        const [eventKey, eventGroup] = entry;

        if (
          keys(eventGroup?.data?.events).length &&
          update.data.eid &&
          parsedState[eventKey]?.data?.events[update.data.eid]
        ) {
          parsedState = _update(
            parsedState,
            `[${eventKey}].data.events.${update.data.eid}`,
            (event: IEvent) => {
              let updatedEvent: IEvent = {
                ...event,
                live_status: update.data.status,
              };
              if (
                update.data.status === LiveStatus.open &&
                event.suspend_reason
              ) {
                updatedEvent = {
                  ...updatedEvent,
                  suspend_reason: null,
                  suspended_timer: null,
                };
              }
              if (updatedEvent.timer && update.data.timer) {
                updatedEvent = _update(
                  { ...updatedEvent },
                  'timer',
                  (timer: ITimer) => merge({ ...timer }, update.data.timer),
                );
                if (updatedEvent.timer && isRunning(updatedEvent.timer)) {
                  updatedEvent = set(
                    updatedEvent,
                    EVENT_SINCE_PATH_SHORT,
                    new Date().getTime(),
                  );
                } else {
                  updatedEvent = set(
                    updatedEvent,
                    EVENT_SINCE_PATH_SHORT,
                    null,
                  );
                }
                return updatedEvent;
              }
              if (update.data.timer) {
                updatedEvent = set(updatedEvent, 'timer', update.data.timer);
                if (updatedEvent.timer && isRunning(updatedEvent.timer)) {
                  updatedEvent = set(
                    updatedEvent,
                    EVENT_SINCE_PATH_SHORT,
                    new Date().getTime(),
                  );
                }
                return updatedEvent;
              }
              return updatedEvent;
            },
          );
        }
      });
    } else if (update.label === 'uEventBetStop') {
      forEach(toPairs(parsedState), (entry: [string, IEventState]) => {
        const [eventKey, eventGroup] = entry;
        if (keys(eventGroup?.data?.events).length && update.data.eid) {
          const updateInstance = { ...update.data };
          if (parsedState[eventKey]?.data?.events[update.data.eid]) {
            parsedState = _update(
              parsedState,
              `[${eventKey}].data.events.${updateInstance.eid}`,
              (event: IEvent) => {
                const category =
                  parsedState[eventKey]?.data?.categories?.[event.category_id];
                return handleUpdateReason(
                  event,
                  update,
                  updateInstance,
                  getTopID(category),
                  eventKey,
                );
              },
            );
          }
        }
      });
    } else if (update.label === 'uCards') {
      forEach(toPairs(parsedState), (entry: [string, IEventState]) => {
        const [eventKey, eventGroup] = entry;

        if (
          keys(eventGroup?.data?.events).length &&
          update.data.eid &&
          parsedState[eventKey]?.data?.events[update.data.eid]
        ) {
          parsedState = _update(
            parsedState,
            `[${eventKey}].data.events.${update.data.eid}`,
            (event: IEvent) => merge({ ...event }, update.data),
          );
        }
      });
    } else if (update.label === 'uTimer') {
      forEach(toPairs(parsedState), (entry: [string, IEventState]) => {
        const [eventKey, eventGroup] = entry;

        if (
          keys(eventGroup?.data?.events).length &&
          update.data.eid &&
          parsedState[eventKey]?.data?.events[update.data.eid]
        ) {
          parsedState = _update(
            parsedState,
            `[${eventKey}].data.events[${update.data.eid}].timer`,
            (timer: ITimer) => {
              let updatedTimer: ITimer = cloneDeep(timer);
              if (updatedTimer) {
                updatedTimer = merge(updatedTimer, update.data);

                if (
                  timer.running !== updatedTimer?.running ||
                  timer.period_id !== updatedTimer?.period_id
                ) {
                  parsedState[eventKey].data.events = cloneDeep(
                    parsedState[eventKey].data.events,
                  );
                }
                if (isRunning(updatedTimer)) {
                  updatedTimer = set(
                    updatedTimer,
                    'runningSince',
                    new Date().getTime(),
                  );
                } else {
                  updatedTimer = set(updatedTimer, 'runningSince', null);
                }
                return updatedTimer;
              }
              //  updatedTimer = update.data;
              if (isRunning(updatedTimer)) {
                updatedTimer = set(
                  updatedTimer,
                  'runningSince',
                  new Date().getTime(),
                );
              }
              return updatedTimer;
            },
          );
        }
      });
    } else if (update.label === 'uScore') {
      forEach(toPairs(parsedState), (entry: [string, IEventState]) => {
        const [eventKey, eventGroup] = entry;
        if (
          keys(eventGroup?.data?.events).length &&
          update.data.eid &&
          parsedState[eventKey]?.data?.events[update.data.eid]
        ) {
          parsedState = _update(
            parsedState,
            `[${eventKey}].data.events.${update.data.eid}.score`,
            (score: IScore) => {
              if (score) {
                return set(
                  { ...merge({ ...score }, update.data) },
                  'runningSince',
                  new Date().getTime(),
                );
              }
              return update.data;
            },
          );
        }
      });
    } else if (update.label === 'tradingStatusChanged') {
      forEach(toPairs(parsedState), (entry: [string, IEventState]) => {
        const [eventKey, eventGroup] = entry;

        if (
          keys(eventGroup?.data?.events).length &&
          update.data.market_id &&
          parsedState[eventKey]?.data?.markets[update.data.market_id]
        ) {
          parsedState = _update(
            parsedState,
            `[${eventKey}].data.markets.${update.data.market_id}`,
            (market: IMarket) =>
              set({ ...market }, 'trading_status', update.data.status),
          );
        }
      });
    } else if (update.label === 'uSportRule') {
      forEach(toPairs(parsedState), (entry: [string, IEventState]) => {
        const [eventKey, eventGroup] = entry;

        if (
          keys(eventGroup?.data?.events).length &&
          update.data.eid &&
          parsedState[eventKey]?.data?.events[update.data.eid]
        ) {
          parsedState = _update(
            parsedState,
            `[${eventKey}].data.events.${update.data.eid}`,
            (event: IEvent) => {
              let currentEvent = { ...event };
              if (event?.timer && update.data.timer) {
                currentEvent = set(
                  { ...event },
                  'timer',
                  merge({ ...event?.timer }, update.data.timer),
                );
                if (currentEvent.timer && isRunning(currentEvent.timer)) {
                  currentEvent = set(
                    currentEvent,
                    EVENT_SINCE_PATH_SHORT,
                    new Date().getTime(),
                  );
                } else {
                  currentEvent = set(
                    currentEvent,
                    EVENT_SINCE_PATH_SHORT,
                    null,
                  );
                }
              } else if (update?.data?.timer) {
                currentEvent = set({ ...event }, 'timer', update.data.timer);
                if (currentEvent.timer && isRunning(currentEvent.timer)) {
                  currentEvent = set(
                    currentEvent,
                    EVENT_SINCE_PATH_SHORT,
                    new Date().getTime(),
                  );
                }
              }
              if (currentEvent?.score && update?.data?.score) {
                currentEvent = set(
                  currentEvent,
                  'score',
                  merge(currentEvent.score, update.data.score),
                );
              } else if (update?.data?.score) {
                currentEvent = set(currentEvent, 'score', update.data.score);
              }
              return currentEvent;
            },
          );
        }
      });
    }
  });
  addStartedEvents(updates);
  removeEndedEvents(updates);
  return { ...parsedState };
};

export const parseSocketUpdates = (payload): IEventUpdates => {
  let updates = [] as IEventUpdates;
  forEach(payload, data => {
    const { body } = data;
    updates = updates.concat(body);
  });
  return updates;
};
