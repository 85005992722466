/* eslint-disable lodash/prefer-lodash-method */
import map from 'lodash/map';

import services from '@features/core/services';
import CustomError from '@features/core/error/error';
import i18n from '@features/core/translation';

import { MarketingEvents } from '@packages/events/appEvents';

import {
  DATA_COOKIE,
  IS_LOGGED_IN,
  MAIN_USER_ID,
  QUERY_REG_DATA,
  SESSION_ID,
  STEP_COOKIE,
  TOKEN,
  USER_ID,
} from '@common/constants/cookie';
import {
  parseCoddedString,
  isDesktopView,
  createExpiresStamp,
  TWENTY_HOURS_IN_SEK,
} from '@common/helpers/deviceUtil';
import checkDuplicatedCustomer from '@common/api/registration/checkDuplicatedCustomer';
import { ICountry, IRegistrationData } from '@common/interfaces';
import getMinStepWithSubmitError from '@common/helpers/registrationHelper/getMinStepWithSubmitError';
import scrollToErrorField from '@common/helpers/registrationHelper/scrollToErrorField';
import {
  setRegisterError,
  setRegisterLoading,
} from '@common/providers/registration/useRegistration';
import { isNativeApp } from '@common/helpers/links';
import { sendRegCookiesToNative } from '@common/helpers/registrationHelper/sendRegCookiesToNative';
import { removeNumSeparators } from '@common/helpers/formsHelpers/registration/numSeparators';
import postRegistration from '@common/providers/registration/helper';

import { ISubmitErrors } from './registrationHelpers.types';

const isDesktop = isDesktopView();

export const SINGLE = 'single';
export const STEPPER = 'stepper';

export const getInitSingleFormDate = (): Partial<IRegistrationData & {
  birthday: string;
}> => {
  const initialFormData = parseCoddedString(
    services.cookie.get(QUERY_REG_DATA) || '',
  );
  try {
    return JSON.parse(initialFormData || '{}');
  } catch {
    return {};
  }
};

export const getRegData = (): Partial<IRegistrationData> => {
  try {
    return JSON.parse(services.cookie.get(DATA_COOKIE));
  } catch {
    return {};
  }
};

export const getPartnerId = (pathname: string): string => {
  const regex = /register-(\d+)$/;
  const match = pathname.match(regex);
  if (match) {
    return match[1];
  }
  return '';
};

export const formatedData = (data, affiliateData): IRegistrationData => {
  return {
    ...data,
    ...affiliateData,
    email2: data.email,
    preferred_language: services.domainLang,
    no_sec_answer: '1',
    no_bonus: data.no_bonus,
    phone: data.phone || '',
    region: data.country_code === 'DE' ? data.region : '',
    privacy_policy_accepted: data.privacy_policy_accepted === true ? '1' : '0',
    newsletter_agree: data.newsletter_agree === true ? '1' : '0',
    terms_agree: data.terms_agree === true ? '1' : '0',
    source_of_registration: isDesktop ? '1' : '2',
    title: data.title === '' ? 0 : data.title,
  };
};

export const setRegisterCookies = (result): void => {
  const { secret_token: token, session_id: session, user_id } = result;
  services.cookie.remove(STEP_COOKIE);
  services.cookie.remove(DATA_COOKIE);
  services.cookie.remove(QUERY_REG_DATA);

  const expires = createExpiresStamp(TWENTY_HOURS_IN_SEK);
  services.cookie.set(TOKEN, token, { expires });
  services.cookie.set(SESSION_ID, session, { expires });
  services.cookie.set(USER_ID, user_id, { expires });
  services.cookie.set(MAIN_USER_ID, user_id, { expires });
  services.cookie.set(IS_LOGGED_IN, 'is_logged_in', { expires });
};

export const checkDublicate = async (
  rest,
  bday_day,
  bday_month,
  bday_year,
): Promise<boolean> => {
  const checkDuplicatedResult = await checkDuplicatedCustomer({
    ...rest,
    birthday: { day: bday_day, month: bday_month, year: bday_year },
  });
  return (
    checkDuplicatedResult instanceof CustomError ||
    checkDuplicatedResult?.user_exists !== 0
  );
};

export const getErrors = (e, data, userExist?: boolean): ISubmitErrors => {
  scrollToErrorField(e);
  const error = e?.response?.data?.error;
  // eslint-disable-next-line sonarjs/no-dead-store
  let submitErrors = { submitErrors: [] } as ISubmitErrors;
  if (error?.failedFields?.length) {
    const newSubmitErrors = map(error.failedFields, (failedField, i) => ({
      message: error.userMessages[i] || 'Unknown error',
      fieldName: failedField,
      fieldValue: data[failedField],
    }));
    const newStep = getMinStepWithSubmitError(newSubmitErrors) || 4;
    submitErrors = { submitErrors: newSubmitErrors, step: newStep };
  } else if (error?.userMessages?.length) {
    const newSubmitErrors = map(error.userMessages, (failedField, i) => {
      return {
        message: error.userMessages[i],
      };
    });
    submitErrors = { submitErrors: newSubmitErrors };
  } else if (e?.errorFields?.length) {
    const newSubmitErrors = map(e.errorFields, (failedField, i) => ({
      message: failedField.errors[i],
    }));
    submitErrors = { submitErrors: newSubmitErrors };
  } else if (e?.response?.status === 500) {
    submitErrors = {
      submitErrors: [
        { message: i18n.t('registration.contact_customer_support') },
      ],
    };
  } else {
    submitErrors = {
      submitErrors: [
        {
          message: i18n.t('registration.contact_customer_support'),
        },
      ],
    };
  }
  setRegisterLoading(false);
  if (userExist) {
    submitErrors = { ...submitErrors, userExist };
  }
  return submitErrors;
};

export const regionDE = {
  BW: 'Baden-Württemberg',
  BY: 'Bayern',
  BE: 'Berlin',
  BB: 'Brandenburg',
  HB: 'Bremen',
  HH: 'Hamburg',
  HE: 'Hessen',
  MV: 'Mecklenburg-Vorpommern',
  NI: 'Niedersachsen',
  NW: 'Nordrhein-Westfalen',
  RP: 'Rheinland-Pfalz',
  SL: 'Saarland',
  SN: 'Sachsen',
  ST: 'Sachsen-Anhalt',
  SH: 'Schleswig-Holstein',
  TH: 'Thüringen',
};

export const getRadioValue = (radio: number, limit: string): string => {
  switch (radio) {
    case 2:
      return '1000.00';
    case 3:
      return parseFloat(limit || '0').toFixed(2);
    default:
      return '';
  }
};

export const handleStep = async ({
  step,
  stepData,
  search,
  form,
  setCurrentStep,
  setStepData,
}): Promise<void | null> => {
  try {
    await form.validateFields();
    const formData = {
      ...stepData,
      ...form.getFieldsValue(),
    };
    if (step < 4) {
      services.cookie.set(STEP_COOKIE, `${step + 1}`, { expires: null });
      services.cookie.set(DATA_COOKIE, JSON.stringify(formData), {
        expires: null,
      });
      if (isNativeApp(search)) {
        sendRegCookiesToNative();
      }
      setCurrentStep(step + 1);
      setStepData(formData);
    } else {
      const { radiobtn, deposit_monthly_limit } = form.getFieldsValue();
      const limitData = getRadioValue(
        radiobtn,
        removeNumSeparators(deposit_monthly_limit),
      );
      const submitData = {
        ...formData,
        deposit_monthly_limit: limitData,
        pep: '0',
      };
      delete formData.radiobtn;
      await postRegistration(submitData as IRegistrationData);
    }
    services.events.emitEvent(MarketingEvents.REGISTRATION_STEP, { step });
  } catch (e) {
    services.logger?.log(String(e));
  }
};

export const onStepChangeHandler = ({
  step,
  search,
  unknownErrors,
  setCurrentStep,
}): void => {
  services.cookie.set(STEP_COOKIE, String(step), {
    expires: null,
  });
  if (isNativeApp(search)) {
    sendRegCookiesToNative();
  }
  setCurrentStep(step);
  if (unknownErrors.length > 0) {
    setRegisterError({ submitErrors: [] });
  }
};

export const getPreselectedCountry = (countries: ICountry[]): string => {
  return countries.length === 1 && countries[0].value ? countries[0].value : '';
};
