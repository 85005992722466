import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useServices } from '@features/core/services';
import { AcceptTermsForm } from '@features/user/components/acceptTerms';
import { PageName, StaticSlugPageName } from '@features/core/routing';

import { TERMS_LAYER } from '@common/constants/dialogs';
import { scrollTo } from '@common/helpers/scrollHelper';
import { SidebarSide } from '@common/interfaces';
import { isDesktopView } from '@common/helpers/deviceUtil';
import {
  setStaticPageContent,
  useAppState,
} from '@common/providers/application/useAppState';
import { getStaticPageType } from '@common/helpers/staticPageHelper';
import { useIsLoggedIn } from '@common/helpers/userHelper/loggedInState';

import * as S from './AcceptTerms.styled';

const AcceptTerms = (): React.ReactElement => {
  const { t } = useTranslation();
  const isLoggedIn = useIsLoggedIn();
  const staticPageContent = useAppState(state => state.staticPageContent);
  const { domainLang } = useServices();
  const staticPageType = getStaticPageType(
    PageName.INFORMATION,
    StaticSlugPageName.TERMS,
  );
  const [showScrollButton, setShowScrollButton] = useState(true);
  const parentScrollRef = useRef<HTMLDivElement>(null);
  const element = parentScrollRef.current;

  const scrollButtonHandler = (): void => {
    if (element) {
      const maxScrollPosition = element.scrollHeight - element.clientHeight;

      scrollTo(element, {
        top: maxScrollPosition,
        behavior: 'smooth',
      });
    }
  };

  const handleScroll = useCallback(() => {
    if (element) {
      const maxScrollPosition = element.scrollHeight - element.clientHeight;
      const scrollHeight = element.scrollTop;

      if (scrollHeight < maxScrollPosition - 100) {
        setShowScrollButton(true);
      } else {
        setShowScrollButton(false);
      }
    }
  }, [element]);

  useEffect(() => {
    if (isLoggedIn) {
      setStaticPageContent({
        lang: domainLang,
        types: [staticPageType],
      });
    }

    element?.addEventListener('scroll', handleScroll);
  }, [domainLang, element, handleScroll]);

  useEffect(() => {
    return () => {
      element?.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <S.Root className="accept-terms" ref={parentScrollRef}>
      <S.Header className="header">
        <S.HeaderTitle>{t('user.terms.header_title')}</S.HeaderTitle>
        <S.HeaderContent>{t('user.terms.header_content')}</S.HeaderContent>
      </S.Header>

      <S.Terms>
        <S.TermsTitleBlock>
          <S.TermsTitle>{t('user.terms.terms-title')}</S.TermsTitle>
        </S.TermsTitleBlock>

        <div
          dangerouslySetInnerHTML={{
            __html: staticPageContent[staticPageType]?.body || ('' as string),
          }}
          role="presentation"
        />
      </S.Terms>

      <S.FormWrap>
        <AcceptTermsForm />
      </S.FormWrap>
      {showScrollButton && (
        <S.ScrollButton onClick={scrollButtonHandler}>
          <S.StyledIcon name="chevron-down" width="30" height="30" />
        </S.ScrollButton>
      )}
    </S.Root>
  );
};

AcceptTerms.meta = {
  side: isDesktopView() ? SidebarSide.center : SidebarSide.stretch,
  key: TERMS_LAYER,
  preventDarkening: false,
  fullScreen: true,
};

export default AcceptTerms;
