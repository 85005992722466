import styled from 'styled-components';

export const getFocusStyles = (theme, isError): string => {
  const color = isError ? theme.statusError : theme.inputSuccessBorderColor;
  return `
    box-shadow: 
        inset 0 0 0 2px ${color}, 
        0 0 15px ${color};
        transition: 0.3s ease;
        border-color: ${color};
  `;
};

export const Root = styled.div`
  position: relative;

  .errorIcon,
  .errorPasswordIcon,
  .leftIcon {
    position: absolute;
    right: ${({ theme }) => theme.space_10};
    top: 50%;
    transform: translateY(-50%);
    fill: ${props => props.theme.statusError};
  }

  .successPasswordIcon,
  .successIcon {
    position: absolute;
    right: ${({ theme }) => theme.space_10};
    top: 50%;
    transform: translateY(-50%);
  }

  .errorPasswordIcon,
  .successPasswordIcon {
    right: ${({ theme }) => theme.space_50};
  }

  .leftIcon {
    top: 50%;
    left: ${({ theme }) => theme.space_10};
    width: 20px;
    height: 20px;
    transform: translateY(-50%);
  }

  .boldInput {
    font-weight: ${({ theme }) => theme.fontBold};
  }

  .suffix_padding_1 {
    padding-right: ${({ theme }) => theme.space_20};
  }

  .suffix_padding_2 {
    padding-right: ${({ theme }) => theme.space_30};
  }

  .suffix_padding_3 {
    padding-right: ${({ theme }) => theme.space_40};
  }
`;

export const Sufix = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: ${({ theme }) => theme.space_10};
`;

const disabledInputBackground = bgColor => `background-color: ${bgColor};`;

export const StyledInput = styled.input<{
  isValidateError: boolean;
  hasFloatingLabel: boolean;
  errorIcon?: boolean;
  successIcon?: boolean;
  isError?: boolean;
  rightText?: boolean;
}>`
  height: 48px;
  width: 100%;
  padding: ${({ theme }) => theme.space_10};
  padding-top: ${({ theme, hasFloatingLabel }) =>
    hasFloatingLabel && theme.space_20};
  box-sizing: border-box;
  border-radius: 3px;
  border: solid 1px
    ${({ isError, theme }) =>
      isError ? theme.statusError : theme.inputDefaultText};
  background-color: ${({ theme }) => theme.highlightWrapperBackground};
  color: ${({ theme }) => theme.inputDefaultText};
  -webkit-text-fill-color: ${({ theme }) => theme.inputDefaultText};
  font-size: ${({ theme }) => theme.fontMD};
  outline: none;

  &:focus {
    ${({ theme, isValidateError }) => getFocusStyles(theme, isValidateError)}
  }

  &:focus + label,
  &:not(:placeholder-shown) + label,
  &:-webkit-autofill + label {
    transform: translate(0, -10px);
    font-size: ${({ theme }) => theme.fontMD};
    color: ${({ isError, theme }) =>
      isError ? theme.statusError : theme.inputDefaultText};
  }

  ${({ rightText }) => rightText && 'text-align: right;'};

  ${({ disabled, theme }) =>
    disabled && disabledInputBackground(theme.inputDisabledBackground)};
`;

export const FloatingLabel = styled.label<{
  rightText?: boolean;
  isError?: boolean;
}>`
  position: absolute;
  top: ${({ theme }) => theme.space_15};
  color: ${props => props.theme.inputDefaultText};
  opacity: ${({ isError }) => !isError && '0.65'};
  font-size: ${props => props.theme.fontMD};
  pointer-events: none;
  font-weight: ${({ theme }) => theme.fontRegular};
  transition: 0.3s ease;

  ${({ rightText }) => (rightText ? 'right: 10px;' : 'left: 10px;')}

  input[disabled] + & {
    color: ${props => props.theme.inputDisabledText};
    opacity: 1;
  }
`;
