import React from 'react';
import { useTranslation } from 'react-i18next';

import { PageName } from '@features/core/routing';

import { WALLET_SWITCHER } from '@common/constants/dialogs';
import { useUserState } from '@common/providers/user/useUserState';
import { openSidebar } from '@common/providers/application/useAppState';
import { setLocation } from '@common/providers/router/helper';
import { IUser } from '@common/interfaces';
import mustCreateOnlineAccount from '@common/helpers/userHelper/mustCreateOnlineAccount';

import { GenericNotification } from '@ui/components/genericNotification';
import {
  INotificationIcon,
  INotificationStyle,
} from '@ui/components/genericNotification/GenericNotification.types';
import { Button } from '@ui/components/buttons';
import { ButtonSize, ButtonType } from '@ui/components/buttons/Button.styled';

const ShopWalletNotification = (): React.ReactElement => {
  const { t } = useTranslation();
  const user = useUserState(state => state.data) as IUser;

  const onButtonClick = (): void => {
    if (mustCreateOnlineAccount(user)) {
      setLocation(PageName.CREATE_ONLINE_ACCOUNT);
    } else {
      openSidebar({ type: WALLET_SWITCHER });
    }
  };

  const closeButton = (
    <Button
      buttonType={ButtonType.Secondary}
      size={ButtonSize.Big}
      onClick={onButtonClick}
    >
      {mustCreateOnlineAccount(user)
        ? t('bettingslip.shop_notification.button_activate')
        : t('bettingslip.shop_notification.button_switch')}
    </Button>
  );

  return (
    <GenericNotification
      iconType={INotificationIcon.base}
      styleType={INotificationStyle.shopWalletNotification}
      title={t('bettingslip.shop_notification.title')}
      closeHandle={closeButton}
      additionalText={
        mustCreateOnlineAccount(user)
          ? t('bettingslip.shop_notification.text_activate')
          : t('bettingslip.shop_notification.text_switch')
      }
    />
  );
};

export default ShopWalletNotification;
