import { IUser } from '@common/interfaces';
import { ISelection } from '@common/interfaces/prediction/IPrediction';
import { ISetBettingSlipErrorPayload } from '@common/providers/bettingslip/types';

export enum IBettingslipStates {
  OPENED = 'opened',
  ACCEPTED = 'accepted',
  QUEUED = 'queued',
  REJECTED = 'rejected',
  FROZEN = 'frozen',
}

export enum IBettingslipType {
  single = 'single',
  combi = 'combi',
  system = 'system',
}

export enum IBetPackerType {
  'normal',
  'betPacker',
}

export enum IBSWalletType {
  REGULAR = 'REGULAR',
  BONUS = 'BONUS',
  SHOP = 'SHOP',
}
// rename to ISelections
export interface ISelections {
  [key: string]: ISelection;
}

export interface IBettingslip {
  type: IBettingslipType;
  state: IBettingslipStates;
  banks: number[];
  selections: ISelections;
  size: number[];
  totalStake: number;
  stake: number;
  user: IUser;
  loading?: boolean;
  selectionsLoading: boolean;
  error: ISetBettingSlipErrorPayload | null;
  bet_id?: string | null;
  queue_delay?: number;
  bsMode: keyof typeof IBetPackerType;
  betPackerSelections: ISelections;
  betPackerPredictions: string[];
  betPackerOdds: number | null;
  walletType?: keyof typeof IBSWalletType;
  legsCount: number;
  affiliateID: string;
}

export interface IQueuedBettingslip {
  lang: string;
  token: string;
  session: string;
  bet_id?: string;
  is_total_amount: number;
}

export interface ISubmittedBettingslip extends IQueuedBettingslip {
  [index: string]: string | number | string[] | number[] | undefined;
  selections: string[];
  legs_count: number;
  leg_size: number[];
  possible_profit: string;
  amount: string;
  bet_type: IBettingslipType;
  tax: string;
  source_of_bet: number;
  use_bonus?: number;
  use_shop_emoney?: string | 1 | 0;
}

export interface IBettingslipResponse {
  result: {
    is_accepted: number;
    is_queued: number;
    queue_delay?: string;
    is_rejected: number;
  };
}
