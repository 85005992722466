import React, { useRef } from 'react';
import map from 'lodash/map';

import services, { useServices } from '@features/core/services';

import { CommonEvents } from '@packages/events/appEvents';

import { OMIT_FACE_ID_CHECK } from '@common/constants/cookie';
import changeLanguage from '@common/helpers/changeLangHelper';

import * as S from './LanguagesList.styled';
import { ILanguagesListProps } from './LanguagesList.types';

const languages = [
  {
    id: 'de',
    title: 'Deutsch',
  },
  {
    id: 'en',
    title: 'English',
  },
];

const LanguagesList: React.FC<ILanguagesListProps> = ({ className }) => {
  const { domainLang } = useServices();
  const languageBlock = useRef<HTMLDivElement>(null);

  return (
    <S.Root className={className} data-qa="menu-language-nav">
      <S.LanguagesContainer ref={languageBlock}>
        {map(languages, language => (
          <S.LanguageItem
            key={language.id}
            onClick={(e): boolean => {
              services.events.emitEvent(CommonEvents.SET_NATIVE_COOKIE, {
                [OMIT_FACE_ID_CHECK]: true,
              });
              e.preventDefault();
              changeLanguage(language.id);
              return false;
            }}
            role="presentation"
            data-qa={`language-btn-${language.title}`}
          >
            <S.LanguageInput
              type="radio"
              name="language"
              active={language.id === domainLang}
            />
            {language.title}
          </S.LanguageItem>
        ))}
      </S.LanguagesContainer>
    </S.Root>
  );
};

export default LanguagesList;
