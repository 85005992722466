import { ICategory } from './ICategories';
import { IPagination } from './IPagination';

export enum PageType {
  LIVE = 'live',
  CATEGORIES = 'categories',
  SPORT = 'sport',
  HIGHLIGHTS = 'highlights',
  HOME = 'home',
  DETAIL = 'detail',
  EVENTS = 'events',
}

export enum EventListTypes {
  'live' = 'live',
  'detail' = 'detail',
  'combined_events' = 'combined_events',
  'favorites' = 'favorites',
  'cashout' = 'cashout',
  // TODO: leave 1
  'betslip' = 'betslip',
  'bettingslip' = 'betslip',
  'quotenmeister' = 'quotenmeister',
  'slider_events' = 'slider_events',
}

export enum EventListSort {
  'liveHighlights' = 'liveHighlights',
  'sportsStandard' = 'sportsStandard',
  'sportHighlights' = 'sportHighlights',
}

export interface IEventFromList {
  id: string;
  list: string;
}

export interface IWrappedEventListProps {
  sortedEventIds: string[];
  categories: Record<string, ICategory>;
  pagination?: IPagination;
  loading: boolean;
  openAppVersion: number;
}
