import styled from 'styled-components';

import { TextNormal } from '@features/core/styles/text.styled';

const opacityStyle = 'opacity: 0.5;';

export const SelectionContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  justify-content: space-between;
  align-items: center;
  min-height: 35px;
  justify-items: start;
  grid-template-columns: 1fr 25%;
`;

export const SelectionItem = styled.div`
  display: grid;
  grid-auto-flow: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  align-items: center;
`;

export const Description = styled.div<{ isSuspended: boolean }>`
  max-width: ${({ isSuspended }) => (isSuspended ? '160' : '220')}px;

  ${({ isSuspended }) => isSuspended && opacityStyle}
`;

export const Odd = styled.div<{ isBettingSlip: boolean }>`
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  height: 100%;
  justify-content: center;
  justify-self: start;
`;

export const IconWrapper = styled.div`
  background-color: ${({ theme }) => theme.highlightWrapperBackground};
  width: 24px;
  height: 25px;
  border-radius: 3px;
  display: grid;
  place-items: center;
`;

export const PredictionLabel = styled(TextNormal)<{ isLocked: boolean }>`
  display: inline;
  color: ${props => props.theme.textMain};
  font-weight: ${({ theme }) => theme.fontBold};

  ${({ isLocked }) => isLocked && opacityStyle}
`;
export const MarketLabel = styled(TextNormal)<{ isLocked: boolean }>`
  display: inline;
  color: ${props => props.theme.textMain};
  margin-right: ${({ theme }) => theme.space_5};

  ${({ isLocked }) => isLocked && opacityStyle}
`;
export const SpecialValue = styled.span``;

export const SuspendWrapper = styled.div<{ isActionBlockShown: boolean }>`
  ${({ isActionBlockShown }) => isActionBlockShown && 'margin-left: -30px;'};

  @media (min-width: 768px) {
    ${({ isActionBlockShown }) => isActionBlockShown && 'margin-left: -70px;'};
  }
`;

export const ActionsContainer = styled.div`
  text-align: center;
  justify-content: space-between;
  display: grid;
  grid-auto-flow: column;
  grid-gap: ${({ theme }) => theme.space_10};
  align-items: center;
  width: 100%;
  grid-auto-columns: 1fr;
  position: relative;
`;
export const RemoveButton = styled.button`
  height: 100%;
  border: 0;
  background: transparent;
  vertical-align: middle;
  padding-top: ${({ theme }) => theme.space_5};
  cursor: pointer;
  justify-self: end;
  padding-left: 0;
  padding-right: 0;
`;

export const OddSpan = styled.span`
  color: ${props => props.theme.accent};
  font-weight: ${({ theme }) => theme.fontBold};
`;

export const IconsWrapper = styled.div<{ up: boolean }>`
  position: absolute;
  right: ${({ theme }) => theme.space_30};
`;
export const OddsWrapper = styled.div`
  position: relative;
`;
