import React from 'react';
import map from 'lodash/map';

import { System } from '@features/bettingslip/components/system';

import { getPossibleSizes } from '@common/helpers/bettingSlipHelper/bettingSlipCalculationModel';
import { useBettingSlip } from '@common/providers/bettingslip/useBettingSlip';
import { IBettingslipType } from '@common/interfaces';

import SystemsContainer from './Systems.styled';

const Systems: React.FC = (): React.ReactElement => {
  const bettingslip = useBettingSlip();
  const systems = getPossibleSizes(bettingslip);
  const isSystemTab = bettingslip.type === IBettingslipType.system;

  if (!systems.length || !isSystemTab) {
    return <></>;
  }

  return (
    <SystemsContainer systems={systems.length} isSystemTab={isSystemTab}>
      {map(systems, (system, index) => (
        <System
          key={String(index)}
          banksCount={system.banksCount}
          size={system.size}
          legsCount={system.legsCount}
          total={system.total}
          state={bettingslip.state || ''}
          totalSize={bettingslip.size}
          value={system.value}
          selected={system.selected}
        />
      ))}
    </SystemsContainer>
  );
};

export default Systems;
