import services from '@features/core/services';

import { LUGAS_TIMER } from '@common/constants/cookie';
import hasLugasRestriction from '@common/helpers/userHelper/hasLugasRestrictions';
import { MethodType } from '@common/api/apiHandler';
import fetchPocketTags from '@common/api/account/fetchPocketTags';

export const authLugasTimer = async (): Promise<void> => {
  if (hasLugasRestriction() && !services.cookie.get(LUGAS_TIMER)) {
    const lugasActivityTimer = await fetchPocketTags({
      method: MethodType.Get,
      params: { tag: { key: 'usr.front.activitystart' } },
    });
    if (lugasActivityTimer?.tag?.value) {
      services.cookie.set(LUGAS_TIMER, lugasActivityTimer.tag.value, {
        expires: null,
      });
    }
  }
};

export default authLugasTimer;
