import { ICustomError } from '@common/interfaces';
import {
  authUserError,
  resetUserState,
} from '@common/providers/user/useUserState';
import {
  resetAuthCookies,
  resetUserSessionData,
} from '@common/providers/user/actions/logoutUser';

const authFailure = (e): boolean => {
  const customAxiosError = e as ICustomError;

  if (customAxiosError?.code === '600') {
    return false;
  }

  authUserError(customAxiosError);
  resetUserState();

  resetAuthCookies();
  resetUserSessionData();

  return true;
};

export default authFailure;
