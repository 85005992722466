import React from 'react';

import { StyledIcon } from '@pages/categoriesPanel/CategoriesPanel.styled';

import { getTimeSwitcherText } from '@common/helpers/categories/categoriesModel';
import { useLiveCategories } from '@common/providers/events/liveCategories/useLiveCategories';

import { Icon } from '@ui/components/icon';

import * as S from './TimeSwitcher.styled';
import { ITimeSwitcher } from './TimeSwitcher.types';

const TimeSwitcher: React.FC<ITimeSwitcher> = ({
  timeFilter,
  setTimeFilter,
}) => {
  const filter = useLiveCategories(s => s.sportCategoriesTree.filter);
  return (
    <S.Root>
      <S.TimeSwitcher
        onClick={(): void => setTimeFilter(!timeFilter)}
        timeFilter={timeFilter}
        data-qa="time-filter-switcher"
      >
        <StyledIcon name="clock" width="18" height="18" />

        <span>{getTimeSwitcherText(filter)}</span>

        <Icon
          name={timeFilter ? 'chevron-up' : 'chevron-down'}
          width="12"
          height="12"
        />
      </S.TimeSwitcher>
    </S.Root>
  );
};

export default TimeSwitcher;
