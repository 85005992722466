import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useThemeScheme } from '@features/core/theming/contexTheme';

import { getLiveStatus } from '@common/helpers/eventsHelper/eventStatusHelper';
import {
  hasValidOdds,
  getFormattedOdds,
} from '@common/helpers/eventsHelper/predictionModel';
import {
  getLabel as getMarketLabel,
  getSpecialValue,
  isMarketEnabled,
  getType,
  hasSpecialValueInLabel,
} from '@common/helpers/markets/marketModel';
import {
  STATE_FROZEN,
  STATE_QUEUED,
} from '@common/providers/bettingslip/state';
import {
  removeSelection,
  useBettingSlip,
} from '@common/providers/bettingslip/useBettingSlip';
import { IMarket, LiveStatus } from '@common/interfaces';
import useOddsDirection from '@common/hooks/useOddsDirection';
import { getSelectionLabel } from '@common/helpers/eventsHelper/selectionModel';
import {
  deleteSelectionData,
  useEventsListState,
} from '@common/providers/events/eventList/useEventsList';

import { Icon } from '@ui/components/icon';

import * as S from './Selection.styled';
import ISelectionProps from './Selection.types';

const Selection: React.FC<ISelectionProps> = ({
  selection,
}): React.ReactElement => {
  const { t } = useTranslation();
  const state = useBettingSlip(s => s.state);
  const event = useEventsListState(
    s => s.betslip.data.events[selection.eventId],
  );
  const prediction = useEventsListState(
    s => s.betslip.data.predictions[selection.id],
  );
  const market = useEventsListState(
    s => s.betslip.data.markets[selection.marketId],
  );

  const isSuspended = !!(
    event.suspend_reason && event.live_status === LiveStatus.suspended
  );
  const { up, down } = useOddsDirection(prediction);
  const { theme } = useThemeScheme();
  const isLocked = useMemo(() => {
    return (
      (market && !isMarketEnabled(market, getLiveStatus(event))) ||
      !hasValidOdds(prediction)
    );
  }, [market, event, prediction]);

  const marketlabel = getMarketLabel(market as IMarket);

  const handleClick = useCallback((): void => {
    if (state !== STATE_FROZEN && state !== STATE_QUEUED) {
      removeSelection(selection);
      deleteSelectionData(selection);
    }
  }, [state, selection]);

  const renderActionBlock = useCallback(
    (): React.ReactElement => (
      <S.Odd isBettingSlip>
        {isLocked ? (
          <S.IconWrapper>
            <Icon
              name="lock-outlined"
              width="15"
              height="15"
              fill={theme.statusError}
            />
          </S.IconWrapper>
        ) : (
          <S.OddsWrapper>
            <S.IconsWrapper up={up}>
              {down && (
                <Icon
                  width="7"
                  height="12.5"
                  name="down-arrow"
                  fill={theme.statusError}
                />
              )}
              {up && (
                <Icon
                  width="7"
                  height="12.5"
                  name="up-arrow"
                  fill={theme.statusWon}
                />
              )}
            </S.IconsWrapper>
            <S.OddSpan data-qa="betting-slip-odd">
              {getFormattedOdds(prediction)}
            </S.OddSpan>
          </S.OddsWrapper>
        )}
      </S.Odd>
    ),
    [isLocked, up, down, prediction.odds],
  );

  return (
    <S.SelectionContainer>
      <S.SelectionItem>
        <S.Description isSuspended={isSuspended}>
          <S.MarketLabel
            isLocked={isLocked}
            data-qa="betting-slip-market-label"
          >
            {`${marketlabel} - `}
            {!hasSpecialValueInLabel(getType(market as IMarket)) &&
              getSpecialValue(market as IMarket) && (
                <S.SpecialValue>
                  {getSpecialValue(market as IMarket)}
                </S.SpecialValue>
              )}
          </S.MarketLabel>
          <S.PredictionLabel
            isLocked={isLocked}
            data-qa="betting-slip-prediction-label"
          >
            {getSelectionLabel(prediction)}
          </S.PredictionLabel>
        </S.Description>
      </S.SelectionItem>
      <S.ActionsContainer>
        {!isSuspended && renderActionBlock()}

        <S.RemoveButton
          disabled={state === STATE_FROZEN}
          onClick={handleClick}
          aria-label={t('bettingslip.delete')}
          data-qa="btn-remove-bet"
        >
          <Icon
            name="delete-icon"
            width="16"
            height="16"
            fill={theme.textMain}
          />
        </S.RemoveButton>
      </S.ActionsContainer>
    </S.SelectionContainer>
  );
};

export default Selection;
