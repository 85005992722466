import { IThemeProps } from '@features/core/theming/styled';

const BASE_FONT_SIZE = '0.875rem';

const theme: IThemeProps = {
  // font size 14px
  baseFontSize: BASE_FONT_SIZE,
  // small info text
  fontXS: `0.625rem`, // 10px
  // info text
  fontSM: `0.75rem`, // 12px
  // body
  fontMD: `${BASE_FONT_SIZE}`, // 14px
  // buttons
  fontLG: `1rem`, // 16px
  // menu
  fontXL: `1.125rem`, // 18px
  // headline 3
  fontH3: `1.25rem`, // 20px
  // headline 2
  fontH2: `1.625rem`, // 26px
  // headline 1
  fontH1: `1.875rem`, // 30px

  // font weight
  fontRegular: 400,
  fontBold: 700,

  // spacing
  space_1: '1px',
  space_5: '5px',
  space_10: '10px',
  space_15: '15px',
  space_20: '20px',
  space_25: '25px',
  space_30: '30px',
  space_40: '40px',
  space_50: '50px',
  space_60: '60px',
  space_70: '70px',

  // z-index
  zIndexHeader: 100,
  zIndexSidebar: 101,
  zIndexFooter: 100,

  disabledOption: 0.5,

  extraFontsH0: '5rem',
  extraFontsH1: '4rem',
  extraFontsH2: '3rem',
  extraFontsH3: '2rem',
  extraFontsH4: '1.5rem',
  extraFontsXXS: '0.5rem',

  landingPageFontH2: '3.125rem', // 50px
  landingPageFontH3: '2.188rem', // 35px
};

export default theme;
