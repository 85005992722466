import styled from 'styled-components';

import { TextNormal } from '@features/core/styles/text.styled';

export const Root = styled.div`
  padding: ${({ theme }) => theme.space_10};
  margin: ${({ theme }) => theme.space_10} 0;
  background-color: ${({ theme }) => theme.textActive};
`;

export const RegisterContainer = styled.div`
  display: grid;
  justify-content: space-between;
  ${({ theme }) =>
    !theme.isDesktop ? 'grid-auto-flow: column;' : 'grid-auto-columns: 1fr;'}
`;

const getMobRegTextStyles = theme => `font-weight: ${theme.fontBold};`;

const getDeskRegTextStyles = theme => `margin-bottom: ${theme.space_20};`;

export const RegisterText = styled.div`
  font-size: ${({ theme }) => theme.fontMD};
  color: ${({ theme }) => theme.textMain};

  ${({ theme }) =>
    !theme.isDesktop
      ? getMobRegTextStyles(theme)
      : getDeskRegTextStyles(theme)};
`;

export const BoldText = styled(TextNormal)`
  margin-bottom: ${({ theme }) => theme.space_5};
  font-weight: ${({ theme }) => theme.fontBold};
`;
