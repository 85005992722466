import styled from 'styled-components';

import { footerHeight } from '@features/app/layout/footer';
import services from '@features/core/services';

import { IS_TERMINAL } from '@common/constants/config';

import { Icon } from '@ui/components/icon';

const isTerminal = services.config.get(IS_TERMINAL);

const terminalOverrides = `
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

const rootContainerMobileStyles = () => `
  width: 100%;
  height: 100%;
  overflow-y:auto;
  overflow-x:hidden;
  -webkit-overflow-scrolling: touch;
  position: relative;
  padding-bottom: 0;
`;

export const SectionContainer = styled.div``;
export const BetpackerRow = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;

  & span {
    padding: ${({ theme }) => theme.space_10} 0;
  }
`;

export const LabelWrapper = styled.span`
  display: flex;
  flex-direction: row;
`;

export const MarketLabel = styled.span<{ hasIndent: boolean }>`
  font-size: ${props => props.theme.fontMD};
  color: ${({ theme }) => theme.textMain};
  padding-bottom: ${({ theme }) => theme.space_5};
`;

export const SelectionLabel = styled.span`
  font-size: ${props => props.theme.fontMD};
  font-weight: ${({ theme }) => theme.fontBold};
  color: ${({ theme }) => theme.textMain};
  margin-right: ${({ theme }) => theme.space_5};
  margin-left: ${({ theme }) => theme.space_5};
`;

export const Container = styled.div<{
  desktop?: boolean;
}>`
  margin-top: ${!isTerminal && 'auto'};
  ${isTerminal && 'height: 100%;'}
  ${({ desktop }) => !desktop && rootContainerMobileStyles()};

  &:empty {
    height: auto;
  }
`;

const containerWithoutSelections = `
  background: transparent;
  height: 90px;
  overflow: hidden;
`;

const containerDesktopStyles = theme => `
  background: ${theme.textActive};
  max-height: none;
  overflow: initial;
  min-height: 40px;
  ${isTerminal && terminalOverrides}

  .tab-header {
    background: ${theme.primaryBackground};
    padding-left: ${theme.space_10};
  }
`;

const containerWithoutNotificationStyles = () => `
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between; 
`;

export const BettingslipContainer = styled.div<{
  withoutSelections: boolean;
  innerHeight?: number;
  loggedIn?: boolean;
  desktop?: boolean;
  isAccepted?: boolean;
}>`
  width: 100%;
  background: ${props => props.theme.secondary};
  position: relative;

  ${({ withoutSelections }) => withoutSelections && containerWithoutSelections}
  ${({ desktop, theme }) => desktop && containerDesktopStyles(theme)}
  ${({ desktop }) => !desktop && containerWithoutNotificationStyles()}
  ${({ isAccepted, desktop }) =>
    isAccepted && desktop && 'max-height: 80vh;overflow: auto;'}
`;

export const RemoveAllButtonContainer = styled.button`
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  border: ${({ theme }) => theme.border};
  cursor: pointer;
  background: transparent;
  padding: 0 ${({ theme }) => theme.space_15} ${({ theme }) => theme.space_20}
    ${({ theme }) => theme.space_10};
  margin: ${({ theme }) => theme.space_20} 0 0 auto;
  position: relative;
`;

export const DeleteIcon = styled(Icon)`
  padding-left: ${({ theme }) => theme.space_5};

  & path {
    fill: ${props => props.theme.textMain};
  }
`;

export const RemoveAllItems = styled.span`
  font-size: ${props => props.theme.fontMD};
  margin-right: ${({ theme }) => theme.space_5};
  color: ${props => props.theme.textMain};
`;

const getDesktopItemsStyles = ({ theme }) => `
  margin-top: ${theme.space_5};
  background: ${theme.textActive};
`;

export const ItemsContainer = styled.div`
  background: ${props => props.theme.secondary};
  position: relative;
  min-height: 150px;

  ${({ theme }) => theme.isDesktop && getDesktopItemsStyles};
`;

const desktopControlsStyles = `
  position: sticky;
  bottom: 0px;
`;

export const ControlsContainer = styled.div<{ desktop: boolean }>`
  position: relative;
  background-color: ${({ theme }) => theme.secondary};
  scroll-margin-bottom: ${({ theme }) =>
    theme.isDesktop ? 0 : footerHeight}px;

  ${({ desktop }) => desktop && desktopControlsStyles}
`;
