import head from 'lodash/head';
import some from 'lodash/some';
import reject from 'lodash/reject';

import services from '@features/core/services';
import { IWalletView } from '@features/wallets/walletSwitcher';

import { HAS_SUMMARY_LAYER } from '@common/constants/config';
import {
  SUMMARY_LAYER,
  TERMS_LAYER,
  WALLET_SWITCHER,
} from '@common/constants/dialogs';
import {
  ACCEPT_TAC_LAYER_COOKIE,
  DEFAULT_WALLET_ID,
  SUMMARY_LAYER_COOKIE,
  SWITCHER_POPUP_CHECKED,
} from '@common/constants/cookie';
import { IFetchWallets } from '@common/providers/payments/wallets/types';
import {
  openSidebar,
  useAppState,
} from '@common/providers/application/useAppState';
import { IUser } from '@common/interfaces';
import { IUserWalletType } from '@common/interfaces/user/IUser';
import { setWalletView } from '@common/providers/payments/wallets/useWallets';
import { isNativeApp } from '@common/helpers/links';

/**
 * isOpenDialog
 *
 * @param {string} dialog
 * @returns {boolean} isOpenDialog
 */
export const isOpenDialog = (dialog: string): boolean => {
  const { openedSidebars } = useAppState.getState();
  return some(openedSidebars, ['type', dialog]);
};

/**
 * getOpenDialog
 *
 * @returns {string | undefined} openDialog
 */
export const getOpenDialog = (): string | undefined => {
  const { openedSidebars } = useAppState.getState();
  return head(openedSidebars)?.type;
};

export const setDialogs = (params: {
  user: IUser;
  wallets: IFetchWallets;
}): boolean => {
  const { user, wallets } = params;
  const isNative = isNativeApp(window.location.search);
  if (isNative) {
    return false;
  }
  const isBalanceSwitcherEnabled =
    reject(wallets?.result?.wallets, ['type', IUserWalletType.bonus]).length >
    1;

  if (
    String(user.acceptedTAC) === '0' &&
    !services.cookie.get(ACCEPT_TAC_LAYER_COOKIE)
  ) {
    openSidebar({ type: TERMS_LAYER });
  }

  if (
    !services.cookie.get(SWITCHER_POPUP_CHECKED) &&
    isBalanceSwitcherEnabled &&
    !services.cookie.get(DEFAULT_WALLET_ID)
  ) {
    openSidebar({ type: WALLET_SWITCHER });
    setWalletView(IWalletView.POPUP_VIEW);
  }

  if (
    !services.cookie.get(SUMMARY_LAYER_COOKIE) &&
    services.config.get(HAS_SUMMARY_LAYER)
  ) {
    openSidebar({ type: SUMMARY_LAYER });
  }

  return true;
};
