import styled from 'styled-components';

import { TextNormal } from '@features/core/styles/text.styled';

export const Root = styled.div`
  background: ${props => props.theme.primaryBackground};
`;

export const TabsContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: repeat(3, 1fr);
  height: 32px;
`;

const disabledTabStyles = `
  pointer-events: none;
  opacity: .35;
`;

const activeTabStyles = theme => `
  background-color: ${theme.accent};

  & p {
    font-weight: ${theme.fontBold};
    color: ${theme.textActive};
  }
`;

export const Tab = styled.button<{ isDisabled?: boolean }>`
  border: ${({ theme }) => theme.border};
  margin: 0;
  padding: 0;
  background-color: ${props => props.theme.primaryBackground};
  position: relative;
  cursor: pointer;

  &.active {
    ${({ theme }) => activeTabStyles(theme)}
  }

  ${({ isDisabled }) => isDisabled && disabledTabStyles}
`;
export const Label = styled(TextNormal)`
  text-align: center;
  text-transform: uppercase;
  color: ${props => props.theme.textPrimary};
  margin: auto;
`;
