import { CSSProperties } from 'react';

import { IBetDetail } from '@common/interfaces';

export interface IBetItem {
  itemStyle?: CSSProperties | null;
  index?: number | null;
  currency_code?: string;
  isBetDetail?: boolean;
  bet: IBetDetail;
  hasReuseBet?: boolean;
  isBetDetailView?: boolean;
}

export enum BetItemStatusCode {
  OPEN,
  LOST,
  WON,
  CANCELLED,
}
