import React, { useMemo } from 'react';
import map from 'lodash/map';
import orderBy from 'lodash/orderBy';
import includes from 'lodash/includes';

import { EventHeader } from '@features/bettingslip/components/eventHeader';
import { Selection } from '@features/bettingslip/components/selection';

import { IBettingslipType } from '@common/interfaces';
import { ISelection } from '@common/interfaces/prediction/IPrediction';
import { getSelectionsByEvent } from '@common/helpers/bettingSlipHelper/bettingSlipModel';
import { ISelections } from '@common/interfaces/bettingslip/IBettingslip';

interface IProps {
  selectionGroup: ISelection[];
  activeBank: boolean;
  bettingslipType: IBettingslipType;
}

interface ISelectionsListProps {
  selections: ISelections;
  banks: number[];
  bettingslipType: IBettingslipType;
}

const SelectionGroup: React.FC<IProps> = ({
  selectionGroup,
  activeBank,
  bettingslipType,
}) => {
  const found = selectionGroup[0];
  const { eventId } = found;
  const categoryId = found.categoryId || '';

  return (
    <EventHeader
      eventId={eventId}
      categoryId={categoryId}
      type={bettingslipType}
      activeBank={activeBank}
    >
      {map(selectionGroup, selection => (
        <Selection key={selection.id} selection={selection} />
      ))}
    </EventHeader>
  );
};

const SelectionsList: React.FC<ISelectionsListProps> = ({
  selections,
  banks,
  bettingslipType,
}) => {
  const groupedSelections = useMemo(() => {
    const allSelections = getSelectionsByEvent(selections);
    return orderBy(allSelections, e => e[0].timeAdded, ['desc']);
  }, [selections]);

  return (
    <>
      {map(groupedSelections, selectionGroup => {
        const found = selectionGroup[0];
        const activeBank = includes(banks, parseFloat(found.eventId));

        return (
          <SelectionGroup
            key={found.eventId}
            selectionGroup={selectionGroup}
            activeBank={activeBank}
            bettingslipType={bettingslipType}
          />
        );
      })}
    </>
  );
};

export default SelectionsList;
