import styled from 'styled-components';

import { TextSM, TextXLarge } from '@features/core/styles/text.styled';

export const Container = styled.div`
  padding: ${({ theme }) => theme.space_10} 0 0;
  background: ${props => props.theme.elementNuanceColor};
`;

export const Header = styled.div`
  display: grid;
  align-items: center;
  padding-bottom: ${({ theme }) => theme.space_5};
`;

export const HeaderLabel = styled(TextSM)`
  color: ${props => props.theme.textMain};
  font-weight: ${({ theme }) => theme.fontBold};
`;

export const VirtualInputContainer = styled.div<{ isOneInput: boolean }>`
  display: grid;
  grid-auto-flow: column;
  ${props => !props.isOneInput && 'grid-auto-columns: min-content 2fr;'}

  align-items: center;
  padding: ${({ theme }) => theme.space_10} ${({ theme }) => theme.space_15};
  background-color: ${props => props.theme.tertiaryBackground};
`;

export const VirtualInputItem = styled.div`
  display: grid;
  grid-auto-flow: row;
  align-items: start;
  width: 100%;
`;

export const Separator = styled.div`
  display: grid;
  grid-auto-flow: row;
  align-items: center;
`;

export const SeparatorLabel = styled(TextXLarge)`
  padding: 0 ${({ theme }) => theme.space_10};
  color: ${props => props.theme.textMain};
`;
