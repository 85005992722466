import replace from 'lodash/replace';

import { PageName } from '@features/core/routing';
import i18n from '@features/core/translation';

const getHeaderText = (currentRoute?: PageName): string => {
  let tKey = currentRoute || '';
  let tOption = '';
  const { pathname } = window.location;
  const queryNumber = replace(pathname, /.*\/([^\/]+)$/, '$1'); // eslint-disable-line no-useless-escape, sonarjs/unnecessary-character-escapes

  switch (currentRoute) {
    case PageName.USER_LIMITS_LOCK:
      tOption = i18n.t('app-layout.navigation.limits-lock-oasis');
      break;
    case PageName.USER_PAYOUT_BANK_AMOUNT:
      tOption = queryNumber;
      break;
    case PageName.USER_PAYIN_SUCCESS:
    case PageName.USER_PAYIN_FAILURE:
    case PageName.USER_PAYIN_PREPARED:
      tKey = PageName.USER_PAYIN;
      break;
    case PageName.USER_PAYOUT_SUCCESS:
    case PageName.USER_PAYOUT_FAILURE:
    case PageName.USER_PAYOUT_PREPARED:
      tKey = PageName.USER_PAYOUT;
      break;
    default:
      break;
  }
  return i18n.t(`app-layout.navigation.${tKey}`, { value: tOption });
};

export default getHeaderText;
