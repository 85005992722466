import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Form from 'rc-field-form';

import { TextInput } from '@features/core/form/components/textInput';
import FormItem from '@features/core/form/components/FormItem';
import { RadioButton } from '@features/core/form/components/radioButton';

import { DEPOSIT_LIMIT_LAYER } from '@common/constants/dialogs';
import makeLimitPerMonthPreset from '@common/helpers/formsHelpers/validation/limitPerMonthPreset';
import { ButtonType, SidebarSide } from '@common/interfaces';
import { DEPOSIT_LIMIT } from '@common/helpers/limitsHelper/limitTypes';
import { setLimitAction } from '@common/providers/limits/helper';
import fetchPocketTags from '@common/api/account/fetchPocketTags';
import { MethodType } from '@common/api/apiHandler';
import { closeSidebar } from '@common/providers/application/useAppState';
import { usePaymentsState } from '@common/providers/payments/usePayments';

import {
  INotificationStyle,
  INotificationIcon,
} from '@ui/components/genericNotification/GenericNotification.types';
import StyledButton from '@ui/components/buttons/StyledButton';
import { GenericNotification } from '@ui/components/genericNotification';
import { Icon } from '@ui/components/icon';

import * as S from './DepositLimit.styled';

const DepositLimit = (): React.ReactElement => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [limit, setLimit] = useState(0);
  const [radio, setRadio] = useState(0);
  const account = usePaymentsState(s => s.account);
  const MAX_LIMIT = 1000;

  const handleClose = async (): Promise<void> => {
    closeSidebar(DEPOSIT_LIMIT_LAYER);
    await fetchPocketTags({
      method: MethodType.Post,
      params: {
        tag: {
          key: 'usr.frontend.shoponline_depositlimit_layer_seen',
          value: String(Date.now()),
        },
      },
    });
  };

  const handleSubmit = (): void => {
    setLimitAction({
      limitType: DEPOSIT_LIMIT,
      accountId: account.id,
      token: account.token,
      amount: limit,
      period: 'sys.deposit.self_limit.monthly',
    });
    handleClose();
  };

  const limitValuePreset = makeLimitPerMonthPreset({
    min: 1,
    max: MAX_LIMIT,
    globalErrLimitMsg: '',
  });

  return (
    <S.Backdrop className="info-limit">
      <S.Content>
        <S.ContentIcon
          className="closeIcon"
          onClick={handleClose}
          name="close"
          fill="currentColor"
        />
        <S.TitleInner>
          <Icon name="info-circle" width="30px" height="30px" />
          <S.Title>{t('limits.increase_limit')}</S.Title>
        </S.TitleInner>
        <div
          dangerouslySetInnerHTML={{
            __html: t('registration.step4.limit_section_subtitle'),
          }}
        />
        <S.RadioButtonsBlock>
          <S.RadioWrapper onClick={(): void => setRadio(0)}>
            <RadioButton
              value="no"
              title={t('helpers.no')}
              checked={radio === 0}
              isBottomMargin
              isDarkMode
            />
          </S.RadioWrapper>
          <S.RadioWrapper onClick={(): void => setRadio(1)}>
            <RadioButton
              value="yes"
              title={t('helpers.yes')}
              checked={radio === 1}
              isBottomMargin
              isDarkMode
            />
          </S.RadioWrapper>
        </S.RadioButtonsBlock>
        {radio === 1 && (
          <S.LimitsContainer>
            <S.LimitRow>
              <div>{t('registration.step4.limit_per_month')}</div>
              <Form
                form={form}
                className="form-container"
                onFieldsChange={(changedFields, allFields): void => {
                  setLimit(parseFloat(allFields[0].value));
                }}
              >
                <FormItem
                  name="sys.deposit.self_limit.monthly"
                  rules={limitValuePreset}
                  floatError
                >
                  <TextInput sufix="€" type="number" />
                </FormItem>
              </Form>
            </S.LimitRow>
            <GenericNotification
              iconType={INotificationIcon.base}
              title={t('modal.important')}
              styleType={INotificationStyle.depositLimitNotification}
            >
              {t(`registration.step4.limit_infotext`)}
            </GenericNotification>
          </S.LimitsContainer>
        )}
        {radio === 1 ? (
          <StyledButton
            onClick={handleSubmit}
            disabled={limit <= 0 || limit > MAX_LIMIT}
            label="limits.save_limits_layout"
            buttonType={ButtonType.Secondary}
          />
        ) : (
          <StyledButton
            onClick={handleClose}
            label="limits.next_limits"
            buttonType={ButtonType.Secondary}
          />
        )}
      </S.Content>
    </S.Backdrop>
  );
};

DepositLimit.meta = {
  side: SidebarSide.center,
  key: DEPOSIT_LIMIT,
};

export { DepositLimit as Component };

export default DepositLimit;
