import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import keys from 'lodash/keys';

import { ErrorUnwrapped } from '@features/bettingslip/components/error';
import services from '@features/core/services';

import { HAS_TOTAL_ODDS_NOTIFIER } from '@common/constants/config';
import {
  getCappedProfit,
  getTotalTax,
} from '@common/helpers/bettingSlipHelper/bettingSlipCalculationModel';
import { isMultiway } from '@common/helpers/bettingSlipHelper/bettingSlipModel';
import { useBettingSlip } from '@common/providers/bettingslip/useBettingSlip';
import { formatBalance } from '@common/helpers/paymentsHelper/walletsHelper';
import { IBettingslipType } from '@common/interfaces';
import useQuotas from '@common/hooks/useQuotas';

import * as S from './StakeInfo.styled';

const StakeInfo: React.FC = () => {
  const bettingslip = useBettingSlip();
  const shouldShowTaxes = bettingslip.user.taxes?.live > 0;
  const [expanded, setExpanded] = useState(false);
  const { t } = useTranslation();
  const [maxProfit, setMaxProfit] = useState(0);
  const [minProfit, setMinProfit] = useState(0);
  const [totalTax, setTotalTax] = useState(0);
  const [showQuotas, setShowQuotas] = useState(false);
  const quotas = useQuotas(bettingslip, showQuotas);

  useEffect(() => {
    setTimeout(() => {
      setMaxProfit(getCappedProfit(bettingslip, true));
      setMinProfit(getCappedProfit(bettingslip, false));
      setTotalTax(getTotalTax(bettingslip));
    }, 0);
  }, [bettingslip]);

  useEffect(() => {
    const hasLegs = isMultiway(bettingslip);
    setShowQuotas(!hasLegs && bettingslip.type === IBettingslipType.combi);
  }, [keys(bettingslip.selections).length, bettingslip.type]);

  return (
    <S.InfoWrapper>
      {!bettingslip.error?.data && (
        <>
          <S.InfoTopContainer>
            <S.InfoContainer>
              <S.InfoItem data-qa="betting-slip-possible-winnings-title">
                {t('bettingslip.chance')}
              </S.InfoItem>
              <S.InfoItemBold data-qa="betting-slip-possible-winnings">
                {formatBalance(maxProfit)}
              </S.InfoItemBold>
            </S.InfoContainer>

            {minProfit !== maxProfit && (
              <S.InfoContainer>
                <S.InfoItem data-qa="betting-slip-min-possible-winnings-title">
                  Min. {t('common.labels.possible_winnings')}
                </S.InfoItem>
                <S.InfoItemBold data-qa="betting-slip-min-possible-winnings">
                  {formatBalance(minProfit)}
                </S.InfoItemBold>
              </S.InfoContainer>
            )}
            {showQuotas && services.config.get(HAS_TOTAL_ODDS_NOTIFIER) && (
              <S.InfoContainer>
                <S.InfoItem data-qa="betting-slip-total-quotas-title">
                  {t('bettingslip.totalQuotas')}
                </S.InfoItem>
                <S.InfoItemBold data-qa="betting-slip-total-quotas">
                  {quotas}
                </S.InfoItemBold>
              </S.InfoContainer>
            )}
            {expanded && (
              <S.ToggleDropDown>
                <S.InfoContainer>
                  <S.InfoItem data-qa="betting-slip-total-stake-title">
                    {t('bettingslip.nls_bet')}
                  </S.InfoItem>
                  <S.InfoItemBold data-qa="betting-slip-total-stake">
                    {formatBalance(bettingslip.totalStake - totalTax)}
                  </S.InfoItemBold>
                </S.InfoContainer>
                {shouldShowTaxes && (
                  <S.InfoContainer>
                    <S.InfoItem data-qa="betting-slip-fee-title">
                      {t('bettingslip.fee')}
                    </S.InfoItem>
                    <S.InfoItemBold data-qa="betting-slip-fee">
                      {formatBalance(totalTax)}
                    </S.InfoItemBold>
                  </S.InfoContainer>
                )}
              </S.ToggleDropDown>
            )}
          </S.InfoTopContainer>

          <S.ContainerButton>
            <S.ShowMoreButton
              isRotateButton={expanded}
              onClick={(): void => setExpanded(!expanded)}
              data-qa="btn-toggle-stakeInfo"
              aria-label={t('common.buttons.show_more')}
              className={`${expanded && 'expanded'}`}
            >
              <S.ShowMoreIcon width="12" height="12" name="chevron-down" />
            </S.ShowMoreButton>
          </S.ContainerButton>
        </>
      )}
      <ErrorUnwrapped />
    </S.InfoWrapper>
  );
};

export default StakeInfo;
