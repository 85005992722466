import styled from 'styled-components';

import { Icon } from '@ui/components/icon';

export const Root = styled.div`
  background-color: ${({ theme }) => theme.secondary};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;

  .categories-tabs {
    display: flex;
    flex-direction: column;
    position: relative;
  }
`;

export const FiltersWrap = styled.div<{
  topLayoutHeight: number;
}>`
  top: ${({ topLayoutHeight }) => topLayoutHeight}px;
  position: sticky;
  z-index: 5;
  width: 100%;
`;

export const mainFilterWrapBorder = color =>
  `border-bottom: 2px solid ${color}`;

export const MainFilterWrap = styled.div<{ timeFilter: boolean }>`
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  justify-content: start;
  background-color: ${({ theme }) => theme.bodyBackground};

  ${({ timeFilter, theme }) =>
    timeFilter && !theme.isDesktop && mainFilterWrapBorder(theme.accent)};
`;

export const StyledIcon = styled(Icon)`
  margin-top: ${({ theme }) => theme.space_5};
`;

export const LongTermWrapper = styled.div`
  margin-top: ${({ theme }) => theme.space_10};
`;

export const CategoriesWrapper = styled.div`
  padding-bottom: ${({ theme }) => theme.space_60};
`;

export const LoadingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;
