import React from 'react';
import { useTheme } from 'styled-components';

import { ANIMATED_BALANCE } from '@common/helpers/appLayoutHelper';
import { useAppState } from '@common/providers/application/useAppState';
import {
  getActiveWallet,
  formatWalletBalance,
} from '@common/helpers/paymentsHelper/walletsHelper';

import { IBalanceBadgeProps } from './BalanceBadge.types';
import Root from './BalaceBadge.styled';
import * as S from './BalaceBadge.styled';

const BalanceBadge: React.FC<IBalanceBadgeProps> = ({ className }) => {
  const showComponent = useAppState(state => state.showComponent);
  const { tertiaryBackground } = useTheme();
  const isAnimation = showComponent === ANIMATED_BALANCE;

  const renderBalance = (): string | JSX.Element => {
    const activeWallet = getActiveWallet();
    if (activeWallet) {
      return formatWalletBalance(activeWallet, true);
    }
    return (
      <S.IconWrapper>
        <S.LoaderIcon
          name="loader"
          width="18px"
          height="18px"
          fill={tertiaryBackground}
        />
      </S.IconWrapper>
    );
  };

  return (
    <Root isAnimation={isAnimation} className={className}>
      {renderBalance()}
    </Root>
  );
};

export default BalanceBadge;
