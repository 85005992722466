import groupBy from 'lodash/groupBy';
import sortBy from 'lodash/sortBy';
import every from 'lodash/every';
import values from 'lodash/values';
import parseISO from 'date-fns/parseISO';

import { IBetSelection, IPrediction, PredictionType } from '@common/interfaces';
import { isLiveCurrent } from '@common/helpers/eventsHelper/eventStatusHelper';
import { ISelections } from '@common/interfaces/bettingslip/IBettingslip';
import { ISelection } from '@common/interfaces/prediction/IPrediction';
import { useEventsListState } from '@common/providers/events/eventList/useEventsList';
import { normalizeLabel } from '@common/helpers/eventsHelper/predictionModel';

/**
 * sortByTime
 *
 * @param {IBetSelection[][]} selections
 * @returns {IBetSelection[][]} grouped
 */
export const sortByTime = (
  selections: IBetSelection[][],
): IBetSelection[][] => {
  return sortBy(selections, selection => {
    return selection[0].event_date_safe_server
      ? parseISO(selection[0].event_date_safe_server)
      : selection;
  });
};

/**
 * groupByMarket
 *
 * @param {ISelection[]} selections
 * @returns {ISelection[][]} selections grouped by market
 */
export const groupByMarket = (selections: ISelection[]): ISelection[][] => {
  const groupedSelection = groupBy(selections, 'marketId');
  return values(groupedSelection);
};

/**
 * groupByCategory
 *
 * @param {ISelections} selections
 * @returns {Record<string, ISelection[]>} selectionsByCategories
 */
export const groupByCategory = (
  selections: ISelections,
): Record<string, ISelection[]> => {
  const selectionsArray = values(selections);
  return groupBy(selectionsArray, 'categoryId');
};

export const isFullyLive = (): boolean => {
  const { events } = useEventsListState.getState().betslip.data;
  return every(events, isLiveCurrent);
};

/**
 * getLabel
 *
 * @param {IPrediction} prediction
 * @returns {string} label
 */
export const getSelectionLabel = (prediction: IPrediction): string => {
  if (
    prediction.type === PredictionType.WINNER ||
    prediction.type === PredictionType.GOALSCORER
  ) {
    return normalizeLabel(prediction.label);
  }
  return prediction.label || '';
};
