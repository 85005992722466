import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { PageName } from '@features/core/routing/linkAliases';
import CountdownDoubleWhiteButton from '@features/bettingslip/components/buttons/CountdownDoubleWhiteButton';

import { setLocation } from '@common/providers/router/helper';
import {
  serializeBS,
  validate,
} from '@common/helpers/bettingSlipHelper/bettingSlipCalculationModel';
import { serializeQueued } from '@common/helpers/bettingSlipHelper/bettingSlipModel';
import {
  STATE_FROZEN,
  STATE_OPENED,
  STATE_QUEUED,
} from '@common/providers/bettingslip/state';
import {
  changeBettingSlipState,
  setBettingSlipError,
  submitBettingSlip,
  useBettingSlip,
} from '@common/providers/bettingslip/useBettingSlip';
import { useUserState } from '@common/providers/user/useUserState';
import usePrevious from '@common/hooks/usePrevious';
import useCountDown from '@common/hooks/useCountDown';
import { handleBSErrorAndOddsUpdate } from '@common/providers/bettingslip/helper';
import {
  useIsLoggedIn,
  isLoginProccessing,
} from '@common/helpers/userHelper/loggedInState';

const Buttons: React.FC = () => {
  const { t } = useTranslation();
  const { countdown, startCountDown } = useCountDown();
  const queue_delay = useBettingSlip(s => s.queue_delay);
  const bet_id = useBettingSlip(s => s.bet_id);
  const state = useBettingSlip(s => s.state);
  const loading = useBettingSlip(s => s.loading);
  const error = useBettingSlip(s => s.error);
  const loadingState = useUserState(s => s.loadingState);
  const isLoggedIn = useIsLoggedIn();
  const prevDelay = usePrevious(queue_delay);

  const handleClick = useCallback((): void => {
    if (error) {
      setBettingSlipError({});
    }
    const bettingSlip = useBettingSlip.getState();
    const errorBettingslip = validate(bettingSlip, false);
    if (!isLoggedIn) {
      setLocation(PageName.LOGIN);
      return;
    }
    if (errorBettingslip) {
      handleBSErrorAndOddsUpdate(errorBettingslip);
      return;
    }
    if (state === STATE_OPENED) {
      changeBettingSlipState(STATE_FROZEN);
      submitBettingSlip(serializeBS(bettingSlip));
    }
  }, [state, isLoggedIn]);

  useEffect(() => {
    if (queue_delay && prevDelay !== queue_delay) {
      startCountDown(() => {
        if (bet_id) {
          submitBettingSlip(serializeQueued(bet_id));
        }
      }, queue_delay);
    }
  }, [queue_delay]);

  const loginBtnLabel =
    state === STATE_QUEUED ? 'bettingslip.confirmed' : 'bettingslip.make';

  return (
    <CountdownDoubleWhiteButton
      onClick={handleClick}
      label={isLoggedIn ? loginBtnLabel : t('bettingslip.make_after_login')}
      withBackground
      isWhite={state === STATE_QUEUED}
      isLoading={loading}
      disabled={isLoginProccessing(loadingState)}
      dataQa="bettingSlip-btn-submit"
      type="submit"
      countdown={state === STATE_QUEUED ? countdown : 0}
      noLock
    />
  );
};

export default Buttons;
