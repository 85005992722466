import styled from 'styled-components';

import { TextNormal } from '@features/core/styles/text.styled';

import { Icon } from '@ui/components/icon';

export const InfoTopContainer = styled.div`
  display: grid;
  grid-auto-flow: row;
  padding: ${({ theme }) => theme.space_5} 0 0;
  overflow: hidden;
  background-color: ${props => props.theme.main};

  & div:first-child {
    & p,
    & div {
      font-size: ${props => props.theme.fontLG};
    }
  }
`;

export const InfoContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  justify-content: space-between;
  align-items: center;
  padding: 0 ${({ theme }) => theme.space_10} ${({ theme }) => theme.space_5};
`;

export const InfoItem = styled(TextNormal)`
  color: ${props => props.theme.textMain};
`;

export const InfoItemBold = styled(TextNormal)`
  font-weight: ${({ theme }) => theme.fontBold};
  color: ${props => props.theme.textMain};
`;

export const ContainerButton = styled.div`
  background-color: ${props => props.theme.tertiaryBackground};
`;

export const ShowMoreButton = styled.button<{ isRotateButton: boolean }>`
  margin: auto;
  background: none;
  border: ${({ theme }) => theme.border};
  padding: 0;
  display: grid;
  justify-content: center;
  align-items: center;
  height: 24px;
  width: 24px;
  color: ${props => props.theme.textMain};
  ${({ isRotateButton }) =>
    !isRotateButton ? '' : 'transform: rotate(180deg);'}
`;

export const ShowMoreIcon = styled(Icon)`
  path {
    fill: ${props => props.theme.textMain};
  }
`;

export const InfoWrapper = styled.div`
  position: relative;
`;

export const ToggleDropDown = styled.div`
  background: ${({ theme }) => theme.tertiaryBackground};
  padding-top: ${({ theme }) => theme.space_5};

  &:empty {
    padding-top: 0;
  }
`;
