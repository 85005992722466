import CustomError from '@features/core/error/error';
import services from '@features/core/services';

import { CommonEvents, MarketingEvents } from '@packages/events/appEvents';

import {
  formatedData,
  getErrors,
  setRegisterCookies,
} from '@common/helpers/registrationHelper/registrationHelpers';
import { IRegistrationData } from '@common/interfaces';
import fetchRegistration from '@common/api/registration/fetchRegistration';
import authUser from '@common/providers/user/actions/authUser';
import {
  setRegisterError,
  setRegisterLoading,
  setRegisterSuccess,
} from '@common/providers/registration/useRegistration';
import { forwardDestination } from '@common/providers/user/actions/actionFwdDestination';
import {
  getAffiliateParamsById,
  getAffiliateParamsData,
} from '@common/helpers/userHelper/affiliate/affiliateParams';

const postRegistration = async (payload: IRegistrationData): Promise<void> => {
  try {
    setRegisterLoading(true);
    setRegisterError({ submitErrors: [] });

    await getAffiliateParamsData();

    const preparedAffiliateData = getAffiliateParamsById();

    const response = await fetchRegistration(
      formatedData(payload, preparedAffiliateData),
    );

    if (response instanceof CustomError || response.error) {
      setRegisterError({
        submitErrors: [
          {
            message:
              response?.error?.userMessages[0] || 'Unknown error in response',
          },
        ],
      });
    } else {
      services.events.emitEvent(CommonEvents.SUCCESS_REGISTRATION, {
        user: payload.login,
        password: payload.password,
      });
      setRegisterCookies(response);
      setRegisterSuccess(true);
      setRegisterLoading(false);
      forwardDestination();
      await authUser();
      services.events.emitEvent(MarketingEvents.REGISTRATION_STEP, {
        step: 'success',
      });
    }
  } catch (e) {
    setRegisterLoading(false);
    setRegisterError(getErrors(e, payload));
  }
};

export default postRegistration;
