import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import numeral from 'numeral';

import { useServices } from '@features/core/services';
import { useCashoutItemModel } from '@features/cashout/useCashoutItemModel';

import { serialize, serializeQueued } from '@common/helpers/cashoutHelper';
import {
  ICashoutInputProps,
  ICashoutButtonProps,
  TTimeout,
  ICashoutRequested,
} from '@common/interfaces';
import deleteTimeout from '@common/helpers/timeoutHelper';
import {
  setCashoutRequestError,
  submitCashoutRequestAction,
} from '@common/providers/cashout/useCashout';
import { ICashoutRequestError } from '@common/providers/cashout/types';

export default (SubmitHoc): ((passedProps) => JSX.Element) => {
  const Submit = (
    passedProps: ICashoutInputProps | ICashoutButtonProps,
  ): React.ReactElement => {
    const { t } = useTranslation();
    const { domainLang } = useServices();
    const { betId, setPartCashout, barcode } = passedProps;
    const { requestedCashout, cashoutOffer, offerToken } = useCashoutItemModel(
      betId,
    );
    const timerinterval = useRef<TTimeout>(null);
    const [cashoutOfferQueued, setCashoutOfferQueued] = useState<string>('');
    useEffect(() => {
      if (
        betId === requestedCashout?.cashout_detail?.cashout_id &&
        requestedCashout.state !== 'queued'
      ) {
        timerinterval.current = setTimeout(
          () => setCashoutOfferQueued(''),
          5000,
        );
      }
    }, [requestedCashout]);

    useEffect(() => {
      return () => {
        setCashoutOfferQueued('');
        deleteTimeout(timerinterval.current);
      };
    }, []);

    const onSubmitCashoutQueued = (
      newRequestedCashout: ICashoutRequested,
    ): Promise<boolean> => {
      return submitCashoutRequestAction(
        serializeQueued({
          bet_id: betId,
          barcode,
          lang: domainLang,
          cashout_id: newRequestedCashout?.cashout_detail?.cashout_id,
          offer_token: offerToken,
        }),
      );
    };

    // eslint-disable-next-line sonarjs/default-param-last
    const submit = (max = false, amount): Promise<boolean> | void => {
      setCashoutOfferQueued(cashoutOffer);
      setPartCashout(max);
      const newAmount = amount;
      if (+newAmount > parseFloat(cashoutOffer) && !max) {
        return setCashoutRequestError({
          data: { bet_id: betId },
          message: t('cashout.errorExceededMaxCashout'),
        } as ICashoutRequestError);
      }

      return submitCashoutRequestAction(
        serialize({
          amount: max
            ? parseFloat(cashoutOffer)
            : Number(numeral(newAmount).value()),
          offer: parseFloat(cashoutOffer),
          bet_id: betId,
          lang: domainLang,
          offer_token: offerToken,
          barcode,
        }),
      );
    };

    const props = {
      ...passedProps,
      submit,
      onSubmitCashoutQueued,
      cashoutOfferQueued,
    };

    return <SubmitHoc {...props} />;
  };
  Submit.displayName = 'Submit';
  return Submit;
};
