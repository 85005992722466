import styled from 'styled-components';

import { IListType } from '@features/wallets/switcher/Switcher.types';

import { hexToRgba } from '@common/helpers/deviceUtil';

import { Icon } from '@ui/components/icon';

const getListStyle = (type: IListType, theme) => {
  switch (type) {
    case 'single':
      return `
        grid-auto-flow: unset;
        grid-template-columns: 1fr;
        
        & .item {
          border: none;
          height: auto;
        }
        
        & .content-top {
          display: flex;
          align-items: center;
          justify-content: center;
        }
        
        & .title {
          font-size: ${theme.fontLG};
          font-weight: bold;
          text-align: center;
        }
        
        & .description {
          max-width: none;
          margin-left: 0;
          margin-top: 5px;
          font-size: ${theme.fontSM};
          font-weight: bold;
          opacity: .65;
        }
        
        & .icon {
          width: 18px;
          height: 18px;
        }
      `;
    case 'dual':
      return `
        grid-auto-flow: unset;
        grid-template-columns: 1fr 1fr;
      `;
    default:
      return `
        & .item {
          min-width: 140px;
        }
      `;
  }
};

export const List = styled.div<{ listType: IListType }>`
  display: grid;
  overflow: auto hidden;
  grid-auto-flow: column;
  grid-auto-columns: min-content;
  gap: ${({ theme }) => theme.space_10};
  padding: ${({ theme }) => theme.space_10};
  background: ${({ theme }) => theme.tertiaryBackground};
  ${({ listType, theme }) => getListStyle(listType, theme)};
`;
export const Item = styled.div<{ isActive: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 ${({ theme }) => theme.space_10};
  height: 42px;
  border: 1px solid ${({ theme }) => theme.textMain};
  border-radius: 3px;
  color: ${({ theme, isActive }) =>
    isActive ? theme.textActive : theme.textMain};
  box-sizing: border-box;
  font-weight: ${({ isActive }) => (isActive ? 'bold' : 'normal')};
  background-color: ${({ isActive, theme }) =>
    isActive ? theme.textMain : 'inherit'};

  & .icon {
    color: ${({ theme, isActive }) =>
      isActive ? theme.textActive : theme.textMain};
  }
`;
export const ItemIconWrap = styled.div`
  display: flex;
  align-self: stretch;
  align-items: center;
  margin-right: ${({ theme }) => theme.space_10};
`;
export const ItemContentWrap = styled.div``;
export const ItemIcon = styled(Icon)``;
export const ItemTitle = styled.div`
  font-size: ${({ theme }): string => theme.fontMD};
  white-space: nowrap;
  text-align: left;
`;

export const ItemContentTop = styled.div``;

export const ItemDescription = styled.div`
  max-width: 120px;
  font-size: ${({ theme }): string => theme.fontXS};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const CheckCircle = styled.div`
  position: absolute;
  top: -5px;
  right: -5px;
  display: grid;
  width: 20px;
  height: 20px;
  background-color: ${({ theme }) => theme.accent};
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  box-shadow: -1px 1px 2px ${({ theme }) => hexToRgba(theme.textTertiary, 0.16)};
`;
