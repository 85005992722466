import takeRight from 'lodash/takeRight';
import findIndex from 'lodash/findIndex';
import maxBy from 'lodash/maxBy';

import services from '@features/core/services';

import { IEventUpdates } from '@common/interfaces';

let globalqueue = [] as IEventUpdates;
const MESSAGES_IN_GLOBALQUEUE = 300;

export const enqueInGlobalQueue = (updates: IEventUpdates): IEventUpdates => {
  globalqueue = takeRight(globalqueue.concat(updates), MESSAGES_IN_GLOBALQUEUE);
  return globalqueue;
};

export const getGlobalQueue = (index): IEventUpdates =>
  takeRight(globalqueue, globalqueue.length - index);

export const getApplySince = (version: number): number => {
  const updates = getGlobalQueue(0);
  let applySince = findIndex(updates, ['version', version]);
  if (applySince === -1) {
    const maxVersionUpdate = (maxBy(updates, 'version') as unknown) as number;
    applySince = maxVersionUpdate < version ? 0 : -1;
    if (maxVersionUpdate < version) {
      services.logger.log(
        `Applying max version index: event list version is ${version}, max updates in queue version is ${maxVersionUpdate}, no updates to apply`,
      );
    } else {
      services.logger.log(
        `Applying max version index: event list version is ${version}, max updates in queue version is ${maxVersionUpdate}, applying all`,
      );
    }
  } else {
    services.logger.log(`Applying index ${applySince}`);
  }
  return applySince === -1 ? updates.length : applySince;
};
