import keys from 'lodash/keys';
import { generatePath, matchPath } from 'react-router-dom';
import reduce from 'lodash/reduce';

import services from '@features/core/services';
import { PageName } from '@features/core/routing/linkAliases';
import { ROUTERS_MAPPER } from '@features/core/routing/routerMapper';

import { LANGUAGE } from '@common/constants/cookie';
import { useAppState } from '@common/providers/application/useAppState';

/**
 * Changes application language
 *
 * @param {string} language
 * @returns {void}
 */
const changeLanguage = (language: string): void => {
  const alternate = useAppState.getState().metaData?.alternate_slug;

  const keyRoute = reduce(
    keys(ROUTERS_MAPPER),
    (found, key) => {
      const path = ROUTERS_MAPPER[key][services.domainLang];
      const route = matchPath(window.location.pathname, {
        path,
        exact: true,
        strict: false,
      });
      if (route) {
        return { path: ROUTERS_MAPPER[key][language], params: route.params };
      }
      return found;
    },
    {
      path: ROUTERS_MAPPER[PageName.HOME][language],
      params: {},
    },
  );

  if (language && language !== services.domainLang) {
    const path = alternate || keyRoute.path;
    services.updateService('domainLang', language);
    window.location.pathname = generatePath(path, keyRoute.params);
    services.cookie.set(LANGUAGE, language);
  }
};

export default changeLanguage;
