import styled from 'styled-components';

export const mobileBetsContainerStyles = `
  .score-container {
   padding-right: 0;
   margin-right: -5px;
   min-width: 20px;
  };
  
  .team-scoreboard {
    justify-content: end;
    justify-items: end;
    padding: 0;
  };
`;

export const BetsContainer = styled.div`
  ${({ theme }) => !theme.isDesktop && mobileBetsContainerStyles};

  .placed-bet {
    display: grid;
    grid-auto-flow: row;
  }

  .separator-top {
    border-top: 1px solid ${({ theme }) => theme.inputDisabledBackground};
  }

  .p-vertical10 {
    padding: ${({ theme }) => theme.space_10};
    margin: 0 -${({ theme }) => theme.space_10};
  }
`;
