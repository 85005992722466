import styled from 'styled-components';

export const RivalsContainer = styled.div<{ isCancelled?: boolean }>`
  display: grid;
  grid-auto-flow: column;
  grid-row: 1;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.space_5};
  grid-template-columns: 20px 1fr min-content;
  padding: ${({ theme }) => theme.space_5} 0 ${({ theme }) => theme.space_5}
    ${({ theme }) => theme.space_10};
  ${({ isCancelled }) => isCancelled && 'opacity: 0.6;'};

  .scoreboard-time {
    font-size: ${props => props.theme.fontSM};
    color: ${props => props.theme.textMain};
  }
`;

export const RivalsContainerLabel = styled.div`
  padding-right: ${({ theme }) => theme.space_25};
  position: relative;
  left: ${({ theme }) => theme.space_10};
  letter-spacing: normal;
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  min-width: 120px;
`;
export const RivalsContainerTimeScore = styled.div`
  display: grid;
  grid-auto-flow: column;
  justify-content: space-between;
  align-items: center;
  width: 115px;

  & .time-container:empty {
    display: none;

    & + .score-container {
      justify-content: space-between;
      width: ${({ theme }) => (theme.isDesktop ? '100' : '115')}px;
    }
  }

  & .score {
    min-width: max-content;
  }
`;

export const RivalsContainerScoreWrapper = styled.div`
  display: grid;
  grid-auto-flow: column;
  justify-content: center;
  align-items: center;
`;

export const RivalsContainerTimerWrapper = styled.div`
  margin-right: ${({ theme }) => theme.space_10};
`;

export const Containers = styled.div`
  padding-right: ${({ theme }) => !theme.isDesktop && theme.space_15};
`;

export const TeamWrapper = styled.div`
  position: relative;
  display: grid;
  grid-auto-flow: column;
  padding-left: ${({ theme }) => theme.space_10};
  margin-left: -${({ theme }) => theme.space_10};
  font-weight: ${({ theme }) => theme.fontBold};
  color: ${props => props.theme.textMain};
`;

export const BankButtonContainer = styled.div``;
export const EmptyLeftSpace = styled.div`
  width: 20px;
`;

export const activeBankButtonStyles = theme => `
  background: ${theme.accent};
  color: ${theme.textActive};
`;

export const BankButton = styled.button<{ isActiveBank: boolean }>`
  width: 20px;
  height: 33px;
  background: ${props => props.theme.primaryBackground};
  color: ${props => props.theme.textMain};
  border: ${({ theme }) => theme.border};
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  justify-content: center;
  padding: 0;

  ${({ isActiveBank, theme }) => isActiveBank && activeBankButtonStyles(theme)};
`;

export const BankButtonLabel = styled.span``;
