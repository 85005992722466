import { IGCMSUserGroup } from '@common/interfaces';

export interface IOddsBoostData {
  name: string;
  slug: string;
  headline: string;
  sub_headline: string;
  conditions: string;
  usergroup: IGCMSUserGroup;
  status: boolean;
  quotenboost_configuration: IOddsBoostEvent[];
  createdAt: string;
  updatedAt: string;
  schedule_from: string;
  schedule_to: string;
  publishedAt: string;
  locale: string;
  localizations: string[];
  id: string;
  hero_image: string;
  template_type: IOddsBoostTemplateType;
}

export interface IOddsBoostEvent {
  boosted_odds: string;
  current_odds: string;
  event: {
    id: number | string;
    label: string;
    expires_ts: number;
  };
  image: string;
  label_loser: string;
  label_winner: string;
  market: {
    id: number | string;
    label: string | number;
  };
  prediction: {
    id: number | string;
    odds: string | number;
  };
}
export enum IOddsBoostTemplateType {
  default = 1,
  HeroTemplate = 2,
}
