import React, { useState } from 'react';
import noop from 'lodash/noop';
import includes from 'lodash/includes';
import { useTheme } from 'styled-components';

import { PageName } from '@features/core/routing/linkAliases';

import { setLocation } from '@common/providers/router/helper';
import { toggleAnimatedComponent } from '@common/providers/application/useAppState';

import { Icon } from '@ui/components/icon';

import * as S from './TabHeader.styled';
import ITabHeader from './TabHeader.types';

const TabHeader: React.FC<ITabHeader> = props => {
  const {
    title,
    isBackButton,
    isToggleButton,
    onClose = noop,
    icon,
    type,
    isBetting,
    dataQaClose,
    className,
  } = props;
  const { iconFillLight } = useTheme();

  const [closed, setClosed] = useState(false);

  const handleLoginIconClick = (): void => {
    if (includes(window.location.pathname, 'login')) {
      return setLocation(PageName.HOME);
    }

    return setLocation(PageName.LOGIN);
  };

  const renderBackButton = (): React.ReactElement => {
    return (
      <S.BackButton onClick={handleLoginIconClick}>
        <Icon name="login" width="21" height="18" viewBox="0 0 486 486" />
      </S.BackButton>
    );
  };

  const renderIcon = (): React.ReactElement | null => {
    if (icon) {
      return <S.IconContainer type={type}>{icon}</S.IconContainer>;
    }

    return null;
  };

  const onClickHandler = (): void | null => {
    if (isBetting) {
      toggleAnimatedComponent('');
      setTimeout(() => onClose && onClose());
      return;
    }
    if (onClose) {
      onClose();
    }
    if (isToggleButton) {
      setClosed(prevState => !prevState);
    }
  };

  return (
    <S.Header
      type={type}
      isBackButton={isBackButton}
      className={className}
      onClick={onClickHandler}
      data-qa="tab-header"
      isBetting={isBetting}
    >
      {isBackButton ? renderBackButton() : renderIcon()}
      <S.Title>{title}</S.Title>
      {isToggleButton && (
        <Icon
          width="18"
          height="18"
          name="chevron-down"
          className={`${closed ? 'closed' : ''} toggle-btn`}
        />
      )}
      <S.CloseButtonContainer data-qa={dataQaClose}>
        {!isBetting && (
          <Icon
            name="close"
            height="15"
            width="15"
            onClick={onClose}
            fill={iconFillLight}
          />
        )}
      </S.CloseButtonContainer>
    </S.Header>
  );
};

export default TabHeader;
