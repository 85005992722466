import React, { useEffect, useState } from 'react';
import map from 'lodash/map';
import mapValues from 'lodash/mapValues';
import constant from 'lodash/constant';

import { useServices } from '@features/core/services';
import { IToggleValues } from '@features/app/layout/acceptCookieLayer/AcceptCookie.types';
import mockedContentData from '@features/app/layout/acceptCookieLayer/mockedContentData.json';

import { ACCEPTED_COOKIE } from '@common/constants/cookie';
import { ACCEPT_COOKIE_LAYER } from '@common/constants/dialogs';
import { ButtonSize, ButtonType, SidebarSide } from '@common/interfaces';
import {
  closeSidebar,
  setStaticPageContent,
  useAppState,
} from '@common/providers/application/useAppState';
import {
  getAddTagEventDataCookie,
  getCookieConfig,
  getToggleData,
  initialToggle,
  saveCookieHandler,
} from '@common/helpers/acceptCookieLayerHelper';
import { addGTagEvent } from '@common/helpers/marketing';
import { useIsLoggedIn } from '@common/helpers/userHelper/loggedInState';

import { StyledButton } from '@ui/components/buttons';
import { Toggle } from '@ui/components/toggle';
import { Tag } from '@ui/components/tag';
import { Accordion } from '@ui/components/accordion';

import * as S from './AcceptCookieLayer.styled';

const AcceptCookieLayer = (): React.ReactElement => {
  const isLoggedIn = useIsLoggedIn();
  const { domainLang, cookie, logger } = useServices();
  const staticPageContent = useAppState(state => state.staticPageContent);
  const staticId = 'internal/cookie-consent-data-2';
  let content = mockedContentData;
  try {
    content = JSON.parse(String(staticPageContent?.[staticId]?.body));
  } catch (e) {
    logger.log(`Not able to parse cookie content ${e}`);
    content = mockedContentData;
  }

  const acceptCookie = cookie.get(ACCEPTED_COOKIE);

  const cookieConfig = getCookieConfig(content?.groups);

  const [isToggleEnabled, setIsToggleEnabled] = useState(initialToggle);

  const onToggleEnabledHandler = (id: string): void => {
    if (id === 'essential') {
      return;
    }
    setIsToggleEnabled(prevState => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  const onSubmitHandler = (type): void => {
    const getCookieValue = (): IToggleValues => {
      switch (type) {
        case 'decline':
          return initialToggle;
        case 'acceptAll':
          return mapValues(initialToggle, constant(true));
        default:
          return isToggleEnabled;
      }
    };

    if (type !== 'save') {
      setIsToggleEnabled(getCookieValue());
    }
    const event = getAddTagEventDataCookie(content, getCookieValue());
    saveCookieHandler(event);
    addGTagEvent(event);
    closeSidebar(ACCEPT_COOKIE_LAYER);
  };

  useEffect(() => {
    if (acceptCookie) {
      setIsToggleEnabled(getToggleData(content, acceptCookie));
    }
  }, [acceptCookie, staticPageContent?.[staticId]?.body]);

  useEffect(() => {
    if (isLoggedIn) {
      setStaticPageContent({
        lang: domainLang,
        types: [staticId],
      });
    }
  }, []);

  return (
    <S.ContentWrapper>
      <S.TitleWrapper className="top-container">
        <S.Title
          dangerouslySetInnerHTML={{
            __html: content?.cookie_title,
          }}
        />
        <div
          dangerouslySetInnerHTML={{
            __html: content?.cookie_info,
          }}
        />
      </S.TitleWrapper>
      <div className="options">
        {map(cookieConfig?.policies, (item, elementIndex) => {
          return (
            <Accordion
              id={item.id}
              elementIndex={elementIndex}
              groupLength={cookieConfig?.policies?.length + 1}
              hiddenContent={map(item.info, element => {
                return <Tag element={element} />;
              })}
            >
              <S.ItemHeaderSection>
                <div className="header">{item.name}</div>
                <div className="subheader">{item.title}</div>
              </S.ItemHeaderSection>
              <S.ItemSwitcher>
                <Toggle
                  onClick={(): void => onToggleEnabledHandler(item.id)}
                  width="50px"
                  height="25px"
                  checkedState={isToggleEnabled[item.id]}
                  enabled={item.id !== 'essential'}
                />
              </S.ItemSwitcher>
            </Accordion>
          );
        })}
      </div>
      <S.StickyButtonsWrapper>
        <div className="border" />
        <S.ButtonsWrapper>
          {map(cookieConfig?.permissionLabels, buttonEl => {
            return (
              <StyledButton
                onClick={(): void => {
                  onSubmitHandler(buttonEl.id);
                }}
                label={buttonEl.title}
                key={buttonEl.id}
                className={buttonEl.id}
                buttonType={
                  buttonEl.id === 'acceptAll'
                    ? ButtonType.Secondary
                    : ButtonType.DarkGhost
                }
                size={ButtonSize.Big}
              />
            );
          })}
        </S.ButtonsWrapper>
      </S.StickyButtonsWrapper>
    </S.ContentWrapper>
  );
};

AcceptCookieLayer.meta = {
  side: window.innerWidth > 481 ? SidebarSide.center : SidebarSide.bottom,
  key: ACCEPT_COOKIE_LAYER,
  preventDarkening: false,
  fullScreen: true,
};

export { AcceptCookieLayer as Component };

export default AcceptCookieLayer;
