import services from '@features/core/services';

import { CommonEvents } from '@packages/events/appEvents';

import { DATA_COOKIE, STEP_COOKIE } from '@common/constants/cookie';

// eslint-disable-next-line import/prefer-default-export
export const sendRegCookiesToNative = (): void => {
  const registrationCookies = {
    [DATA_COOKIE]: services.cookie.get(DATA_COOKIE),
    [STEP_COOKIE]: services.cookie.get(STEP_COOKIE),
  };
  services.events.emitEvent(
    CommonEvents.SET_NATIVE_COOKIE,
    registrationCookies,
  );
};
