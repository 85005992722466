import React from 'react';

interface ErrorBoundaryProps {
  children: React.ReactNode;
  // eslint-disable-next-line react/require-default-props
  fallback?: React.ReactElement;
}

interface ErrorBoundaryState {
  hasError: boolean;
}

// to simulate error in component put this into the componentent body:
// throw new Error('Simulated error for testing ErrorBoundary');

class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(): ErrorBoundaryState {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    console.error('ErrorBoundary caught an error', error, errorInfo);
  }

  render(): React.ReactNode {
    const { hasError } = this.state;
    const { fallback, children } = this.props;

    if (hasError) {
      if (fallback) {
        return fallback;
      }
      return (
        <div className="error-view" style={{ color: '#fff' }}>
          <h1>Oops! Something went wrong.</h1>
          <p>
            We are sorry for the inconvenience. You can try to reload the page
            or navigate to a different section.
          </p>
        </div>
      );
    }
    return children;
  }
}

export default ErrorBoundary;
