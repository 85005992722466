import React, { useState } from 'react';
import map from 'lodash/map';
import find from 'lodash/find';
import noop from 'lodash/noop';

import services, { useServices } from '@features/core/services';

import { CommonEvents } from '@packages/events/appEvents';

import { OMIT_FACE_ID_CHECK } from '@common/constants/cookie';
import { isDesktopView } from '@common/helpers/deviceUtil';
import changeLanguage from '@common/helpers/changeLangHelper';

import * as S from './LanguageSwitcher.styled';

const LANGUAGES = [
  {
    id: 'en',
    title: 'English',
    ariaLabel: 'Language: English',
  },
  {
    id: 'de',
    title: 'Deutsch',
    ariaLabel: 'Sprache: Deutsch',
  },
];

const LanguageSwitcher: React.FC = (): React.ReactElement => {
  const { domainLang } = useServices();
  const [showList, setShowList] = useState(false);

  const onClickHandler = (): void => {
    return isDesktopView() ? noop() : setShowList(prev => !prev);
  };
  const onMouseHandler = (showListValue: boolean): void => {
    return !isDesktopView() ? noop() : setShowList(showListValue);
  };

  return (
    <S.SwitcherWrapper>
      <S.Select
        onMouseOver={(): void => onMouseHandler(true)}
        onMouseLeave={(): void => onMouseHandler(false)}
        onClick={onClickHandler}
      >
        <div className={`select ${showList && 'list-opened'}`}>
          {find(LANGUAGES, { id: domainLang })?.title}
          <S.DropIcon name="chevron-down" width="12" height="12" />
        </div>
        {showList && (
          <div className="lngList">
            {map(
              LANGUAGES,
              ({ title, id, ariaLabel }) =>
                id !== domainLang && (
                  <a
                    id="lngBtn"
                    aria-label={ariaLabel}
                    data-qa={ariaLabel}
                    key={id}
                    href={id === 'de' ? '/' : `/${id}`}
                    onClick={(e): boolean => {
                      services.events.emitEvent(
                        CommonEvents.SET_NATIVE_COOKIE,
                        { [OMIT_FACE_ID_CHECK]: true },
                      );
                      e.preventDefault();
                      changeLanguage(id);
                      return false;
                    }}
                  >
                    {title}
                  </a>
                ),
            )}
          </div>
        )}
      </S.Select>
    </S.SwitcherWrapper>
  );
};

export default LanguageSwitcher;
