import styled from 'styled-components';

import { TextXLarge } from '@features/core/styles/text.styled';

import { hexToRgba } from '@common/helpers/deviceUtil';

const styles = {
  height: 50,
};

const menuHeaderStyles = ({ theme }): string => `
  justify-content: space-between;
  background-color: ${theme.secondary};

  div:first-child {
    padding-left: ${theme.space_10};
  }
`;

const headerBettingStyles = (bgColor: string, fontSize: string): string => `
  background-color: ${bgColor};
  height: 36px;
  
  div {
    font-size: ${fontSize};
  }
`;

export const Header = styled.div<{
  isBackButton?: boolean;
  type?: string;
  isBetting?: boolean;
}>`
  display: grid;
  align-content: center;
  justify-content: start;
  height: ${styles.height}px;
  width: 100%;
  background-color: ${props => props.theme.textActive};
  box-shadow: 0 3px 6px 0 ${({ theme }) => hexToRgba(theme.textTertiary, 0.16)};
  position: relative;

  .toggle-btn {
    margin-left: auto;
    margin-right: -${({ theme }) => theme.space_15};
    transform: rotate(180deg);

    &.closed {
      transform: rotate(0deg);
    }
  }

  .closed {
    transform: rotate(180deg);
  }

  ${({ type }) => type && type === 'menuPanel' && menuHeaderStyles}

  & svg {
    color: ${props => props.theme.textMain};

    path {
      color: ${props => props.theme.textMain};
    }
  }

  ${({ isBetting, theme }) =>
    isBetting && headerBettingStyles(theme.secondary, theme.fontMD)}
`;

export const Title = styled(TextXLarge)`
  font-weight: ${({ theme }) => theme.fontBold};
  color: ${props => props.theme.textMain};
  padding-left: ${({ theme }) => theme.space_10};
`;

export const IconContainer = styled.div<{ type?: string }>`
  position: absolute;
  right: ${({ theme }) => theme.space_10};
  top: ${({ theme }) => theme.space_10};

  & svg {
    width: 16px;
    height: 16px;
  }
`;

export const BackButton = styled(IconContainer)`
  border-radius: 0 0 35px;
  background-color: ${props => props.theme.buttonMainTextColor};
  cursor: pointer;

  & svg {
    color: ${({ theme }) => theme.textTertiary};
    transform: rotate(180deg);
  }
`;

export const CloseButtonContainer = styled.div`
  padding: 0 ${props => props.theme.space_10} 0 ${props => props.theme.space_30};
  cursor: pointer;
`;
