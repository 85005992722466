import replace from 'lodash/replace';
import omit from 'lodash/omit';

import services from '@features/core/services';

import {
  IRegistrationData,
  csrfTokenResponse,
  IFetchRegistrationResponse,
} from '@common/interfaces';
import { DynamicCalls, getApiUrl, StaticCalls } from '@common/api/api';
import { apiHandler, MethodType } from '@common/api/apiHandler';

const { API_TOKENS } = StaticCalls;
const { API_REGISTRATION } = DynamicCalls;

const fetchRegistration = async (
  payload: IRegistrationData,
): Promise<IFetchRegistrationResponse> => {
  const url = getApiUrl(API_REGISTRATION, {
    dynamicLanguage: services.domainLang,
  });
  const data = {
    ...payload,
    password2: payload.password,
  };

  const { csrfSec, csrfToken } = await apiHandler<csrfTokenResponse>(
    { method: MethodType.Get },
    { apiUrl: API_TOKENS },
  );

  const headers = {
    'CSRF-TOKEN': replace(`${csrfToken}-${btoa('55')}`, '=', ''),
    'CSRF-SEC': csrfSec,
  };

  const registrationResponse = await services.http.post(
    url,
    omit(data, 'street'),
    { headers },
  );

  return registrationResponse.data;
};

export default fetchRegistration;
