import forEach from 'lodash/forEach';
import replace from 'lodash/replace';

import { PageName, StaticSlugPageName } from '@features/core/routing';
import { getStaticPageURL } from '@features/core/routing/linkAliases';

import { IUserWalletType } from '@common/interfaces/user/IUser';
import { useUserState } from '@common/providers/user/useUserState';
import { getActiveWalletBalance } from '@common/helpers/paymentsHelper/walletsHelper';
import { isLoginProccessing } from '@common/helpers/userHelper/loggedInState';

/**
 * addQueryParametersToLink
 * Logic for adding Query Parameters from URI to all Links on static pages
 *
 * @param {Array} links
 * @returns {void}
 */

export const addQueryParametersToLink = (): void => {
  const links = document.querySelectorAll('#static-page-section a');

  const searchParams = new URLSearchParams(window.location.search);
  forEach(links, link => {
    const currentHref = link.getAttribute('href');

    if (currentHref && ((searchParams as unknown) as { size: number }).size) {
      const url = new URL(currentHref, window.location.href);
      forEach(searchParams, (value, key): void =>
        url.searchParams.set(key, (value as unknown) as string),
      );
      link.setAttribute('href', url.href);
    }
  });
};

export const getStaticPageType = (
  route: PageName,
  id: StaticSlugPageName,
): string => {
  const pageUrl = getStaticPageURL(route, id);

  return replace(pageUrl, /^\/en\/|^\//, '');
};

export const applyStaticPageUserClasses = (): string => {
  const user = useUserState.getState().data?.username as string;
  if (isLoginProccessing()) {
    return 'user-loading';
  }
  if (user) {
    return `user ${
      getActiveWalletBalance(IUserWalletType.bonus) ? 'bonus' : 'no-bonus'
    }`;
  }
  return 'user-guest';
};
