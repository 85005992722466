import services from '@features/core/services';

import { HAS_LUGAS } from '@common/constants/config';
import { useUserState } from '@common/providers/user/useUserState';
import {
  IUser,
  IUserType,
  IUserWalletType,
} from '@common/interfaces/user/IUser';
import { getActiveWallet } from '@common/helpers/paymentsHelper/walletsHelper';
import { DEPOSIT_LIMIT_LAYER_COOKIE } from '@common/constants/cookie';
import { IFetchPocketTagsResponse } from '@common/interfaces/api/account/IFetchPocketTags';
import { ILimit } from '@common/interfaces';

/**
 * hasLugasRestrictions
 *
 * @returns {boolean} hasLugasRestrictions
 */
export const hasLugasRestrictions = (): boolean => {
  const user = useUserState.getState().data;
  const activeWallet = getActiveWallet();

  return (
    (services.config.get(HAS_LUGAS) as boolean) &&
    user?.countryCode === 'DE' &&
    (activeWallet?.type === IUserWalletType.www ||
      activeWallet?.type === IUserWalletType.shop_www)
  );
};

export const allowedAffiliates = {
  26487: true,
  23085: true,
  43843: true,
  21764: true,
  68464: true,
  84354: true,
  23776: true,
  56240: true,
  60100: true,
  21554: true,
  58023: true,
  38044: true,
  98996: true,
};

export const hasDepositLayer = (
  user: IUser,
  depositLayer: IFetchPocketTagsResponse,
  monthlyLimit?: ILimit,
): boolean =>
  !services.cookie.get(DEPOSIT_LIMIT_LAYER_COOKIE) &&
  user.type === IUserType.shop &&
  allowedAffiliates[user.affiliateID] &&
  !depositLayer.tag?.value &&
  !parseFloat(monthlyLimit?.data.value as string);

export default hasLugasRestrictions;
