import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';

import services from '@features/core/services';

import {
  HAS_GOOGLE_TAG_MANAGER,
  HAS_COOKIE_CONSENT_TYPE,
  HAS_FACEBOOK_PIXEL,
  HAS_BETRADAR_ANALYTICS,
} from '@common/constants/config';
import { ACCEPTED_COOKIE } from '@common/constants/cookie';
import { ACCEPT_COOKIE_LAYER } from '@common/constants/dialogs';
import { isNativeApp } from '@common/helpers/links';
import {
  openSidebar,
  useAppState,
} from '@common/providers/application/useAppState';
import { addGTagEvent } from '@common/helpers/marketing';
import { hasSmartBanner } from '@common/helpers/appLayoutHelper';

const DEFAULT_META =
  'sportwetten.de | Top Wettquoten & Livewetten! Hier wetten I 18+';

const HeadApp = (): JSX.Element | null => {
  const { search } = useLocation();
  const appVersion = useAppState(state => state.openAppVersion);
  // eslint-disable-next-line
  const isStorybook = Boolean(window.__STORYBOOK_CLIENT_API__);

  const metaData = useAppState(state => state.metaData);
  const {
    meta_title = DEFAULT_META,
    meta_description = DEFAULT_META,
  } = metaData;
  const metaDescriptionElement = document.querySelector(
    'meta[name="description"]:not([data-react-helmet="true"])',
  ) as HTMLMetaElement;

  useEffect(() => {
    if (metaDescriptionElement && meta_description) {
      metaDescriptionElement.content = meta_description;
    }
  }, [meta_description]);

  const hasGTM = (): boolean =>
    !isNativeApp(search) &&
    (((services.config.get(HAS_GOOGLE_TAG_MANAGER) &&
      services.cookie.get(ACCEPTED_COOKIE)) ||
      (services.config.get(HAS_GOOGLE_TAG_MANAGER) &&
        services.config.get(HAS_COOKIE_CONSENT_TYPE) ===
          'usercentrics')) as boolean);

  const hasFBPixel = (): boolean => {
    try {
      return (
        !!services.config.get(HAS_FACEBOOK_PIXEL) &&
        !!services.cookie.get(ACCEPTED_COOKIE) &&
        JSON.parse(services.cookie.get(ACCEPTED_COOKIE))['Facebook Pixel']
      );
    } catch (e) {
      services.logger.log('pixel parse error', String(e));
      return false;
    }
  };

  useEffect(() => {
    if (
      services.config.get(HAS_GOOGLE_TAG_MANAGER) &&
      services.cookie.get(ACCEPTED_COOKIE) &&
      !isNativeApp(search)
    ) {
      try {
        addGTagEvent(JSON.parse(services.cookie.get(ACCEPTED_COOKIE)));
      } catch (err) {
        openSidebar({ type: ACCEPT_COOKIE_LAYER });
        services.logger.log('cookie layer parse error', String(err));
      }
    }
  }, [appVersion]);

  return (
    <Helmet>
      <title>{meta_title}</title>
      {!metaDescriptionElement && (
        <meta name="description" content={meta_description} />
      )}
      {hasSmartBanner() && (
        <meta name="apple-itunes-app" content="app-id=1658516144" />
      )}
      {hasGTM() && (
        <script className="delete-for-native-app">
          {`
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${services.config.get(
          HAS_GOOGLE_TAG_MANAGER,
        )}');`}
        </script>
      )}
      {hasFBPixel() && (
        <script>
          {`
        !function(f,b,e,v,n,t,s)
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
        n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s)}(window, document,'script',
        'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', ${services.config.get(HAS_FACEBOOK_PIXEL)});
        fbq('track', 'PageView');
       `}
        </script>
      )}
      {services.config.get(HAS_BETRADAR_ANALYTICS) && !isStorybook && (
        <script>
          {`
            (function (a, b, c, d, e, f) {
             a[e] = a[e] || [];
             a.x = b.createElement(c);
             a.y = b.getElementsByTagName(c)[0];
             x.src = d + "?id=" + f;
             x.setAttribute("data-srtmn", e);
             y.parentNode.insertBefore(x, y);
            })(
             window,
             document,
             "script",
             "https://tm.ads.sportradar.com/dist/tag-manager.js",
             "srtmCommands",
             "STM-AAAA13"
            );
        `}
        </script>
      )}
    </Helmet>
  );
};

export default HeadApp;
