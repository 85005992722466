/* eslint-disable sonarjs/no-nested-template-literals */
import styled, { css, keyframes } from 'styled-components';

import { TextNormal } from '@features/core/styles/text.styled';

import { isDesktopView } from '@common/helpers/deviceUtil';

import { Icon } from '@ui/components/icon';

const startNum = isDesktopView() ? 0.4 : 0.3;
const midlNum = isDesktopView() ? 0.5 : 0.2;

const BounceAnimation = keyframes`
  0% {
    transform: translateY(-${startNum}em);
    animation-timing-function: ease-in;
  }

  50% {
    transform: translateY(${midlNum}em);
    animation-timing-function: ease-out;
  }

  100% {
    transform: translateY(-${startNum}em);
    animation-timing-function: ease-in;
  }
`;

const Root = styled(TextNormal)<{ isAnimation: boolean }>`
  color: ${(props): string => props.theme.selectTextColor};
  ${({ isAnimation }) =>
    isAnimation &&
    css`
      animation: ${BounceAnimation} 1s linear 3.3;
    `};
`;

export const LoaderIcon = styled(Icon)`
  animation: spinner 1s linear infinite;

  @keyframes spinner {
    to {
      transform: rotate(360deg);
    }
  }
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 0 ${({ theme }) => theme.space_10};
`;

export default Root;
