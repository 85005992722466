import includes from 'lodash/includes';

import { categorySwitcherHeight } from '@features/categories/categoriesSwitcherMobile/CategoriesSwitcherMobile.styled';
import { timeFilterHeight } from '@features/categories/timeFilters/TimeFilters.styled';
import { PageName } from '@features/core/routing';

import { useAppState } from '@common/providers/application/useAppState';
import {
  setSelectedCategoryTypeTab,
  setSportCategoriesFilter,
  toggleSportCategory,
  useLiveCategories,
} from '@common/providers/events/liveCategories/useLiveCategories';
import { DEFAULT_SELECTED_SPORT_CATEGORY } from '@common/providers/events/liveCategories/state';
import { scrollTopWindow } from '@common/helpers/scrollHelper';
import { setLocation } from '@common/providers/router/helper';
import { isDesktopView } from '@common/helpers/deviceUtil';
import { EventTimeFilters } from '@common/interfaces';

export const getTabsNavTop = (timeFilter: boolean): number => {
  const { topLayoutHeight } = useAppState.getState();
  const { selectedCategoryTypeTab } = useLiveCategories.getState();

  const isTimeFilterVisible =
    timeFilter && selectedCategoryTypeTab !== 'longTermCategories';

  const fullFilterHeight =
    categorySwitcherHeight + (isTimeFilterVisible ? timeFilterHeight : 0);
  return topLayoutHeight + fullFilterHeight;
};

export const onChangeTabHandler = (
  tab: string,
  categoryId: string,
  filter: keyof typeof EventTimeFilters | '',
): void => {
  const { selectedCategoryTypeTab } = useLiveCategories.getState();
  const isDesktop = isDesktopView();

  setSelectedCategoryTypeTab({ selectedCategoryTypeTab: tab });

  if (!isDesktop && selectedCategoryTypeTab !== tab) {
    setSportCategoriesFilter({ filter: '' });
    toggleSportCategory({ category: DEFAULT_SELECTED_SPORT_CATEGORY[0] });
    scrollTopWindow();
  }

  if (
    isDesktop &&
    includes(window.location.pathname, 'category') &&
    filter !== ''
  ) {
    setSportCategoriesFilter({ filter: '' });
    setLocation(PageName.EVENTS_CATEGORY, {
      categoryId,
      filter: 'all',
    });
  }
};
