import { EventListTypes, IEventsState } from '@common/interfaces';

export const defaultEventsDataState = {
  events: {},
  categories: {},
  markets: {},
  predictions: {},
  pagination: {
    offset: 0,
    range: 0,
    registers: 0,
    rows: 0,
  },
  list: EventListTypes.live,
  cache_max_age: 0,
  curr_version: 0,
};

export const defaultListState = {
  loading: true,
  error: null,
  data: defaultEventsDataState,
  selectedEvent: null,
  loadedEvent: undefined,
  marketsLimit: 5,
  marketsKeysSets: {},
  filterMarketKey: 'FILTER_ALL',
};

export const defaultState: IEventsState = {
  live: {
    ...defaultListState,
  },
  cashout: {
    ...defaultListState,
  },
  combined_events: {
    ...defaultListState,
  },
  detail: {
    ...defaultListState,
  },
  favorites: {
    ...defaultListState,
  },
  slider_events: {
    ...defaultListState,
  },
  betslip: {
    ...defaultListState,
  },
};
