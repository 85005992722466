import {
  BETTING_SLIP,
  REGISTRATION_BONUS,
  CASINO,
  CREATE_ONLINE_ACCOUNT,
  CHECK_INBOX,
  DEPOSIT_ONLINE_ACCOUNT,
  ACTIVATE_ONLINE_ACCOUNT,
  EVENTS_ITEM,
  EVENTS_ITEM_LMT,
  EVENTS_CATEGORY,
  FAQ_LUGAS,
  FORGOT_PASSWORD,
  FULL_REGISTER,
  HOME,
  LIVE,
  LOGIN,
  NEWS,
  NEWS_CATEGORY,
  NEWS_CATEGORY_DETAIL,
  NEWS_ITEM,
  NEWS_ITEM_CATEGORY,
  ODDS_COMPARISON,
  PAYMENT_METHODS,
  PRESSE,
  INFORMATION,
  COMPANY,
  RESPONSIBILITY,
  PROMOTIONS,
  PROMOTIONS_PAGES,
  REGISTER,
  REGISTRATION_DISABLED,
  TICKET_SCANNER,
  SCANNER_RESULT,
  CASHOUT_SCANNER_RESULT,
  MANUAL_BARCODE_ENTRY,
  SPONSORING,
  SPORT,
  SUPPORT,
  TEST_PAGE,
  UNTERNEHMEN_PRESSE,
  UNTERNEHMEN_PRESSE_DETAIL,
  UPDATE_ONLINE_ACCOUNT,
  USER_BET_ITEM,
  USER_BETS,
  USER_BONUSES,
  USER_CASHOUT,
  USER_CASHOUT_ITEM,
  USER_CHANGE_PASSWORD,
  SET_LIMITS,
  USER_DELETE_ACCOUNT,
  USER_GDPR,
  USER_INBOX,
  USER_INBOX_MESSAGE,
  USER_LIMITS,
  USER_LIMITS_HISTORY,
  USER_LIMITS_LOCK,
  USER_NOTIFICATIONS,
  USER_OVERVIEW,
  USER_PAYIN,
  USER_PAYIN_CARD_AMOUNT,
  USER_PAYIN_FAILURE,
  USER_PAYIN_INFO_BANK,
  USER_PAYIN_INFO_METHOD,
  USER_PAYIN_INFO_SKRILL,
  USER_PAYIN_PREPARED,
  USER_PAYIN_QR,
  USER_PAYIN_SUCCESS,
  USER_PAYOUT,
  USER_PAYOUT_BANK_AMOUNT,
  USER_PAYOUT_FAILURE,
  USER_PAYOUT_PREPARED,
  USER_PAYOUT_SUCCESS,
  USER_PROFILE,
  USER_UPLOADS,
  USER_VERIFICATION,
  USER_VERIFICATION_YEARLY,
  YEARLY_DATA_CHECK,
  REGISTRATION_SUCCESS,
  USER_EMAIL_VERIFICATION_SUCCESS,
  MY_FAVORITO,
  LITTLE_CASHOUT,
  ODDS_BOOST,
} from '@features/core/routing/constants';

// eslint-disable-next-line import/prefer-default-export
export const ROUTERS_MAPPER = {
  HOME,
  SUPPORT,
  LIVE,
  BETTING_SLIP,
  PROMOTIONS,
  PROMOTIONS_PAGES,
  CASINO,
  INFORMATION,
  COMPANY,
  RESPONSIBILITY,
  REGISTRATION_BONUS,
  FORGOT_PASSWORD,
  REGISTER,
  FULL_REGISTER,
  LOGIN,
  USER_OVERVIEW,
  USER_BETS,
  TICKET_SCANNER,
  SCANNER_RESULT,
  CASHOUT_SCANNER_RESULT,
  MANUAL_BARCODE_ENTRY,
  USER_PAYOUT,
  USER_PAYIN,
  USER_CASHOUT,
  USER_LIMITS,
  USER_LIMITS_HISTORY,
  USER_NOTIFICATIONS,
  USER_PROFILE,
  USER_CHANGE_PASSWORD,
  SET_LIMITS,
  USER_VERIFICATION,
  USER_VERIFICATION_YEARLY,

  USER_PAYIN_INFO_BANK,
  USER_PAYIN_CARD_AMOUNT,
  USER_PAYOUT_BANK_AMOUNT,
  USER_PAYIN_INFO_SKRILL,
  USER_PAYIN_QR,
  USER_PAYIN_SUCCESS,
  USER_PAYOUT_SUCCESS,
  USER_PAYIN_FAILURE,
  USER_PAYOUT_FAILURE,
  USER_PAYIN_PREPARED,
  USER_PAYOUT_PREPARED,
  USER_GDPR,
  USER_UPLOADS,
  USER_INBOX,
  USER_INBOX_MESSAGE,
  EVENTS_ITEM,
  EVENTS_ITEM_LMT,
  EVENTS_CATEGORY,
  NEWS,
  NEWS_CATEGORY,
  NEWS_ITEM,
  UNTERNEHMEN_PRESSE,
  PRESSE,
  USER_PAYIN_INFO_METHOD,
  USER_BONUSES,
  USER_BET_ITEM,
  NEWS_ITEM_CATEGORY,
  NEWS_CATEGORY_DETAIL,
  UNTERNEHMEN_PRESSE_DETAIL,
  USER_CASHOUT_ITEM,
  SPONSORING,
  FAQ_LUGAS,
  REGISTRATION_DISABLED,
  SPORT,
  USER_LIMITS_LOCK,
  USER_DELETE_ACCOUNT,
  UPDATE_ONLINE_ACCOUNT,
  CREATE_ONLINE_ACCOUNT,
  CHECK_INBOX,
  DEPOSIT_ONLINE_ACCOUNT,
  ACTIVATE_ONLINE_ACCOUNT,
  YEARLY_DATA_CHECK,
  PAYMENT_METHODS,
  TEST_PAGE,
  ODDS_COMPARISON,
  REGISTRATION_SUCCESS,
  USER_EMAIL_VERIFICATION_SUCCESS,
  MY_FAVORITO,
  LITTLE_CASHOUT,
  ODDS_BOOST,
};
