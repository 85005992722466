import map from 'lodash/map';
import filter from 'lodash/filter';
import isEmpty from 'lodash/isEmpty';

import { DATE_TEMPLATE } from '@features/core/form/components/dateInput/DateTemplates';
import { PageName } from '@features/core/routing';
import { TReturnIconName } from '@features/bets/components/betStatusSquare/BetStatusSquare.types';
import services from '@features/core/services';

import { USE_NEW_SCAN_BET_API } from '@common/constants/config';
import { IBetDetail, IBettingslipType, StatusCode } from '@common/interfaces';
import formatDate from '@common/helpers/timeHelper/localizedFormat';
import { setSelectedBetId, useBets } from '@common/providers/bets/useBets';
import { useEventsListState } from '@common/providers/events/eventList/useEventsList';
import {
  setBettingSlipData,
  setSelections,
} from '@common/providers/bettingslip/useBettingSlip';
import { isMatchWebRoutes, setLocation } from '@common/providers/router/helper';
import { isDesktopView } from '@common/helpers/deviceUtil';
import { ISelections } from '@common/interfaces/bettingslip/IBettingslip';
import { validateSelections } from '@common/providers/bettingslip/helper';

const normalizeBetDetailResponse = (type, response): IBetDetail[] => {
  if (type === 'scan-bet' && !services.config.get(USE_NEW_SCAN_BET_API)) {
    return [response];
  }
  return [response.bet_detail];
};

export const handleReUseBet = (
  bet: IBetDetail,
  validatedSelections: ISelections,
): void => {
  setSelections({});
  const isDesktop = isDesktopView();
  const loading = useBets.getState().detailLoading;
  const eventLoading = useEventsListState.getState().cashout.loading;
  const reUseBetDisabled = eventLoading || isEmpty(validatedSelections);

  if (loading || reUseBetDisabled) {
    return;
  }
  setBettingSlipData({
    selections: validatedSelections,
    type: bet.raw_type,
    ...(bet.raw_type === IBettingslipType.system
      ? { size: bet.leg_sizes }
      : {}),
    totalStake: parseFloat(bet.initial_total_cost),
    banks: map(filter(bet?.selections, ['bank', 1]), selection =>
      parseFloat(selection.event_id),
    ),
    selectionsLoading: true,
  });

  validateSelections();

  if (isDesktop) {
    setSelectedBetId('');
    setLocation(
      isMatchWebRoutes(PageName.HOME) ? PageName.HOME : PageName.LIVE,
      {},
      { forceReload: true },
    );
  } else {
    setLocation(PageName.BETTING_SLIP);
  }
};

export const getFormatDate = (date: number, withSeparate = false): string =>
  formatDate(
    parseInt(`${date || 0}`, 10) * 1000,
    `${DATE_TEMPLATE} ${withSeparate ? '-' : ''} HH:mm:ss`,
  );

export const getIconNameByStatus = (status_code): TReturnIconName => {
  switch (status_code) {
    case StatusCode.WON:
      return 'check';
    case StatusCode.CANCELLED:
      return 'minus';
    case StatusCode.OPEN:
      return 'empty-circle';
    case StatusCode.LOST:
      return 'close';
    default:
      return '';
  }
};

export default normalizeBetDetailResponse;
