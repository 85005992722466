import includes from 'lodash/includes';
import some from 'lodash/some';

import { IMarket } from '@common/interfaces';
import { ISelections } from '@common/interfaces/bettingslip/IBettingslip';

const isBetPackerMarketActive = (
  betPackerSelections: ISelections,
  betPackerPredictions: string[],
  market: IMarket,
): boolean => {
  const isMarketDisabled = some(market.predictions, prediction =>
    includes(betPackerPredictions, prediction),
  );
  return (
    isMarketDisabled ||
    (betPackerSelections && some(betPackerSelections, { marketId: market.id }))
  );
};

export default isBetPackerMarketActive;
