import React, { useEffect, useState } from 'react';
import { useTheme } from 'styled-components';

import useFocus from '@common/hooks/useFocus';

import { Icon } from '@ui/components/icon';

import * as S from './TextInput.styled';
import { ITextInputProps } from './TextInput.types';

const TextInput: React.FC<ITextInputProps> = props => {
  const {
    validateStatus,
    className,
    inputClassName,
    sufix,
    floatingLabel,
    rightText,
    isPassword,
    isLeftIcon,
    errorIcon,
    successIcon,
    isError,
    onError,
    shouldFocus,
    inputType = 'text',
    maxFieldLength,
    checkMaxLength,
    step,
    name,
    autocomplete,
    onChange,
    value,
    clearInitialValue,
    validateField,
    checkRestrictedChar,
    ...inputProps
  } = props;
  const { inputRef, setFocus } = useFocus();
  const [inputValue, setInputValue] = useState(clearInitialValue ? '' : value);
  const showErrorIcon = errorIcon && validateStatus === 'error';
  const showSuccessIcon = successIcon && validateStatus === 'success';
  const { statusSuccess } = useTheme();

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  if (validateStatus === 'error' && onError) {
    onError();
  }

  const getIconClassName = (): string => {
    if (isPassword) {
      return 'errorPasswordIcon';
    }
    if (isLeftIcon) {
      return 'leftIcon';
    }
    return 'errorIcon';
  };

  const getSuccessIconClassName = (): string => {
    if (isPassword) {
      return 'successPasswordIcon';
    }
    return 'successIcon';
  };

  const onKeyPressHandler = (e): void => {
    if (
      (checkMaxLength && String(e.target.value).length >= e.target.maxLength) ||
      (checkRestrictedChar && checkRestrictedChar(e.charCode))
    ) {
      e.preventDefault();
    }
  };

  const onWheel = (): void => {
    if (inputType === 'number') {
      inputRef.current?.blur();
    }
  };

  useEffect(() => {
    if (shouldFocus) {
      setFocus();
    }
  }, [shouldFocus]);

  useEffect(() => {
    if (validateField) {
      validateField();
    }
  }, [inputValue]);

  const onInputChange = (e): void => {
    setInputValue(e.target.value);

    if (onChange) {
      onChange(e);
    }
  };

  return (
    <S.Root className={className}>
      <S.StyledInput
        ref={inputRef}
        type={inputType || 'text'}
        onWheel={onWheel}
        className={inputClassName}
        errorIcon={showErrorIcon}
        successIcon={showSuccessIcon}
        hasFloatingLabel={!!floatingLabel}
        isValidateError={validateStatus === 'error'}
        isError={isError}
        onKeyPress={onKeyPressHandler}
        maxLength={maxFieldLength}
        step={step}
        name={name}
        autoComplete={autocomplete}
        rightText={rightText}
        {...inputProps}
        value={inputValue}
        onChange={onInputChange}
      />
      {!!sufix && <S.Sufix className="sufix">{sufix}</S.Sufix>}
      {floatingLabel && (
        <S.FloatingLabel
          isError={isError}
          rightText={rightText}
          className="floatingLabel"
          data-qa="floating-label"
        >
          {floatingLabel}
        </S.FloatingLabel>
      )}
      {showErrorIcon && (
        <Icon
          className={getIconClassName()}
          name="exclamation-circle"
          width="20"
          height="20"
          fill="inherit"
        />
      )}
      {showSuccessIcon && (
        <Icon
          className={getSuccessIconClassName()}
          name="check-circle"
          width="20"
          height="20"
          fill={statusSuccess}
        />
      )}
    </S.Root>
  );
};

export default TextInput;
