import styled from 'styled-components';

import { hexToRgba } from '@common/helpers/deviceUtil';

import { Icon } from '@ui/components/icon';

const getRootOpened = theme => `
  box-shadow: 0 3px ${theme.space_15} 0 ${theme.primaryBackground};
  outline: solid 3px ${theme.primaryBackground};
  border-radius: 3px;
`;

export const Root = styled.div<{ isOpen: boolean }>`
  background-color: ${({ theme }) => theme.textMain};
  border-radius: 3px;
  ${({ theme, isOpen }) => isOpen && getRootOpened(theme)};
`;

export const List = styled.div`
  background-color: ${({ theme }) => theme.textMain};
`;

export const ListToggle = styled.div<{ isOpen: boolean }>`
  display: flex;
  margin-right: ${({ theme }) => theme.space_15};
  align-items: center;
  color: ${({ theme }) => theme.textActive};
  transform: rotate(${({ isOpen }) => (isOpen ? '180' : '0')}deg);
`;

export const Item = styled.div`
  padding: ${({ theme }) => theme.space_10};
  color: ${({ theme }) => theme.textActive};
  cursor: pointer;
`;

export const ItemIconWrap = styled.div`
  width: 20px;
`;

const getActiveItemOpenBg = ({ theme }): string =>
  `${hexToRgba(theme.primaryBackground, 0.1)}`;
export const ActiveItem = styled.div<{ isOpen: boolean }>`
  display: flex;
  justify-content: space-between;
  padding: ${({ theme }) => theme.space_5} 0;
  border-radius: 3px;
  background: ${({ theme, isOpen }) =>
    isOpen ? getActiveItemOpenBg : theme.textMain};
  cursor: pointer;
`;

export const ItemIcon = styled(Icon)``;

export const ItemContentWrap = styled.div`
  display: flex;
`;

export const ItemTitle = styled.div`
  font-size: ${({ theme }): string => theme.fontMD};
`;

export const ItemDescription = styled.div`
  padding-left: ${({ theme }) => theme.space_20};
  font-size: ${({ theme }): string => theme.fontXS};
`;
