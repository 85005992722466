import reduce from 'lodash/reduce';
import React from 'react';

import { BetPackerTotalViewWrapper } from '@features/bettingslip/components/betPackerTotalViewWrapper';

import { getLabel } from '@common/helpers/markets/marketModel';
import { IMarket, IPrediction } from '@common/interfaces';
import { useEventsListState } from '@common/providers/events/eventList/useEventsList';
import { resetBetPackerSelection } from '@common/providers/bettingslip/useBettingSlip';
import { ISelections } from '@common/interfaces/bettingslip/IBettingslip';

import * as S from './BetPacker.styled';

interface BetpackerTotalViewProps {
  selectionGroup: ISelections;
  betPackerOdds: number | null;
}

const BetpackerTotalView: React.FC<BetpackerTotalViewProps> = ({
  selectionGroup,
  betPackerOdds,
}) => {
  const found = selectionGroup[0];
  const event = useEventsListState(
    state => state.betslip.data.events[found?.eventId],
  );
  const markets = useEventsListState(state => state.betslip.data.markets);
  const predictions = useEventsListState(
    state => state.betslip.data.predictions,
  );

  if (!event) {
    return null;
  }

  const selectionElements = reduce(
    selectionGroup,
    (items: JSX.Element[], selection, i) => {
      const market: IMarket = markets[selection.marketId];
      const prediction: IPrediction = predictions[selection.id];

      if (!market || !prediction) {
        return items;
      }

      items.push(
        <S.BetpackerRow key={selection.id}>
          <S.MarketLabel hasIndent={!!i}>
            {`${getLabel(market)} - `}
            {market.special_value && `${market.special_value} - `}
          </S.MarketLabel>
          <S.SelectionLabel>{prediction.label}</S.SelectionLabel>
        </S.BetpackerRow>,
      );
      return items;
    },
    [],
  );

  return (
    <BetPackerTotalViewWrapper
      id={event.id}
      event={event}
      betPackerOdds={betPackerOdds}
      onClear={resetBetPackerSelection}
    >
      {selectionElements}
    </BetPackerTotalViewWrapper>
  );
};

export default BetpackerTotalView;
